<template>
  <div class="admin-container">
    <div class="admin-header">
      <h1>Student List</h1>
    </div>
    <div class="border-container-unique37">
      <div>
        You can filter by either School or Masterclass to view an overview of
        individual student progress.
      </div>
      <div class="admin-flex-container" ref="adminFlexContainer">
        <div class="admin-list-filter-container">
          <div class="admin-list-filter-item">
            <BaseInput
              v-model="schools"
              type="multipleSelect"
              placeholder="School"
              :options="schoolsData"
              :style="{ width: '250px' }"
              :maximumMultipleSelect="-1"
              @update:options="updateSchoolOptions"
            />
          </div>
          <div class="admin-list-filter-item">
            <BaseInput
              v-model="masterclassId"
              type="multipleSelect"
              placeholder="Masterclass"
              :options="masterclassData"
              :style="{ width: '280px' }"
              :maximumMultipleSelect="-1"
            />
          </div>
        </div>
      </div>
      <div class="admin-list-tool-container"></div>

      <div class="mb-3" style="display: flex; gap: 10px; flex-wrap: wrap"></div>

      <TableData
        :headers="headers"
        :items="tableItems"
        :loading="studentsLoading"
        :total-pages="studentsData?.data?.total_page"
        :current-page="currentPage"
        @update:current-page="changePage"
        @sort="handleSort"
        :use-api-sort="true"
      />
      <div class="admin-list-tool-container">
        <div class="admin-list-tool-item">
          <BaseButton
            variant="primary"
            :loading="downloading"
            loading-text="Downloading..."
            @click="downloadSurveyData"
          >
            Download Survey Data
          </BaseButton>
          <transition name="fade">
            <div
              v-if="downloadMessage"
              :class="['download-message', downloadMessage.type]"
            >
              {{ downloadMessage.text }}
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, computed, watch } from "vue";
import YsaAdminService from "@/services/ysaAdminService";
import BaseInput from "@/components/BaseInput.vue";
import TableData from "@/components/common/TableData.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import { useDataFetching } from "@/composeables/useDataFetching";
import { formatDate } from "@/utils";
// Import router composables
import { useRouter, useRoute } from "vue-router";

// Initialize router and route
const router = useRouter();
const route = useRoute();

const data = ref({
  message: "",
  error: null,
  data: {
    search: "",
    page: 0,
    limit: 0,
    total_rows: 0,
    total_page: 0,
    rows: [],
  },
});

const downloading = ref(false);
const downloadMessage = ref(null);

const { data: schoolsData, fetchData: fetchSchools } = useDataFetching(
  YsaAdminService.getYsaSchools,
  {
    transformResponse: (response) => {
      const schools = response?.data || [];
      return schools.map((school) => ({
        value: school.id,
        text: school.title,
      }));
    },
    initialData: [],
  }
);

const { data: masterclassData, fetchData: fetchMasterclasses } =
  useDataFetching(YsaAdminService.getMasterclassDropdown, {
    transformResponse: (response) => {
      const masterclasses = response?.data || [];
      return masterclasses.map((item) => ({
        value: item.scorm_uuid,
        text: item.title,
      }));
    },
    initialData: [],
  });

const {
  data: studentsData,
  loading: studentsLoading,
  fetchData: fetchStudents,
} = useDataFetching(YsaAdminService.getStudentsWithMasterclass, {
  initialData: {
    message: "",
    error: null,
    data: {
      search: "",
      page: 0,
      limit: 0,
      total_rows: 0,
      total_page: 0,
      rows: [],
    },
  },
});

const schools = ref([]);
const masterclassId = ref([]);

const filters = ref({
  schools: [],
  uuids: [],
  masterclass_id: "",
  page: 1,
  limit: 10,
  sort_by: "student_name",
  sort_dir: "desc",
});

const schoolList = ref([]);

const currentPage = ref(1);
// Define table headers
const headers = ref([
  { text: "Student Name", value: "student_name", sortable: true },
  { text: "Student Email", value: "student_email", sortable: true },
  { text: "School", value: "school_name", sortable: true },
  { text: "Join Date", value: "student_join_date", sortable: true },
  { text: "Masterclass Title", value: "masterclass_title", sortable: true },
  { text: "Progress", value: "progress", sortable: true },
  {
    text: "Survey",
    value: "survey_status",
    sortable: true,
    info: `
      <strong style="color: #3B4648;">Survey Status:</strong>
      <ul style="margin-top: 5px; padding-left: 15px; color: #3B4648;">
        <li><strong>Complete</strong> - Students have completed all items</li>
        <li><strong>Incomplete</strong> - Students have not completed all items</li>
      </ul>
    `,
  },
]);

// Computed property to transform data for the table
const tableItems = computed(() => {
  return data.value.data.rows.map((item) => ({
    ...item,
    student_name: item.student_name,
    id: item.user_id, // Ensure each item has an id for the key in v-for
    ysa: item.ysa,
    progress: `${item.progress}%`,
    student_join_date: item.student_join_date
      ? formatDate(item.student_join_date)
      : "-",
  }));
});

watch(studentsData, (newData) => {
  data.value = newData;
});

// Initialize filters from URL query parameters
const initializeFiltersFromUrl = () => {
  const query = route.query;

  if (query.schools) {
    try {
      // Handle both single value and multiple values
      if (Array.isArray(query.schools)) {
        schools.value = query.schools.map((id) => Number(id));
      } else {
        schools.value = [Number(query.schools)];
      }
    } catch (e) {
      console.error("Error parsing schools from URL:", e);
      schools.value = [];
    }
  }

  if (query.masterclass) {
    // Handle both single value and multiple values
    masterclassId.value = Array.isArray(query.masterclass)
      ? [...query.masterclass]
      : [query.masterclass];
  }

  if (query.page) {
    currentPage.value = Number(query.page);
    filters.value.page = Number(query.page);
  }

  if (query.sort_by) {
    filters.value.sort_by = query.sort_by;
  }

  if (query.sort_dir) {
    filters.value.sort_dir = query.sort_dir;
  }

  // Update filters to match URL values
  filters.value.schools = schools.value;
  filters.value.uuids = masterclassId.value;

  fetchStudents(filters.value);
};

// Update URL with current filter state
const updateUrlWithFilters = () => {
  // Start with a new query object containing only the simple params
  const query = {
    page: filters.value.page.toString(),
  };

  if (filters.value.sort_by) {
    query.sort_by = filters.value.sort_by;
  }

  if (filters.value.sort_dir) {
    query.sort_dir = filters.value.sort_dir;
  }

  // Create the URL with the router's stringifyQuery handling
  const url = new URL(window.location.href);

  // Clear existing query params
  url.search = "";

  // Add basic params
  if (query.page) url.searchParams.append("page", query.page);
  if (query.sort_by) url.searchParams.append("sort_by", query.sort_by);
  if (query.sort_dir) url.searchParams.append("sort_dir", query.sort_dir);

  // Add multiple school values individually
  if (schools.value.length > 0) {
    schools.value.forEach((school) => {
      url.searchParams.append("schools", school);
    });
  }

  // Add multiple masterclass values individually
  if (masterclassId.value.length > 0) {
    masterclassId.value.forEach((uuid) => {
      url.searchParams.append("masterclass", uuid);
    });
  }

  // Replace the URL state without reloading the page
  window.history.replaceState({}, "", url.toString());
};

// Watch for changes in schools and update filters
watch([schools, masterclassId], ([newSchools, newMasterclassId]) => {
  filters.value.schools = newSchools;
  filters.value.uuids = newMasterclassId;
  fetchStudents(filters.value);
  updateUrlWithFilters();
});

const downloadSurveyData = async () => {
  downloading.value = true;
  downloadMessage.value = null;

  try {
    const response = await YsaAdminService.downloadSurveyData(filters.value);
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "students-survey-data.xlsx";
    a.click();

    // Show success message
    downloadMessage.value = {
      type: "success",
      text: "Download successful!",
    };

    // Auto-hide message after 3 seconds
    setTimeout(() => {
      downloadMessage.value = null;
    }, 3000);
  } catch (error) {
    console.error("Error downloading survey data:", error);

    // Show error message
    downloadMessage.value = {
      type: "error",
      text: "Failed to download. Please try again.",
    };

    // Auto-hide error message after 5 seconds
    setTimeout(() => {
      downloadMessage.value = null;
    }, 5000);
  } finally {
    downloading.value = false;
  }
};

// Replace existing functions with new implementations
const loadInitialData = async () => {
  try {
    await Promise.all([
      fetchStudents(filters.value),
      fetchSchools({ limit: 100 }),
      fetchMasterclasses({ limit: 100 }),
    ]);
  } catch (error) {
    console.error("Error loading initial data:", error);
  }
};

const changePage = async (page) => {
  currentPage.value = page;
  filters.value.page = page;
  await fetchStudents(filters.value);
  updateUrlWithFilters();
};

const handleSort = async ({ sort_by, sort_dir }) => {
  filters.value.sort_by = sort_by;
  filters.value.sort_dir = sort_dir;
  await fetchStudents(filters.value);
  updateUrlWithFilters();
};

const updateSchoolOptions = (options) => {
  schoolList.value = options;
};

// Function to update layout based on window width
const updateLayout = () => {
  const container = document.querySelector(".admin-flex-container");
  const toolContainer = document.querySelector(".admin-list-tool-container");
  const parentContainer = document.querySelector(".border-container-unique37");
  const width = window.innerWidth;

  if (width <= 991) {
    parentContainer.insertBefore(toolContainer, container.nextSibling);
  } else {
    container.appendChild(toolContainer);
    container.insertBefore(toolContainer, container.children[1]);
  }
};

onMounted(async () => {
  // Initialize filters from URL first
  initializeFiltersFromUrl();

  // Then load data with those filters
  await loadInitialData();
  window.addEventListener("resize", updateLayout);
  updateLayout();
});
</script>

<style scoped>
.admin-list-filter-container {
  display: flex;
  gap: 10px;
}

.admin-list-tool-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.download-message {
  margin-top: 8px;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  max-width: 300px;
}

.download-message.success {
  background-color: rgba(76, 175, 80, 0.1);
  color: #2e7d32;
  border-left: 3px solid #2e7d32;
}

.download-message.error {
  background-color: rgba(244, 67, 54, 0.1);
  color: #d32f2f;
  border-left: 3px solid #d32f2f;
}

/* Transition effects */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
