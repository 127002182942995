export function generateFilterString(filters) {
  const queryParts = [];

  Object.entries(filters).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length > 0) {
      value.forEach((item) => {
        if (!item) return;

        queryParts.push(`${key}[]=${encodeURIComponent(item)}`);
      });
    } else if (!Array.isArray(value) && value) {
      queryParts.push(`${key}=${encodeURIComponent(value)}`);
    }
  });

  return queryParts.join("&");
}

export const formatDate = (value) => {
  if (!value) return "";
  const date = new Date(value);
  const formattedDate = date.toLocaleDateString("en-GB").replace(/\//g, "-");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${formattedDate} ${hours}:${minutes}`;
};

export function combineQueryString(queries) {
  const queryParts = [];

  queries.forEach((query) => {
    if (!query) return;

    queryParts.push(query);
  });

  return queryParts.join("&");
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;

  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function validateEmail(value) {
  if (!value) {
    return {
      isValid: false,
      message: "Email is required",
    };
  }

  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
    return {
      isValid: false,
      message: "Invalid email address",
    };
  }

  return {
    isValid: true,
    message: "",
  };
}

// Password validation rules
export const passwordValidations = {
  lowercase: (password) => /[a-z]/.test(password),
  uppercase: (password) => /[A-Z]/.test(password),
  specialChar: (password) => /[!@#$%^&*(),.?":{}|<>]/.test(password),
  length: (password) => password.length >= 10,
};

/**
 * Validates a password against all requirements
 * @param {string} password - The password to validate
 * @returns {{isValid: boolean, validations: Object, message: string}} Validation result
 */
export function validatePassword(password) {
  if (!password) {
    return {
      isValid: false,
      message: "Password is required",
    };
  }

  const validations = {
    lowercase: passwordValidations.lowercase(password),
    uppercase: passwordValidations.uppercase(password),
    specialChar: passwordValidations.specialChar(password),
    length: passwordValidations.length(password),
  };

  const isValid = Object.values(validations).every(Boolean);

  let message = "";
  if (!isValid) {
    const errors = [];
    if (!validations.lowercase) errors.push("one lowercase letter");
    if (!validations.uppercase) errors.push("one uppercase letter");
    if (!validations.specialChar) errors.push("one special character");
    if (!validations.length) errors.push("at least 10 characters");
    message = `Password must contain ${errors.join(", ")}`;
  }

  return {
    isValid,
    validations,
    message,
  };
}
