<template>
  <div class="modal-overlay" v-if="isOpen" @click="close">
    <div class="modal terms-of-service flex flex-column">
      <div class="flex justify-end">
        <button class="btn-close" @click="close">&times;</button>
      </div>
      <div>
        <h2 class="title">Terms of Service</h2>
        <p>
          Welcome to Eddy, a product developed by edm8ker LLC (“we,” “us,” “our”). By using our website and services, you agree to the following Terms of Service:
        </p>
        <h3>1. Use of Our Website</h3>
        <p>
          You may use our website for lawful purposes only. You may not use our website to:
          <ul>
            <li>Violate any applicable laws or regulations.</li>
            <li>Infringe on the intellectual property rights of others.</li>
            <li>Transmit any viruses, malware, or other harmful software.</li>
            <li>Engage in any activity that is harmful, obscene, or offensive.</li>
          </ul>
        </p>
        <h3>2. User Accounts</h3>
        <p>
          If you create an account on our website, you are responsible for maintaining the security of your account and password. You may not share your account or password with anyone else. You are also responsible for any activity that occurs on your account.
        </p>
        <h3>3. Intellectual Property</h3>
        <p>
          All content on our website, including text, images, graphics, and logos, is the property of edm8ker LLC or its licensors and is protected by intellectual property laws. You may not use or reproduce our content without our permission.
        </p>
        <h3>4. Disclaimer of Warranties</h3>
        <p>
          Our website and services are provided “as is” and without warranties of any kind, whether express or implied. We do not guarantee that our website will be error-free or that our services will meet your expectations.
        </p>
        <h3>5. Limitation of Liability</h3>
        <p>
          We will not be liable for any damages arising from the use of our website or services. This includes direct, indirect, incidental, and consequential damages. Some jurisdictions do not allow the exclusion or limitation of liability for certain types of damages, so the above limitations may not apply to you.
        </p>
        <h3>6. Indemnification</h3>
        <p>
          You agree to indemnify, defend, and hold edm8ker LLC harmless from any claims, damages, or expenses arising from your use of our website or services or your violation of these Terms of Service.
        </p>
        <h3>7. Termination</h3>
        <p>
          We may terminate your access to our website or services at any time for any reason without notice.
        </p>
        <h3>8. Governing Law</h3>
        <p>
          These Terms of Service will be governed by and construed in accordance with the laws of the United States of America. Any legal action arising from these Terms of Service must be brought in the courts located in the United States of America.
        </p>
        <h3>9. Changes to These Terms</h3>
        <p>
          We may update these Terms of Service from time to time. If we make significant changes, we will notify you by posting a prominent notice on our website or by sending you an email.
        </p>
        <h3>10. Contact Us</h3>
        <p>
          If you have any questions or concerns about our Terms of Service, please contact us at hello@eddy4teachers.com.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        isOpen: { type: Boolean, default: false },
    },
    methods: {
        close() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
  .modal-overlay {
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,.5);
  }

  .modal {
    z-index: 999999;
    position: fixed; 
    box-shadow: 0px 2px 5px #cdcdcd;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -60%);
    text-align: left;
    overflow: auto;
    padding-top: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    height: auto !important;
    max-height: 85vh;
    background-color: white;
  }

  .modal .btn-close {
    font-size: 2rem;
    color: #003D6A;
  }

  .modal .title {
    color: #003D6A;
    font-size: 40px;
    margin-bottom: 1.5rem;
  }

  .modal h3 {
    margin: 1rem 0;
    color: #3B4648;
  }

  .modal ul { 
    margin-left: 1rem;
  }

  .modal ul li { 
    margin: 0;
  }
</style>