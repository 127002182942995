import { ref } from "vue";

export function useDataFetching(fetchFunction, options = {}) {
  const data = ref(null);
  const loading = ref(false);
  const error = ref(null);

  // Add transform function option
  const {
    transformResponse,
    onError = (err) => console.error(err),
    immediate = false,
    initialData = null,
  } = options;

  data.value = initialData;

  const fetchData = async (...args) => {
    loading.value = true;
    error.value = null;

    try {
      const response = await fetchFunction(...args);

      if (response.status === 200) {
        // Transform data if transform function provided
        const responseData = response.data;
        data.value = transformResponse
          ? transformResponse(responseData)
          : responseData;
        return data.value;
      }

      return null;
    } catch (err) {
      error.value = err;
      onError(err);
      return null;
    } finally {
      loading.value = false;
    }
  };

  // If immediate is true, fetch on creation
  if (immediate) {
    fetchData();
  }

  return {
    data,
    loading,
    error,
    fetchData,
    refresh: fetchData,
  };
}
