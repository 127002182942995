import axios from "axios";
import cookiesService from "@/services/cookiesServiceNoAwait";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/api",
  headers: {
    "Content-type": "application/json",
  },
});

http.interceptors.request.use(
  (config) => {
    const authToken = cookiesService.getCookie("authToken");
    if (authToken?.token) {
      config.headers["Authorization"] = `Bearer ${authToken.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (originalRequest.url.includes("/china-us/submission/form")) {
        return Promise.reject(error);
      }

      if (error.config.url == "/stem-studio/check-educators-guide") {
        console.log("no redirect");
      } else if (error.config.url == "/stem-studio/orders") {
        return error.response;
      } else {
        // window.location = `${
        //   process.env.VUE_APP_LOGIN_URL
        // }/request-login?ref_path=${encodeURIComponent(
        //   window.location.pathname
        // )}`;
      }
    }
    return Promise.reject(error);
  }
);

// Tambahkan metode untuk membangun URL dengan query params
http.getUrlWithParams = (url, params) => {
  if (!params || Object.keys(params).length === 0) {
    return url;
  }

  // filter params if value is not null, undefined, or empty string
  const queryParts = [];

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length > 0) {
      value.forEach((item) => {
        if (!item) return;

        queryParts.push(`${key}[]=${encodeURIComponent(item)}`);
      });
    } else if (!Array.isArray(value) && value) {
      queryParts.push(`${key}=${encodeURIComponent(value)}`);
    }
  });

  const queryString = queryParts.join("&");

  return `${url}?${queryString}`;
};

export default http;
