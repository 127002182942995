<template>
  <div class="confirmation-container">
    <div class="confirmation-content">
      <img :src="illustration" class="confirmation-image" alt="" />
      <h3 class="confirmation-title">
        {{ title }}
      </h3>
      <p class="confirmation-description">
        {{ description }}
      </p>
      <p class="timer-message" v-if="!isLoading">
        {{ timerMessage }} <br v-if="showTimerBreak" />
        <template v-if="countdown > 0">
          <span class="countdown">{{ countdown }} seconds</span>
        </template>
      </p>
      <div v-if="isLoading" class="loading-container">
        <div class="loading-spinner"></div>
        <p class="loading-text">Sending email...</p>
      </div>
      <BaseButton
        v-else-if="countdown <= 0"
        variant="primary"
        @click="handleResend"
        class="resend-button"
        :disabled="isLoading"
      >
        {{ resendButtonText }}
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  defineProps,
  defineEmits,
  watch,
} from "vue";
import BaseButton from "./common/BaseButton.vue";

const props = defineProps({
  illustration: {
    type: String,
    default: "/images/email-illustration.png",
  },
  title: {
    type: String,
    default: "Please check your inbox",
  },
  description: {
    type: String,
    default:
      "An email has been sent with more details on how to reset your password.",
  },
  timerMessage: {
    type: String,
    default: "You can re-send the email after",
  },
  showTimerBreak: {
    type: Boolean,
    default: true,
  },
  initialCountdown: {
    type: Number,
    default: 60,
  },
  resendButtonText: {
    type: String,
    default: "Re-send e-mail",
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["resend"]);

const countdown = ref(props.initialCountdown);
let timer;

const startCountdown = () => {
  countdown.value = props.initialCountdown;
  timer = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value--;
    } else {
      clearInterval(timer);
    }
  }, 1000);
};

const handleResend = () => {
  emit("resend");
  if (!props.isLoading) {
    startCountdown();
  }
};

watch(
  () => props.isLoading,
  (newValue, oldValue) => {
    if (oldValue === true && newValue === false) {
      startCountdown();
    }
  }
);

onMounted(() => {
  if (!props.isLoading) {
    startCountdown();
  }
});

onBeforeUnmount(() => {
  if (timer) clearInterval(timer);
});
</script>

<style scoped>
/* Container styles */
.confirmation-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Content wrapper */
.confirmation-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

/* Image */
.confirmation-image {
  width: 50%;
  margin-bottom: 1.25rem;
}

/* Title */
.confirmation-title {
  font-size: 1.875rem;
  text-align: center;
  font-weight: 600;
  color: var(--primary-blue, #3498db);
  width: 100%;
}

/* Description */
.confirmation-description {
  text-align: center;
  color: #6b7280; /* text-gray-500 */
}

/* Timer message */
.timer-message {
  text-align: center;
  font-size: 1.25rem;
  color: #6b7280; /* text-gray-500 */
}

/* Countdown */
.countdown {
  font-weight: 700;
}

/* Resend button */
.resend-button {
  margin-top: 0.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.125rem;
}

/* Loading spinner */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  margin-top: 0.5rem;
}

.loading-text {
  color: var(--primary-blue, #3498db);
  font-size: 1rem;
  font-weight: 500;
}

.loading-spinner {
  width: 2.5rem;
  height: 2.5rem;
  border: 3px solid rgba(52, 152, 219, 0.2);
  border-radius: 50%;
  border-top-color: var(--primary-blue, #3498db);
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Fade transition for loading state */
.confirmation-content > * {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.loading-container {
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
