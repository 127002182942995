// src/services/CommentAdminService.js

import http from "./httpService";

class YsaAdminService {
  async getAllSchool(params) {
    const url = http.getUrlWithParams("/admin/dashboard/schools", params);
    return await http.get(url);
  }

  async getYsaSchools(params) {
    const url = http.getUrlWithParams("/schools-master", {
      ...params,
      is_ysa: true,
    });
    return await http.get(url);
  }

  async getAllStudent(params) {
    const url = http.getUrlWithParams("/admin/dashboard/students", params);
    return await http.get(url);
  }

  async getStudentsWithMasterclass(params) {
    const url = http.getUrlWithParams(
      "/admin/dashboard/students-masterclass",
      params
    );
    return await http.get(url);
  }

  async getAllTeacher(params) {
    const url = http.getUrlWithParams("/admin/dashboard/teachers", params);
    return await http.get(url);
  }

  async toggleStudentYSA(id) {
    return await http.put(`/admin/dashboard/students/${id}/toggle-ysa`);
  }

  async toggleTeacherYSA(id) {
    return await http.put(`/admin/dashboard/teachers/${id}/toggle-ysa`);
  }

  async toggleSchoolYSA(id) {
    return await http.put(`/admin/dashboard/schools/${id}/toggle-ysa`);
  }

  async getMasterclasses(params) {
    const url = http.getUrlWithParams("/masterclass", params);
    return await http.get(url);
  }

  async getMasterclassDropdown(params) {
    const url = http.getUrlWithParams("/masterclass-master", params);
    return await http.get(url);
  }

  async getMasterclassById(id) {
    return await http.get(`/masterclass/${id}`);
  }

  async updateMasterclass(id, data) {
    return await http.put(`/masterclass/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async createMasterclass(data) {
    return await http.post("/masterclass", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async deleteMasterclass(id) {
    return await http.delete(`/masterclass/${id}`);
  }

  async downloadSurveyData(params) {
    const url = http.getUrlWithParams(
      "/admin/dashboard/students-masterclass/export",
      params
    );

    return await http.get(url, {
      responseType: "blob",
    });
  }
}

export default new YsaAdminService();
