<template>
  <div style="display: flex; height: 100vh">
    <img
      src="/images/background-cap.svg"
      alt=""
      style="position: absolute; bottom: 0; left: 0; z-index: -999"
    />
    <div
      style="
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <router-link
        to="/"
        style="
          display: flex;
          gap: 19px;
          margin-top: 40px;
          margin-left: 40px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 50;
        "
      >
        <img src="/images/eddy.svg" alt="" />
      </router-link>

      <div style="display: flex; justify-content: center; width: 100%">
        <div style="width: 430px">
          <h1
            style="
              font-size: 32;
              font-weight: 600;
              margin-bottom: 1rem;
              color: #003d6a;
            "
          >
            Welcome Back!
          </h1>
        </div>
      </div>

      <div
        style="
          display: flex;
          gap: 20px;
          width: 100%;
          flex-direction: column;
          align-items: center;
          margin-top: 10px;
        "
      >
        <BaseInput
          type="text"
          label="Email*"
          placeholder="Input Email"
          v-model="email"
          style="width: 430px"
          :error="isEmailError"
          :errorMessage="emailErrorMessage"
        />

        <div
          style="display: flex; flex-direction: column; align-items: flex-start"
        >
          <BaseInput
            type="password"
            label="Password*"
            placeholder="Input Password"
            v-model="password"
            style="width: 430px"
            :error="isPasswordError"
            :errorMessage="passwordErrorMessage"
          />
          <div
            v-if="errorMessage"
            v-html="errorMessage"
            @click.prevent="handleErrorClick"
            style="
              text-align: left;
              color: #e00269;
              margin-top: 10px;
              width: 430px;
              overflow-wrap: break-word;
              word-break: break-word;
            "
          ></div>
        </div>
      </div>

      <div
        style="
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 5px;
        "
      >
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 430px;
          "
        >
          <div style="display: flex; align-items: center">
            <v-checkbox
              v-model="rememberMe"
              hide-details
              color="#e00069"
              style="
                color: #e00069;
                font-size: 14px;
                border-radius: 10px;
                margin-left: -8px;
              "
            ></v-checkbox>
            <p style="font-size: 14px; color: #e00069; font-weight: 400">
              Remember Me
            </p>
          </div>

          <router-link
            :to="{ name: 'ForgotPasswordPage' }"
            style="
              color: #e00069;
              font-size: 14px;
              font-weight: 400;
              cursor: pointer;
            "
            class="hover-underline"
          >
            Forgot Password?
          </router-link>
        </div>
      </div>

      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          margin-top: 20px;
        "
      >
        <BaseButton
          :loading="loading"
          :disabled="loading"
          @click="login"
          variant="primary"
          style="width: 430px"
        >
          Login
        </BaseButton>
        <div
          style="display: flex; gap: 10px; align-items: center; width: 430px"
        >
          <button
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 8px;
              height: 50px;
              border: 1px solid #3b4648;
              background-color: white;
              border-radius: 12px;
              font-size: 14px;
              font-weight: 400;
              flex: 1;
            "
            @click="loginWithGoogle"
          >
            <img src="/images/google.svg" alt="Google Icon" />
            Continue with Google
          </button>
          <button
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 8px;
              height: 50px;
              border: 1px solid #ccc;
              background-color: white;
              border-radius: 12px;
              color: #ccc;
              font-size: 14px;
              font-weight: 400;
              flex: 1;
            "
            disabled
          >
            <img
              src="/images/facebook.svg"
              alt="Facebook Icon"
              style="filter: grayscale(100%)"
            />
            Continue with Facebook
          </button>
        </div>
      </div>
    </div>

    <!-- right side -->
    <div
      style="
        width: 50%;
        background-color: #003d6a;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <div
        style="
          display: flex;
          justify-content: flex-end;
          width: 100%;
          margin-bottom: 1rem;
          margin-top: 45px;
          padding-right: 40px;
          position: absolute;
          right: 0;
          top: 0;
        "
      >
        <button
          :style="{
            padding: '8px',
            background: selectedLanguage === 'EN' ? '#E00069' : '#F2F3FB',
            color: selectedLanguage === 'EN' ? 'white' : '#E00069',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            width: '60px',
            fontSize: '14px',
          }"
          @click="setLanguage('EN')"
        >
          EN
        </button>
        <button
          :style="{
            padding: '8px',
            background: selectedLanguage === 'ES' ? '#E00069' : '#F2F3FB',
            color: selectedLanguage === 'ES' ? 'white' : '#E00069',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            width: '60px',
            fontSize: '14px',
          }"
          @click="setLanguage('ES')"
        >
          ES
        </button>
      </div>

      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img src="/images/login-illustration.svg" style="width: 50%" alt="" />
        <p
          style="
            color: white;
            font-size: 32px;
            font-weight: 600;
            margin-top: 32px;
          "
        >
          Sign up for Eddy!
        </p>
        <BaseButton
          variant="white"
          style="
            width: 430px;
            margin-top: 32px;
            font-size: 18px;
            font-weight: 600;
          "
          @click="router.push('/signup')"
        >
          New to Eddy? Sign up here!
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isAxiosError } from "axios";
import BaseInput from "../components/BaseInput.vue";
import authService from "../services/authService";
import cookiesService from "../services/cookiesService";
import BaseButton from "@/components/common/BaseButton.vue";

const router = useRouter();
const route = useRoute();

const email = ref("");
const password = ref("");
const selectedLanguage = ref("EN");
const rememberMe = ref(false);
const loading = ref(false);
const isEmailError = ref(false);
const emailErrorMessage = ref("");
const isPasswordError = ref(false);
const passwordErrorMessage = ref("");
const errorMessage = ref("");

function setLanguage(lang) {
  selectedLanguage.value = lang;
}

function validateForm() {
  isEmailError.value = !email.value;
  emailErrorMessage.value = isEmailError.value ? "Email required" : "";

  isPasswordError.value = !password.value;
  passwordErrorMessage.value = isPasswordError.value ? "Password required" : "";

  return !(isEmailError.value || isPasswordError.value);
}

async function login() {
  if (!validateForm()) {
    return;
  }

  loading.value = true;
  try {
    const data = {
      email: email.value,
      password: password.value,
      rememberMe: rememberMe.value,
    };
    const response = await authService.login(data);

    if (response.status === 200) {
      const data = response?.data?.data;
      const userData = data?.user;

      if (userData) {
        await cookiesService.setCookie("authToken", {
          token: data.token,
          role: data.role,
          ...userData,
        });
      }

      if (rememberMe.value) {
        localStorage.setItem("savedEmail", email.value);
        localStorage.setItem("savedPassword", password.value);
      } else {
        localStorage.removeItem("savedEmail");
        localStorage.removeItem("savedPassword");
      }

      router.push("/");
    } else {
      errorMessage.value = response?.data?.message;
    }
  } catch (error) {
    if (isAxiosError(error)) {
      console.log(error);
      if (error?.response?.status >= 400 && error?.response?.status <= 499) {
        errorMessage.value = error?.response?.data?.message;
      } else {
        errorMessage.value = "Something went wrong.";
      }
    } else {
      errorMessage.value = "Incorrect password or email.";
    }
  } finally {
    loading.value = false;
  }
}

function loginWithGoogle() {
  const googleOAuthUrl = `${process.env.VUE_APP_API_URL}/api/oauth/connect/google`;
  window.location.href = googleOAuthUrl;
}

onMounted(() => {
  const savedEmail = localStorage.getItem("savedEmail");
  const savedPassword = localStorage.getItem("savedPassword");

  if (savedEmail && savedPassword) {
    email.value = savedEmail;
    password.value = savedPassword;
    rememberMe.value = true;
  }

  const queryErrorMessage = route.query["error-message"];
  if (queryErrorMessage && !errorMessage.value) {
    errorMessage.value = queryErrorMessage;
  }
});
</script>

<style>
input[type="checkbox"].custom-checkbox {
  width: 1.5em;
  height: 1.5em;
  accent-color: #d62467;
  margin-right: 8px;
  border: 2px solid #d62467;
  border-radius: 4px;
}
</style>
