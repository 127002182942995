<template>
  <v-dialog v-model="localModelValue" max-width="500px">
    <v-card class="modal-card">
      <v-card-title class="modal-header">
        <h3>{{ title }}</h3>
        <v-btn icon class="close-button" @click="cancel" variant="plain">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="modal-content">
        <div class="icon-container">
          <v-icon class="warning-icon">mdi-alert-circle</v-icon>
        </div>

        <div class="message-container">
          <div class="message-title">{{ messageTitle }}</div>
          <div class="message-text">{{ message }}</div>
        </div>
      </v-card-text>

      <v-card-actions class="modal-actions">
        <v-btn class="confirm-button" @click="confirm" :loading="loading">
          {{ confirmText }}
        </v-btn>

        <v-btn class="cancel-button" @click="cancel">
          {{ cancelText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed, defineProps, defineEmits } from "vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "Confirmation",
  },
  message: {
    type: String,
    default: "Are you sure you want to proceed?",
  },
  confirmText: {
    type: String,
    default: "Confirm",
  },
  cancelText: {
    type: String,
    default: "Cancel",
  },
  confirmColor: {
    type: String,
    default: "error",
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "confirm", "cancel"]);

const localModelValue = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const confirm = () => {
  emit("confirm");
};

const cancel = () => {
  emit("cancel");
  localModelValue.value = false;
};
</script>
<style scoped>
.modal-card {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.12);
  background-color: white;
  position: relative;
}

.modal-header {
  padding: 16px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #e60073;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
}

.close-button {
  min-width: 24px;
  width: 24px;
  height: 24px;
  padding: 0;
}

.modal-content {
  padding: 24px 20px;
  text-align: center;
}

.icon-container {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.warning-icon {
  font-size: 64px !important;
  color: #e60073;
}

.message-container {
  margin-bottom: 8px;
}

.message-title {
  font-weight: 600;
  font-size: 18px;
  color: #e60073;
  margin-bottom: 8px;
}

.message-text {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}

.modal-actions {
  padding: 0 20px 24px;
  display: flex;
  justify-content: center;
  gap: 12px;
}

.confirm-button {
  background-color: #e60073 !important;

  color: white !important;
  text-transform: none;
  font-weight: normal;
  padding: 0 20px !important;
  height: 40px;
  border-radius: 4px;
  box-shadow: none !important;
}

.cancel-button {
  background-color: #555 !important;
  color: white !important;
  text-transform: none;
  font-weight: normal;
  padding: 0 20px !important;
  height: 40px;
  border-radius: 4px;
  box-shadow: none !important;
}
</style>
