<template>
  <div>
    <div style="overflow-y: auto; overflow-x: hidden">
      <div class="admin-table-container">
        <table class="admin-table" style="width: 100%">
          <thead>
            <tr>
              <th
                v-for="header in headers"
                :key="header.text"
                :style="{
                  backgroundColor: '#003d6a',
                  color: '#ffffff',
                  fontSize: '16px',
                  fontWeight: '600',
                  textAlign: 'left',
                  paddingTop: '15px',
                  maxWidth: '80px',
                  height: '50px',
                  wordWrap: 'break-word',
                  cursor: header.sortable ? 'pointer' : 'default',
                }"
                @click="header.sortable ? sortColumn(header.value) : null"
              >
                <div
                  style="
                    display: flex;
                    gap: 4px;
                    align-items: center;
                    flex-wrap: wrap;
                  "
                >
                  <span>{{ header.text }}</span>
                  <img
                    v-if="header.sortable"
                    src="/images/filter-table.svg"
                    style="margin-left: 5px; max-height: 20px"
                    alt="filter"
                  />
                  <div
                    v-if="header.info"
                    class="info-icon-container"
                    @click.stop
                  >
                    <img
                      src="/images/icon/info-circle.svg"
                      alt="info"
                      class="info-icon"
                    />
                    <div class="tooltip">
                      <div v-html="header.info"></div>
                    </div>
                  </div>
                </div>
              </th>
              <th
                v-if="isAction || customActions.length > 0"
                :style="{
                  backgroundColor: '#003d6a',
                  color: '#ffffff',
                  fontSize: '16px',
                  fontWeight: '600',
                  textAlign: 'center',
                  paddingTop: '15px',
                  border: 'none',
                  width: 'auto',
                  height: '50px',
                  wordWrap: 'break-word',
                }"
              >
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            <!-- Skeleton Loading -->
            <template v-if="loading">
              <tr>
                <td colspan="6" style="padding: 10px; border: none">
                  <div class="spinner-container">
                    <div class="spinner"></div>
                  </div>
                </td>
              </tr>
            </template>

            <!-- Actual Data -->
            <template v-else>
              <tr v-if="!items?.length">
                <td
                  :colspan="
                    isAction || customActions.length > 0
                      ? headers.length + 1
                      : headers.length
                  "
                  style="text-align: center; padding: 20px; font-size: 16px"
                >
                  No data available
                </td>
              </tr>
              <tr v-else v-for="item in sortedItems" :key="item.id">
                <td
                  v-for="header in headers"
                  :key="header.text"
                  :style="{
                    textAlign: 'left',
                    fontSize: '16px',
                    fontWeight: '400',
                    padding: '10px',
                    borderBottom: '1px solid #ddd',
                    maxWidth: '50px',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                  }"
                >
                  {{
                    typeof item[header.value] === "string"
                      ? item[header.value].trim() || "-"
                      : item[header.value]
                  }}
                </td>

                <td v-if="isAction || customActions.length > 0">
                  <div
                    style="
                      display: flex;
                      gap: 8px;
                      align-items: center;
                      justify-content: center;
                      min-width: fit-content;
                    "
                  >
                    <!-- Standard actions -->
                    <button
                      v-if="isViewAction"
                      style="cursor: pointer"
                      @click="triggerAction('view', item)"
                      :disabled="item.disableAction?.view"
                    >
                      <img
                        :style="{
                          filter: item.disableAction?.view
                            ? 'grayscale(100%)'
                            : 'none',
                        }"
                        src="/images/open-eye.svg"
                        alt="view"
                      />
                    </button>

                    <button
                      v-if="isEditAction"
                      style="cursor: pointer"
                      @click="triggerAction('edit', item)"
                      :disabled="item.disableAction?.edit"
                    >
                      <img
                        :style="{
                          filter: item.disableAction?.edit
                            ? 'grayscale(100%)'
                            : 'none',
                        }"
                        src="/images/edit-pencil.svg"
                        alt="edit"
                      />
                    </button>

                    <button
                      v-if="isDeleteAction"
                      style="cursor: pointer"
                      @click="triggerAction('delete', item)"
                      :disabled="item.disableAction?.delete"
                    >
                      <img
                        :style="{
                          filter: item.disableAction?.delete
                            ? 'grayscale(100%)'
                            : 'none',
                        }"
                        src="/images/delete.svg"
                        alt="delete"
                      />
                    </button>

                    <!-- Custom actions -->
                    <template
                      v-for="(action, actionIndex) in customActions"
                      :key="actionIndex"
                    >
                      <button
                        style="cursor: pointer"
                        @click="triggerCustomAction(action.type, item)"
                        :disabled="action.isDisabled && action.isDisabled(item)"
                      >
                        <img
                          :style="{
                            filter:
                              action.isDisabled && action.isDisabled(item)
                                ? 'grayscale(100%)'
                                : 'none',
                          }"
                          :src="
                            typeof action.icon === 'function'
                              ? action.icon(item)
                              : action.icon
                          "
                          :alt="action.type"
                        />
                      </button>
                    </template>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <BasePagination
      :total-pages="totalPages"
      :model-value="currentPage"
      @update:model-value="updatePage"
    />
  </div>
</template>

<script setup>
import { ref, defineEmits, computed, defineProps } from "vue";
import BasePagination from "./BasePagination.vue";

// Emit events for actions
const emit = defineEmits([
  "action",
  "sort",
  "update:current-page",
  "custom-action",
]);

const triggerAction = (type, item) => {
  emit("action", { type, item });
};

const triggerCustomAction = (type, item) => {
  emit("custom-action", { type, item });
};

// Define props
const props = defineProps({
  headers: {
    type: Array,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
  isAction: {
    type: Boolean,
    default: false,
  },
  isViewAction: {
    type: Boolean,
    default: false,
  },
  isEditAction: {
    type: Boolean,
    default: false,
  },
  isDeleteAction: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  useApiSort: {
    type: Boolean,
    default: false,
  },
  totalPages: {
    type: Number,
    required: true,
  },
  currentPage: {
    type: Number,
    required: true,
  },
  customActions: {
    type: Array,
    default: () => [],
  },
});

// Function to update page and emit event
const updatePage = (page) => {
  emit("update:current-page", page);
};

// Pagination and sorting states
const currentSort = ref({ column: "", order: "asc" });

// Function to handle column sorting
const sortColumn = (column) => {
  if (currentSort.value.column === column) {
    currentSort.value.order =
      currentSort.value.order === "asc" ? "desc" : "asc";
  } else {
    currentSort.value.column = column;
    currentSort.value.order = "asc";
  }

  if (props.useApiSort) {
    emit("sort", {
      sort_by: column,
      sort_dir: currentSort.value.order.toUpperCase(),
    });
  }
};

// Computed property for sorted items
const sortedItems = computed(() => {
  // If no column is selected, return the original items
  if (!currentSort.value.column) return props.items;

  const sorted = [...props.items].sort((a, b) => {
    const valA = a[currentSort.value.column];
    const valB = b[currentSort.value.column];

    // Handle undefined or null values
    if (valA === null || valA === undefined) return 1;
    if (valB === null || valB === undefined) return -1;

    // Compare values
    if (valA < valB) return currentSort.value.order === "asc" ? -1 : 1;
    if (valA > valB) return currentSort.value.order === "asc" ? 1 : -1;
    return 0;
  });

  return sorted;
});
</script>

<style scoped>
.skeleton-loader {
  height: 20px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.info-icon-container {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.info-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: 200px;
  background-color: #fff;
  color: #333;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  font-size: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5px;
  opacity: 0;
  transition: opacity 0.3s;
}

.info-icon-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}
</style>
