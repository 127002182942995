<template>
  <button
    :class="[
      'button',
      `button-${variant}`,
      { 'button-loading': loading },
      className,
    ]"
    :disabled="disabled || loading"
    v-bind="$attrs"
    @click="$emit('click', $event)"
  >
    <div class="button-content">
      <!-- Loading Spinner -->
      <div v-if="loading" class="loading-spinner">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle
            class="spinner-circle"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="spinner-path"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>

      <!-- Loading Text -->
      <span v-if="loading">{{ loadingText }}</span>

      <!-- Default Content -->
      <span v-else>
        <slot />
      </span>
    </div>
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    variant: {
      type: String,
      default: "default",
      validator: (value) =>
        ["primary", "secondary", "white", "default"].includes(value),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: "Loading...",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "",
    },
  },
  emits: ["click"],
};
</script>

<style scoped>
/* Base Button Styles */
.button {
  border-radius: 0.75rem;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;
  transition: all 0.3s;
  height: 3rem;
  border: none;
  outline: none;
}

.button:active {
  transform: scale(0.95);
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Button Content Layout */
.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 100%;
  padding: 0 1rem;
}

/* Loading Spinner */
.loading-spinner {
  animation: spin 1s linear infinite;
  height: 1.25rem;
  width: 1.25rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Variant Styles */
.button-primary {
  background-color: var(--primary-blue, #3498db);
  color: white;
}

.button-primary:not(:disabled):hover {
  background-color: var(--primary-green, #2ecc71);
  color: white;
}

.button-secondary {
  background-color: var(--primary-green, #2ecc71);
  color: white;
}

.button-secondary:not(:disabled):hover {
  background-color: var(--primary-blue, #3498db);
  color: white;
}

.button-white {
  background-color: white;
  color: var(--primary-blue, #3498db);
}

.button-white:not(:disabled):hover {
  background-color: var(--primary-green, #3498db);
  color: white;
}

.button-default {
  background-color: transparent;
}

/* Loading spinner SVG styles */
.spinner-circle {
  opacity: 0.25;
}

.spinner-path {
  opacity: 0.75;
}
</style>
