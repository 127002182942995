<template>
  <div class="flex-container">
    <div class="flex-item" style="padding: 0">
      <div
        id="dynamicHeightDiv"
        class="pa-5 background-style hero-section-3 responsive-layout"
      >
        <div>
          <img
            src="/images/chinaus/submission-background.svg"
            alt="Arrow Icon"
            class="responsive-image-submission-page"
          />
        </div>
        <div class="label-cover">
          <div class="white-backdrop">Submission</div>
        </div>
      </div>
    </div>
  </div>

  <NavigationBarComponent></NavigationBarComponent>

  <div class="welcome-page-4-dsa welcome-page-5 pb-5">
    <div
      v-if="currentAppState === AppState.isLogin"
      class="container d-flex justify-center align-center py-5"
    >
      <div class="card-chinaus">
        <div class="image-container-1 text-center">
          <img
            height="280"
            src="/images/chinaus/fill-out-bro.svg"
            alt="Arrow Icon"
          />
        </div>
        <div class="label-container-1">
          <h2 class="label-card-chinaus center-mobile">Submission</h2>
          <div class="fs-11 mb-3">
            The submission portal will open on <b>25 May 2024.</b>
          </div>
          <div class="fs-11 mb-3">
            [UPDATED] Please be reminded to submit your project by
            <b>30 June 2024, 23:59 PST.</b>
          </div>
          <div class="fs-11 mb-3">
            The early submission deadline is <b>10 June 2024, 23:59 PST.</b>
          </div>
          <div>
            <a
              :href="`${
                isSubmissionOpen ? '/china-us/submission-submit' : '#'
              }`"
              class="button button-email"
              :style="`${
                isSubmissionOpen ? '' : 'pointer-events: none; opacity: 0.5;'
              } text-decoration:none;`"
            >
              <img
                src="/images/chinaus/document-upload.svg"
                alt="Document Download"
              />
              Start Application
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="currentAppState === AppState.isNotLogin"
      class="container d-flex justify-center align-center py-5"
    >
      <div class="card-chinaus">
        <div class="image-container text-center">
          <img
            height="280"
            src="/images/chinaus/computer-login-bro.svg"
            alt="Arrow Icon"
          />
        </div>
        <div class="label-container">
          <h2 class="label-card-chinaus center-mobile">Submission</h2>
          <div>Please log in or sign up to submit a project.</div>
          <div>
            <a
              href="/signin"
              @click="promptSignIn"
              class="button-login mt-4"
              style="text-decoration: none"
            >
              Log in
            </a>
            <a
              href="/signup"
              @click="promptSignUp"
              class="button-signup mt-4 mx-2"
              style="text-decoration: none"
            >
              Sign up
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="currentAppState === AppState.isSubmitted"
      class="container d-flex justify-center align-center py-5"
    >
      <div class="card-chinaus">
        <div class="image-container-1 text-center">
          <img
            class="card-image-china-us"
            src="/images/chinaus/submission-icon.svg"
            alt="Arrow Icon"
          />
        </div>
        <div class="label-container-1">
          <h2 class="label-card-chinaus center-mobile">Project Submitted</h2>
          <div>
            You’ve already submitted your project. Please check your email for
            more information about the progress of your submission.
          </div>
          <div>
            <a
              href="/china-us/submission-detail"
              class="button button-email"
              style="text-decoration: none"
            >
              View
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="currentAppState === AppState.isMobile"
      class="container d-flex justify-center align-center py-5"
    >
      <div class="card-chinaus">
        <div class="image-container-1 text-center">
          <img
            class="card-image-china-us"
            src="/images/chinaus/submission-icon.svg"
            alt="Arrow Icon"
          />
        </div>
        <div class="label-container-1">
          <h2 class="label-card-chinaus center-mobile">Submission</h2>
          <div>
            This feature is not compatible with mobile access. You should submit
            your project from a computer instead.
          </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="currentAppState === AppState.isAdminorYSA"
      class="container d-flex justify-center align-center py-5"
    >
      <div class="card-chinaus">
        <div class="image-container-1 text-center">
          <img
            class="card-image-china-us"
            src="/images/chinaus/submission-icon.svg"
            alt="Arrow Icon"
          />
        </div>
        <div class="label-container-1">
          <h2 class="label-card-chinaus center-mobile">Submission</h2>
          <div>You are admin or YSA you don't have access to this page</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, nextTick } from "vue";
import NavigationBarComponent from "@/components/discussion/NavigationBarComponent.vue";
import cookiesService from "@/services/cookiesService";
import ChinaUsService from "@/services/chinausService.js";

const isSubmissionOpen = ref(false);

const AppState = Object.freeze({
  isMobile: "isMobile",
  isLogin: "isLogin",
  isNotLogin: "isNotLogin",
  isSubmitted: "isSubmitted",
  isAdminorYSA: "isAdminorYSA",
});

const getSubmissionForm = async () => {
  try {
    const response = await ChinaUsService.getSubmissionForm();

    if (response.status === 403) {
      currentAppState.value = AppState.isAdminorYSA;
    }
  } catch (error) {
    console.log(error.message);
  }
};

const currentAppState = ref(AppState.isNotLogin);

function promptSignIn(event) {
  event.preventDefault();
  window.location.href = `${
    process.env.VUE_APP_LOGIN_URL
  }/request-login?ref_path=${encodeURIComponent(window.location.pathname)}`;
}

function promptSignUp(event) {
  event.preventDefault();
  window.location.href = `${
    process.env.VUE_APP_LOGIN_URL
  }/request-login?tab=show-register&ref_path=${encodeURIComponent(
    window.location.pathname
  )}`;
}

const checkAuthToken = async () => {
  try {
    const authToken = await cookiesService.getCookie("authToken");
    if (authToken) {
      currentAppState.value = AppState.isLogin;
      clearInterval(intervalId);
    }
  } catch (error) {
    console.error(error);
  }
};

checkAuthToken();

const intervalId = setInterval(checkAuthToken, 500);

function adjustHeight() {
  const width = window.innerWidth;
  let height;
  if (width <= 540) {
    height = width * (1500 / 1440);
  } else {
    height = width * (545 / 1440);
  }
  const dynamicHeightDiv = document.getElementById("dynamicHeightDiv");
  dynamicHeightDiv.style.height = `${height}px`;
}

function setBackgroundImage() {
  const width = window.innerWidth;
  const dynamicHeightDiv = document.getElementById("dynamicHeightDiv");
  if (width <= 540) {
    dynamicHeightDiv.style.backgroundImage =
      "url('/images/chinaus/background-mobile.svg')";
  } else {
    dynamicHeightDiv.style.backgroundImage =
      "url('/images/chinaus/background.svg')";
  }
}

onMounted(async () => {
  adjustHeight();
  setBackgroundImage();
  setBackgroundPosition();
  scrollToFAQ();
  window.addEventListener("resize", adjustHeight);

  const welcomePageElement = document.querySelector(".welcome-page-4-dsa");

  if (welcomePageElement) {
    welcomePageElement.style.backgroundImage = `url("${process.env.VUE_APP_URL}/images/chinaus/faq-eclipse-green.svg")`;
  }

  const authToken = await cookiesService.getCookie("authToken");

  if (authToken) {
    currentAppState.value = AppState.isLogin;
  }

  const width = window.innerWidth;

  if (width <= 768) {
    currentAppState.value = AppState.isMobile;
    console.log("currentAppState.value", currentAppState.value);
  }

  await getSubmissionForm();

  isSubmissionOpen.value = true;
});

function setBackgroundPosition() {
  const welcomePageElement = document.querySelector(".welcome-page-4-dsa");
  const width = window.innerWidth;

  if (width <= 768) {
    welcomePageElement.style.backgroundPosition = `right top`;
  } else {
    welcomePageElement.style.backgroundPosition = `right top`;
  }
}

onBeforeUnmount(() => {
  window.removeEventListener("resize", adjustHeight);
});

function scrollToFAQ() {
  nextTick(() => {
    const faqButton = document.getElementById("faqButton");
    if (faqButton) {
      const scrollContainer = faqButton.closest(".scroll-container");
      const scrollPosition =
        faqButton.offsetLeft +
        faqButton.clientWidth / 2 -
        window.innerWidth / 2;
      scrollContainer.scrollLeft = scrollPosition + 26;
    }
  });
}
</script>

<style>
.card-chinaus-accordion {
  display: flex;
  gap: 1em;
  background-color: rgba(242, 243, 251, 0.55);
  padding: 2em 2em;
  border-radius: 15px;
  width: 100%;
  justify-content: space-evenly;
  flex-direction: column;
}

.button-email {
  background-color: #00925e;
  border: none;
  border-radius: 16px;
  padding: 10px 15px;
  color: white;
  font-size: 0.8em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  font-weight: 550;
}

.button-email img {
  width: 18px;
  height: auto;
}

.welcome-page-4-dsa {
  background-repeat: no-repeat;
}

.accordion-eqdsad {
  font-size: 1.2em;
  font-weight: 500;
}

.responsive-image-submission-page {
  height: 650px;
}

@media (max-width: 2311px) {
  .responsive-image-submission-page {
    height: 550px;
  }
}

@media (max-width: 1964px) {
  .responsive-image-submission-page {
    height: 400px;
  }
}

@media (max-width: 1307px) {
  .responsive-image-submission-page {
    height: 350px;
  }
}

@media (max-width: 1206px) {
  .responsive-image-submission-page {
    height: 300px;
  }
}

@media (max-width: 1133px) {
  .responsive-image-submission-page {
    height: 300px;
  }
}

@media (max-width: 1025px) {
  .responsive-image-submission-page {
    height: 250px;
  }
}

@media (max-width: 898px) {
  .responsive-image-submission-page {
    height: 250px;
  }
}

@media (max-width: 768px) {
  .responsive-image-submission-page {
    height: 200px;
  }
}

@media (max-width: 540px) {
  .responsive-image-submission-page {
    height: 200px;
    margin-bottom: 1.5em;
  }
}

@media (max-width: 360px) {
  .responsive-image-submission-page {
    height: 200px;
    margin-bottom: 1.5em;
  }
}
</style>
