<template>
  <div class="input-container-333">
    <input type="text" v-model="localTitle" placeholder="Enter Title Here* (Max 50 Characters)" class="title-input"
      @input="emitChanges" :disabled="disabled" maxlength="50" />
    <input type="text" v-model="localBlurb" placeholder="Enter Blurb Here* (Max 500 Characters)" class="blurb-input"
      @input="emitChanges" :disabled="disabled" maxlength="500" />
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from "vue";

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  blurb: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["input-change"]);

// Create local reactive refs that will be used for v-model
const localTitle = ref(props.title || "");
const localBlurb = ref(props.blurb || "");

// Watch for prop changes to update local state
watch(
  () => props.title,
  (newValue) => {
    if (newValue !== localTitle.value) {
      // doesnt allow text more than 50 characters
      localTitle.value = newValue;
    }
  }
);

watch(
  () => props.blurb,
  (newValue) => {
    if (newValue !== localBlurb.value) {
      localBlurb.value = newValue;
    }
  }
);

// Emit changes to parent component
const emitChanges = () => {
  emit("input-change", {
    title: localTitle.value,
    blurb: localBlurb.value,
  });
};

// Initial emit to ensure parent has the initial values
setTimeout(() => {
  emitChanges();
}, 0);
</script>

<style scoped>
.input-container-333 {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  margin: auto;
}

input {
  width: 100%;
  border: none;
  outline: none;
  padding: 10px;
  margin: 5px 0;
  font-size: 1.2em;
  color: #333;
  /* Changed from #ccc to make text more visible */
}

input.title-input {
  font-size: 2em;
  font-weight: bold;
}

input.blurb-input {
  font-size: 1em;
}

input::placeholder {
  color: #ccc;
}

input.title-input:focus,
input.blurb-input:focus {
  border-bottom: 1px solid #00925e;
}
</style>
