<template>
  <div class="pagination-wrapper">
    <div v-if="totalPages > 0">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        :total-visible="10"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          width: 100%;
        "
        @update:model-value="handlePageChange"
      >
        <template v-slot:item="{ props, isActive }">
          <button
            v-bind="props"
            :style="{
              width: '40px',
              height: '40px',
              borderRadius: '30%',
              fontSize: '16px',
              fontWeight: 'bold',
              border: '1px solid #e0e0e0',
              backgroundColor: isActive ? '#003D6A' : 'white',
              color: isActive ? 'white' : '#003D6A',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              borderColor: isActive ? 'white' : '#003D6A',
            }"
          >
            {{ extractPageNumber(props["aria-label"]) }}
          </button>
        </template>
      </v-pagination>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from "vue";

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  totalPages: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const currentPage = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

function extractPageNumber(ariaLabel) {
  if (ariaLabel === undefined) return "...";

  const match = ariaLabel.match(/page (\d+)/i);
  return match ? match[1] : "";
}

function handlePageChange(newPage) {
  emit("update:modelValue", newPage);
}
</script>

<style scoped>
.pagination-wrapper {
  margin-top: 2px;
}
</style>
