<template v-else>
  <h2 style="font-size: 36px; color: #003d6a; font-weight: 600">Account</h2>
  <div
    style="border: solid 1px #d8dae5; margin-top: 21px; margin-bottom: 21px"
  ></div>

  <div style="display: flex; justify-content: space-between">
    <div>
      <h3 style="color: #e00069; font-size: 28px; font-weight: 600">
        Personal Information
      </h3>

      <div v-if="isEditPersonalInformation" style="margin-top: 21px">
        <div style="display: flex; gap: 15px">
          <BaseInput v-model="firstName" label="First Name" />
          <BaseInput v-model="lastName" label="Last Name" />
        </div>
        <BaseInput
          v-model="email"
          label="Email"
          disabled
          style="margin-top: 5px"
        />

        <button
          style="
            background-color: #003d6a;
            width: 120px;
            padding: 5px;
            margin-top: 15px;
            border-radius: 10px;
            color: white;
          "
        >
          <span v-if="isLoading">Saving...</span>
          <span v-else>Save</span>
        </button>
      </div>
      <div
        v-else
        style="
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          gap: 15px;
        "
      >
        <div>
          <p style="color: #787878; font-size: 20px; font-weight: 400">Name</p>
          <p style="color: #3b4648; font-size: 20px; font-weight: 500">
            {{ user?.fullname }}
          </p>
        </div>

        <div>
          <p style="color: #787878; font-size: 20px; font-weight: 400">Email</p>
          <p style="color: #3b4648; font-size: 20px; font-weight: 500">
            {{ user?.email }}
          </p>
        </div>
      </div>
    </div>
    <div>
      <p
        v-if="isEditPersonalInformation"
        @click="isEditPersonalInformation = false"
        style="
          color: #4c4ddc;
          border-bottom: 1px solid #4c4ddc;
          font-size: 20px;
          font-weight: 500;
          cursor: pointer;
        "
      >
        Cancel
      </p>
      <p
        v-else
        @click="startEdit"
        style="
          color: #4c4ddc;
          border-bottom: 1px solid #4c4ddc;
          font-size: 20px;
          font-weight: 500;
          cursor: pointer;
        "
      >
        Edit
      </p>
    </div>
  </div>

  <!-- about me -->
  <div
    style="border: solid 1px #d8dae5; margin-top: 21px; margin-bottom: 21px"
  ></div>
  <div style="display: flex; justify-content: space-between">
    <div>
      <h3 style="color: #e00069; font-size: 28px; font-weight: 600">School</h3>

      <div v-if="isEditSchool" style="margin-top: 15px; min-width: 500px">
        <!-- <SelectSearchComponent :items="schoolList" v-model="school" /> -->
        <div style="display: flex; flex-direction: column; gap: 15px">
          <BaseInput
            v-model="school"
            label="School"
            placeholder="Input School Name"
            type="select"
            :options="schoolList"
            :apiSearchEnabled="true"
            :apiSearchFunction="searchSchools"
            @update:options="updateSchoolOptions"
            :errorMessage="errorMessage"
            :error="errorMessage ? true : false"
            :showInputError="false"
          />
          <!-- <BaseInput
            v-model="country"
            label="Country"
            placeholder="Input Country"
          />
          <BaseInput
            v-model="city"
            label="City/Town"
            placeholder="Input City"
          /> -->
        </div>
        <p style="color: red; margin-top: 10px">{{ errorMessage }}</p>
        <button
          style="
            background-color: #003d6a;
            width: 120px;
            padding: 5px;
            margin-top: 15px;
            border-radius: 10px;
            color: white;
          "
          @click="saveSchool"
        >
          <span v-if="isLoading">Saving...</span>
          <span v-else>Save</span>
        </button>
      </div>
      <div v-else style="margin-top: 10px">
        <p style="color: #787878; font-size: 20px; font-weight: 400">School</p>
        <p
          style="
            color: #3b4648;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 8px;
          "
        >
          {{ user?.school.name || "-" }}
        </p>
        <!-- <p style="color: #787878; font-size: 20px; font-weight: 400">City</p> -->
        <!-- <p
          style="
            color: #3b4648;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 8px;
          "
        >
          {{ user?.city || "-" }}
        </p>
        <p style="color: #787878; font-size: 20px; font-weight: 400">Country</p>
        <p
          style="
            color: #3b4648;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 8px;
          "
        >
          {{ user?.country || "-" }}
        </p> -->
      </div>
    </div>
    <div>
      <p
        v-if="isEditSchool"
        @click="isEditSchool = false"
        style="
          color: #4c4ddc;
          border-bottom: 1px solid #4c4ddc;
          font-size: 20px;
          font-weight: 500;
          cursor: pointer;
        "
      >
        Cancel
      </p>
      <p
        v-else
        @click="startEditAboutMe"
        style="
          color: #4c4ddc;
          border-bottom: 1px solid #4c4ddc;
          font-size: 20px;
          font-weight: 500;
          cursor: pointer;
        "
      >
        Edit
      </p>
    </div>
  </div>
  <!-- time zone -->
  <div
    style="border: solid 1px #d8dae5; margin-top: 21px; margin-bottom: 21px"
  ></div>
  <div style="display: flex; justify-content: space-between">
    <div>
      <h3 style="color: #e00069; font-size: 28px; font-weight: 600">
        Time Zone
      </h3>

      <div v-if="isEditTimeZone" style="margin-top: 15px">
        <BaseInput
          label="Time Zone"
          type="select"
          v-model="timeZone"
          :options="timeZones"
          :maxHeight="'245'"
          style="width: 350px"
        />

        <button
          style="
            background-color: #003d6a;
            width: 120px;
            padding: 5px;
            margin-top: 15px;
            border-radius: 10px;
            color: white;
          "
        >
          <span v-if="isLoading">Saving...</span>
          <span v-else>Save</span>
        </button>
      </div>
      <div v-else style="margin-top: 10px">
        <p style="font-size: 20px; font-weight: 500">
          {{ selectedTimeZone?.text || "-" }}
        </p>
      </div>
    </div>
    <div>
      <p
        v-if="isEditTimeZone"
        @click="isEditTimeZone = false"
        style="
          color: #4c4ddc;
          border-bottom: 1px solid #4c4ddc;
          font-size: 20px;
          font-weight: 500;
          cursor: pointer;
        "
      >
        Cancel
      </p>
      <p
        v-else
        style="
          color: #4c4ddc;
          border-bottom: 1px solid #4c4ddc;
          font-size: 20px;
          font-weight: 500;
          cursor: pointer;
        "
      >
        Edit
      </p>
    </div>
  </div>
</template>

<script setup>
import cookiesService from "@/services/cookiesService";
import ysaAdminService from "@/services/ysaAdminService";
import moment from "moment-timezone";
import { onMounted, ref } from "vue";
import BaseInput from "../BaseInput.vue";
import userService from "@/services/userService";

const isEditPersonalInformation = ref(false);
const isEditSchool = ref(false);
const isEditTimeZone = ref(false);
// const selectedInterests = ref([]);
// const selectedSubject = ref([]);
const timeZones = ref([]);
const selectedTimeZone = ref(null);
const user = ref(null);
const isLoading = ref(true);
const firstName = ref("");
const lastName = ref("");
const email = ref("");
const school = ref("");
const country = ref("");
const city = ref(null);
const district = ref("");
const timeZone = ref("");
const errorMessage = ref(null);

const filter = ref({
  limit: 10,
});

const schoolList = ref([]);

const checkAuthToken = async () => {
  isLoading.value = true;
  try {
    const userData = await cookiesService.getCookie("authToken");
    if (userData) {
      user.value = userData;
      //   selectedInterests.value = authToken.data.data.teaches;
      //   selectedSubject.value = authToken.data.data.interests;

      if (user.value?.timezone) {
        timeZone.value = user.value.timezone;
      }

      //   localStorage.setItem("userData", JSON.stringify(authToken.data.data));
      window.dispatchEvent(new Event("userDataUpdated"));
    }
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const startEdit = () => {
  if (user.value) {
    firstName.value = user.value.firstname;
    lastName.value = user.value.last_name;
    email.value = user.value.email;
    isEditPersonalInformation.value = true;
  }
};

const startEditAboutMe = () => {
  if (user.value) {
    country.value = user.value.country;
    city.value = user.value.city;
    district.value = user.value.district_id;
    school.value = user.value.school.id;
  }
  isEditSchool.value = true;
};

const getAllSchool = async () => {
  try {
    const response = await ysaAdminService.getAllSchool(filter.value);

    if (response.status === 200) {
      console.log(response.data);
      const schools = response?.data?.data?.rows || [];

      schoolList.value = schools.map((school) => ({
        value: school.id,
        text: school.title,
      }));
    }
  } catch (error) {
    console.log(error);
  }
};

const searchSchools = async (query) => {
  try {
    // If query is empty, get all schools
    const response = await ysaAdminService.getAllSchool({
      search: query || "",
      ...filter.value,
    });

    if (response.status === 200) {
      const schools = response?.data?.data?.rows || [];
      return schools.map((school) => ({
        value: school.id,
        text: school.title,
      }));
    }
    return [];
  } catch (error) {
    console.error("Error searching schools:", error);
    return [];
  }
};

const updateSchoolOptions = (options) => {
  schoolList.value = options;
};

const saveSchool = async () => {
  errorMessage.value = null;

  if (!school.value) {
    errorMessage.value = "School is required";
    return;
  }

  try {
    isLoading.value = true;
    await userService.updateSchool({
      school: school.value,
      firstname: user.value?.firstname,
      last_name: user.value?.last_name,
    });

    const response = await userService.getProfile();
    const data = response.data?.data;
    if (data) {
      user.value = data;
    }
    isEditSchool.value = false;
  } catch (error) {
    errorMessage.value =
      error.response?.data?.message || "Failed to save school information";
    console.error("Error saving school information:", error);
  } finally {
    isLoading.value = false;
  }
};

const loadTimeZones = () => {
  const now = new Date();

  timeZones.value = moment.tz.names().map((zone) => {
    const currentTime = moment.tz(now, zone);
    const offset = currentTime.format("Z");
    const displayName = `${currentTime.format("HH:mm")}, ${zone.replace(
      "_",
      " "
    )} (GMT${offset})`;

    return {
      value: zone,
      text: displayName,
    };
  });
};

onMounted(() => {
  checkAuthToken();
  //   teachs();
  //   subjects();
  getAllSchool();
  loadTimeZones();
});
</script>

<style>
.interest-button {
  padding: 10px 20px;
  border: 1px solid #d8dae5;
  border-radius: 25px;
  background-color: #f5f5f5;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.interest-button.selected {
  background-color: #4c4ddc;
  border: 1px solid #4c4ddc;
  color: white;
}

.delete-account-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  padding: 10px;
  border-radius: 12px;
  background-color: #f7f8fc;
  border: 1px solid #e1e6f0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.delete-account-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #d6215a;
  color: white;
  font-size: 62px;
  font-weight: bold;
  position: absolute;
  margin-left: -20px;
}

.delete-account-text {
  flex: 1;
  margin: 0 20px;
  font-size: 16px;
  color: #3b4648;
  font-weight: 500;
  white-space: nowrap;
}

.delete-account-button {
  background-color: #d6215a;
  color: white;
  border: none;
  border-radius: 16px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-account-button:hover {
  background-color: #b51d4e;
}
</style>
