import cookiesService from "@/services/cookiesService";

export async function isAdmin() {
  try {
    const user = await cookiesService.getCookie("authToken");

    if (!user) {
      throw new Error("user not found");
    }

    return user.user_group_id === 1;
  } catch (error) {
    console.error(error);
    alert("Something went wrong. Please try again later.");

    return false;
  }
}
