<template>
    <div class="flex-container">
      <div class="flex-item" style="padding: 0">
        <div id="dynamicHeightDiv" class="pa-5 background-style hero-section-3 responsive-layout">
          <div>
            <img src="/images/chinaus/mobile-ux-bro.svg" alt="Arrow Icon" class="responsive-image-submission-list" />
          </div>
          <div class="label-cover">
            <div class="white-backdrop">
              View Other Project
            </div>
            <div class="white-backdrop-mini">Check out other projects which have been submitted!</div>
          </div>
        </div>
      </div>
    </div>
  
    <NavigationBarComponent></NavigationBarComponent>
  
    <div class="welcome-page-4-dsa welcome-page-5 pb-5">
      <div v-if="currentAppState === AppState.isLogin">
        <div class="container pt-5">
          <div style="display: flex; gap: 10px;">
            <img src="/images/chinaus/sort.svg" alt="">
            <select v-model="sort" @change="changeSort">
              <option value="newest">Sort by Newest</option>
              <option value="oldest">Sort by Oldest</option>
            </select>
          </div>
        </div>
    
        <div v-if="isLoading" class="grid-container container py-5">
          <div></div>
          <div class="mt-5 spinner"></div>
          <div></div>
        </div>
        <div v-else-if="!isLoading && gridItems.length === 0" class="text-center py-5">
          <img src="/images/chinaus/not-yet.svg" alt="Arrow Icon" class="responsive-image-submission-list">
          <div class="text-center color-primary text-medium-bold">No projects submitted yet...</div>
          <div class="text-center color-primary" style="font-size: 0.8em;">Be the first by submitting your project now!</div>
        </div>
        <div v-else class="grid-container container py-5">
          <div v-for="(item, index) in gridItems" :key="index" @click="openDetail(item.project.id)" class="grid-item">
            <img :src="`https://storage.eddy4teachers.com/${item.project.image_url}`" onerror="this.onerror=null; this.src='/images/chinaus/submission-list-default.png'" alt="Project Image">
            <div class="caption-1">{{ item.project.title }}</div>
          </div>
        </div>
    
        <div class="pagination-controls">
          <img src="/images/chinaus/vector-right.svg" class="mr-2" alt="" @click="changePage(currentPage - 1)">
          <button v-for="page in totalPages" :key="page" :class="{ 'page-active': page === currentPage }" @click="changePage(page)">
            {{ page }}
          </button>
          <img src="/images/chinaus/vector-left.svg" class="ml-2" alt="" @click="changePage(currentPage + 1)">
        </div>
      </div>

      <div v-else-if="currentAppState === AppState.isNotLogin" class="container d-flex justify-center align-center py-5">
          <div class="card-chinaus">
              <div class="image-container text-center">
                  <img height="280" src="/images/chinaus/computer-login-bro.svg" alt="Arrow Icon">
              </div>
              <div class="label-container">
                  <h2 class="label-card-chinaus center-mobile">View Other Projects</h2>
                  <div>
                      Please log in or sign up to submit a project.
                  </div>
                  <div>
                      <a href="/signin" @click="promptSignIn" class="button-login mt-4" style="text-decoration:none;">
                          Log in
                      </a>
                      <a href="/signup" @click="promptSignUp" class="button-signup mt-4 mx-2" style="text-decoration:none;"> 
                          Sign up 
                      </a>             
                  </div>
              </div>
          </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { onMounted, onBeforeUnmount, nextTick, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import NavigationBarComponent from '@/components/discussion/NavigationBarComponent.vue';
  import ChinaUsService from '@/services/chinausService.js';
  import cookiesService from '@/services/cookiesService';
  
  const gridItems = ref([]);
  const isLoading = ref(false);
  const currentPage = ref(1);
  const totalPages = ref(1);
  const sort = ref('newest'); // Menyimpan status sort

  const AppState = Object.freeze({
    isLogin: 'isLogin',
    isNotLogin: 'isNotLogin'
  });

  const currentAppState = ref(AppState.isNotLogin);
  
  const getAllSubmission = async (page, sort) => {
    try {
      isLoading.value = true;
      const response = await ChinaUsService.getAllSubmission(page, sort, 'submitted');
  
      if (response.status === 200) {
        isLoading.value = false;
        gridItems.value = response.data.data.rows;
        currentPage.value = response.data.data.page || 1;
        totalPages.value = response.data.data.total_pages || 1;
      }
    } catch (error) {
      isLoading.value = false;
      console.log(error.message);
    }
  };
  
  const changePage = (page) => {
    if (page > 0 && page <= totalPages.value) {
      getAllSubmission(page, sort.value);
    }
  };
  
  const changeSort = () => {
    getAllSubmission(currentPage.value, sort.value);
  };
  
  const router = useRouter();
  
  function adjustHeight() {
    const width = window.innerWidth;
    let height;
    if (width <= 540) {
      height = width * (1500 / 1440);
    } else {
      height = width * (545 / 1440);
    }
    const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
    dynamicHeightDiv.style.height = `${height}px`;
  }
  
  function setBackgroundImage() {
    const width = window.innerWidth;
    const dynamicHeightDiv = document.getElementById('dynamicHeightDiv');
    if (width <= 540) {
      dynamicHeightDiv.style.backgroundImage = "url('/images/chinaus/background-mobile.svg')";
    } else {
      dynamicHeightDiv.style.backgroundImage = "url('/images/chinaus/background.svg')";
    }
  }

  const checkAuthToken = async () => {
    try {
      const authToken = await cookiesService.getCookie('authToken');
      if (authToken) {
        currentAppState.value = AppState.isLogin;
        clearInterval(intervalId);
        await getAllSubmission(currentPage.value, sort.value);
      }
    } catch (error) {
      console.error(error);
    }
  };

  checkAuthToken();

  const intervalId = setInterval(checkAuthToken, 500);
  
  onMounted(async () => {
    adjustHeight();
    setBackgroundImage();
    setBackgroundPosition();
    scrollToFAQ();
    window.addEventListener('resize', adjustHeight);
  
    const welcomePageElement = document.querySelector('.welcome-page-4-dsa');
  
    if (welcomePageElement) {
      welcomePageElement.style.backgroundImage = `url("${process.env.VUE_APP_URL}/images/chinaus/faq-eclipse.svg")`;
    }

    const authToken = await cookiesService.getCookie("authToken");

    if(authToken) {
        await getAllSubmission(currentPage.value, sort.value);
    }
    
  });
  
  function setBackgroundPosition() {
    const welcomePageElement = document.querySelector('.welcome-page-4-dsa');

    if(!welcomePageElement) {
      return
    }

    const width = window.innerWidth;
  
    if (width <= 540) {
      welcomePageElement.style.backgroundPosition = `right top`;
    } else {
      welcomePageElement.style.backgroundPosition = `right top`;
    }
  }
  
  onBeforeUnmount(() => {
    window.removeEventListener('resize', adjustHeight);
  });
  
  function scrollToFAQ() {
    nextTick(() => {
      const faqButton = document.getElementById('faqButton');
      if (faqButton) {
        const scrollContainer = faqButton.closest('.scroll-container');
        const scrollPosition = faqButton.offsetLeft + faqButton.clientWidth / 2 - window.innerWidth / 2;
        scrollContainer.scrollLeft = scrollPosition + 26;
      }
    });
  }
  
  function openDetail(id) {
    router.push({ path: `/china-us/submission-detail/${id}` });
  }

  function promptSignIn(event) {
    event.preventDefault();
    window.location.href = `${process.env.VUE_APP_LOGIN_URL}/request-login?ref_path=${encodeURIComponent(window.location.pathname)}`;
  }

  function promptSignUp(event) {
    event.preventDefault();
    window.location.href = `${process.env.VUE_APP_LOGIN_URL}/request-login?tab=show-register&ref_path=${encodeURIComponent(window.location.pathname)}`;
  }
  </script>
  
  <style>
  .card-chinaus-accordion {
    font-family: 'League Spartan';
    display: flex;
    gap: 1em;
    background-color: rgba(242, 243, 251, 0.55);
    padding: 2em 2em;
    border-radius: 15px;
    width: 100%;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .button-email {
    background-color: #00925E;
    border: none;
    border-radius: 16px;
    padding: 10px 15px;
    color: white;
    font-size: 0.8em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    font-weight: 550;
  }

  .button-email img {
    width: 18px;
    height: auto;
  }

  .welcome-page-4-dsa {
    background-repeat: no-repeat;
  }

  .accordion-eqdsad {
    font-size: 1.2em;
    font-weight: 500;
  }

  .responsive-image-submission-list {
      height: 550px;
  }

  .white-backdrop-mini {
    color: white;
    font-size: 3em;
  }

  @media (max-width: 2311px) {
      .responsive-image-submission-list {
          height: 550px;
      }
  }

  @media (max-width: 1964px) {
      .responsive-image-submission-list {
          height: 380px;
      }
      
      .white-backdrop-mini {
        color: white;
        font-size: 1.5em;
      }
  }

  @media (max-width: 1307px) {
      .responsive-image-submission-list {
          height: 350px;
      }
  }

  @media (max-width: 1206px) {
      .responsive-image-submission-list {
          height: 300px;
      }
      
      .white-backdrop-mini {
        color: white;
        font-size: 1.4em;
      }
  }

  @media (max-width: 1133px) {
      .responsive-image-submission-list {
          height: 300px;
      }
  }

  @media (max-width: 1025px) {
      .responsive-image-submission-list {
          height: 250px;
      }
  }

  @media (max-width: 898px) {
      .responsive-image-submission-list {
          height: 250px;
      }
  }

  @media (max-width: 768px) {
      .responsive-image-submission-list {
          height: 160px;
      }
  }

  @media (max-width: 540px) {
      .responsive-image-submission-list {
          height: 250px;
      }
      
      .white-backdrop-mini {
        color: white;
        font-size: 2em;
      }
  }

  @media (max-width: 360px) {
      .responsive-image-submission-list {
          height: 250px;
      }
  }
  </style>
