<template>
  <TopSectionSpanning :topSectionHeight="topSectionHeight" :mode="`client`" />
  <div class="app-bar white-bg">
    <!-- Logo Section -->
    <div class="app-bar-section logo-section">
      <a href="/">
        <img height="40" src="/images/eddy.svg" alt="Eddy Logo" />
      </a>
    </div>

    <!-- Menu Section -->
    <div
      class="app-bar-section menu-section"
      :class="{ 'is-open': mobileMenuOpen }"
    >
      <template v-for="(menu, index) in menus" :key="index">
        <div
          v-if="menu.submenu && !menu.hide"
          :class="['menu-item menu-link', { active: isActiveMenu(menu) }]"
          @mouseenter="openMenu(index)"
          @mouseleave="closeMenu(index)"
          @click="toggleSubMenu(index)"
        >
          <a :href="menu.link" :target="menu.blank ? '_blank' : '_self'">{{
            menu.label
          }}</a>
          <div class="submenu" v-show="submenuOpen[index]">
            <template
              v-for="(submenu, subIndex) in menu.submenu"
              :key="subIndex"
            >
              <a
                v-if="!submenu.hide && !submenu.submenu"
                :href="submenu.link"
                :target="submenu.blank ? '_blank' : '_self'"
                class="menu-link submenu-link"
              >
                <img :src="submenu.icon" alt="" class="colour-image" />
                <span class="margin-label">{{ submenu.label }}</span>
              </a>
              <div
                v-else-if="!submenu.hide && submenu.submenu"
                class="submenu-item"
                @mouseleave="closeSubSubmenu(index, subIndex)"
              >
                <a
                  class="menu-link submenu-link"
                  @mouseenter="toggleSubSubmenu(index, subIndex)"
                >
                  <img :src="submenu.icon" alt="" class="colour-image" />
                  <span class="margin-label">{{ submenu.label }}</span>
                  <img
                    :src="
                      subSubmenuOpen[index] && subSubmenuOpen[index][subIndex]
                        ? '/images/admin/polygon-up.svg'
                        : '/images/admin/polygon-down.svg'
                    "
                    class="arrow-icon colour-image"
                    alt="arrow"
                  />
                </a>
                <!-- Sub-submenu -->
                <div
                  v-show="
                    subSubmenuOpen[index] && subSubmenuOpen[index][subIndex]
                  "
                  class="sub-submenu"
                >
                  <a
                    v-for="(subsubmenu, subSubIndex) in submenu.submenu"
                    :key="subSubIndex"
                    :href="subsubmenu.link"
                    :target="menu.blank ? '_blank' : '_self'"
                    class="menu-link sub-submenu-link"
                  >
                    <img :src="subsubmenu.icon" alt="" class="colour-image" />
                    <span class="margin-label">{{ subsubmenu.label }}</span>
                  </a>
                </div>
              </div>
            </template>
          </div>
        </div>

        <a
          v-else-if="!menu.submenu && !menu.hide"
          :class="['menu-link', { active: isActiveMenu(menu) }]"
          :href="menu.link"
          class="menu-link"
          :target="menu.blank ? '_blank' : '_self'"
          >{{ menu.label }}</a
        >
      </template>
    </div>

    <!-- User Section -->
    <div class="app-bar-section user-section">
      <a
        v-if="!user"
        style="margin-top: 0.3em"
        class="menu-link"
        @click="promptSignIn"
        >Sign In</a
      >
      <div v-else>
        <v-menu
          v-model="userMenuOpen"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
          :style="{
            position: 'fixed',
            top: isAdminAndYsa ? '13%' : '8%',
          }"
        >
          <template v-slot:activator="{ on }">
            <div
              @click="toggleUserMenu"
              style="
                font-family: 'League Spartan';
                display: flex;
                align-items: center;
                font-weight: 500;
                color: #003d6a;
                gap: 12px;
                cursor: pointer;
              "
              v-bind="on"
            >
              <!-- Cek apakah user ada sebelum mencoba menampilkan gambar -->
              <img
                v-if="user.image"
                :src="user.image"
                alt=""
                style="height: 30px; border-radius: 50%"
              />
              {{ user.firstname }} {{ user.lastname }}
              <img src="/images/chevron-down.svg" alt="" />
            </div>
          </template>
          <v-list>
            <v-list-item>
              <router-link to="/profile">
                <div
                  style="
                    font-family: 'League Spartan';
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    color: #003d6a;
                    gap: 12px;
                    cursor: pointer;
                    color: #003d6a;
                  "
                >
                  <img
                    src="/images/user-square.svg"
                    style="height: 20px"
                    alt=""
                  />
                  <div style="padding-top: 6px">Profile</div>
                </div>
              </router-link>
            </v-list-item>
            <v-list-item v-if="canAccessYsaDashboard">
              <router-link :to="{ name: 'YSATeacherStudentListPage' }">
                <div
                  style="
                    font-family: 'League Spartan';
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    color: #003d6a;
                    gap: 12px;
                    cursor: pointer;
                    color: #003d6a;
                  "
                >
                  <img
                    src="/images/sidebar-menu/ysa-dashboard-blue.svg"
                    style="height: 20px"
                    alt=""
                  />
                  <div style="padding-top: 6px">YSA Dashboard</div>
                </div>
              </router-link>
            </v-list-item>
            <v-list-item>
              <div
                style="
                  font-family: 'League Spartan';
                  display: flex;
                  align-items: center;
                  font-weight: 500;
                  color: #003d6a;
                  gap: 12px;
                  cursor: pointer;
                  color: #e00069;
                "
                @click="signOut"
              >
                <img src="/images/logout.svg" style="height: 20px" alt="" />
                <div style="padding-top: 6px">Sign Out</div>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <!-- Hamburger Menu Icon (only visible on mobile) -->
    <div class="hamburger-menu" @click="toggleMobileMenu" v-if="isMobile">
      <img src="/images/humburger-menu.svg" alt="Menu" />
    </div>
  </div>
</template>

<script setup>
import TopSectionSpanning from "@/components/common/TopSectionSpanning.vue";
import cookiesService from "@/services/cookiesService";
import StemStudioService from "@/services/stemStudioService";
import userService from "@/services/userService";
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

// eslint-disable-next-line
const checkEducatorsGuide = async () => {
  try {
    const response = await StemStudioService.checkEducatorsGuide();
    if (response.status === 200) {
      const { showEducatorsGuide } = response.data.data;

      // Find and update the `hide` property in the "Maker Cart Educator's Guide" menu.
      menus.value.forEach((menu) => {
        if (menu.submenu) {
          menu.submenu.forEach((submenu) => {
            if (submenu.label === "Makercart Educator's Guide") {
              submenu.hide = !showEducatorsGuide; // If `showEducatorsGuide` is true, then `hide` is false and vice versa
            }
          });
        }
      });
    }
  } catch (err) {
    console.log(err.message);
  }
};

const isAdminAndYsa = computed(() => {
  return user.value?.role === "Administrator" && user.value?.ysa;
});

const user = ref(null);
const userMenuOpen = ref(false);
const mobileMenuOpen = ref(false);
const isMobile = ref(window.innerWidth < 991);
const route = useRoute();
const router = useRouter();
const topSectionHeight = ref("0px");

const submenuOpen = ref({});
const subSubmenuOpen = ref({});

const menus = ref([
  {
    label: "Online Making",
    blank: true,
    link: "https://eddy4teachers.com/lesson-plans",
    hide: false,
  },
  {
    label: "Professional Development",
    hide: false,
    submenu: [
      {
        label: "Micro Learning",
        blank: true,
        link: "https://eddy4teachers.com/micro-learning",
        icon: "/images/sidebar-menu/micro-learning.svg",
        hide: false,
      },
      {
        label: "Makercart Educator's Guide",
        link: "/makercart-guide",
        routeName: "MakerCartGuidePage",
        icon: "/images/menu/document-code-2.svg",
        hide: true,
      },
    ],
  },
  { label: "EdTech Studio", link: "/", routeName: "HomePage", hide: false },
  {
    label: "Makercart Studio",
    link: "/makercart-studio",
    routeName: "MakerCartPage",
    hide: false,
  },
  {
    label: "Other Resources",
    hide: false,
    submenu: [
      {
        label: "Community",
        blank: true,
        link: "https://eddy4teachers.com/community",
        icon: "/images/menu/people.svg",
        hide: false,
      },
      {
        label: "Sustainability Education",
        icon: "/images/menu/ecology.svg",
        hide: false,
        submenu: [
          {
            label: "Resources",
            blank: true,
            link: "https://eddy4teachers.com/resources",
            icon: "/images/menu/folder-favorite.svg",
            hide: false,
          },
          {
            label: "Feedback & Assesment",
            blank: true,
            link: "https://eddy4teachers.com/feedback-and-assesment",
            icon: "/images/menu/document-text.svg",
            hide: false,
          },
        ],
      },
      {
        label: "China-US Young Maker Competition",
        link: "/china-us",
        routeName: "ChinaUSPage",
        icon: "/images/menu/medal-star.svg",
        hide: false,
      },
      {
        label: "Hands on Making",
        blank: true,
        link: "https://eddy4teachers.com/maker-resources",
        icon: "/images/menu/people.svg",
        hide: false,
      },
    ],
  },
]);

function isActiveMenu(menu) {
  // Check if the current route matches the main menu item
  if (menu.routeName === route.name) {
    return true;
  }

  // Check if the current route matches any child route
  if (menu.submenu) {
    for (const submenu of menu.submenu) {
      if (submenu.routeName === route.name) {
        return true;
      }
      // Check for sub-submenu routes
      if (submenu.submenu) {
        for (const subsubmenu of submenu.submenu) {
          if (subsubmenu.routeName === route.name) {
            return true;
          }
        }
      }
    }
  }

  return false;
}

// eslint-disable-next-line
function getMenuClass(menu) {
  return menu.routeName && route.name === menu.routeName ? "active" : "";
}

// const handleResize = () => {
//   isMobile.value = window.innerWidth < 991;
// };

const handleResize = () => {
  isMobile.value = window.innerWidth < 991;
  if (isMobile.value) {
    openAllSubSubmenus();
  }
};

window.addEventListener("resize", handleResize);

onMounted(() => {
  checkAuthToken();
  window.addEventListener("resize", handleResize);

  // Initial check to open submenu if we're already on a matching route
  menus.value.forEach((menu, index) => {
    if (menu.submenu) {
      menu.submenu.forEach((submenu, subIndex) => {
        if (submenu.routeName === route.name) {
          submenuOpen.value[index] = true;
        }
        // Check sub-submenu
        if (submenu.submenu) {
          submenu.submenu.forEach((subsubmenu) => {
            if (subsubmenu.routeName === route.name) {
              submenuOpen.value[index] = true;
              subSubmenuOpen.value[index] = subSubmenuOpen.value[index] || {};
              subSubmenuOpen.value[index][subIndex] = true;
            }
          });
        }
      });
    }
  });

  // Open all sub-submenus if in mobile mode
  if (isMobile.value) {
    openAllSubSubmenus();
  }
});

function openAllSubSubmenus() {
  menus.value.forEach((menu, index) => {
    if (menu.submenu) {
      menu.submenu.forEach((submenu, subIndex) => {
        if (submenu.submenu) {
          if (!subSubmenuOpen.value[index]) {
            subSubmenuOpen.value[index] = {};
          }
          subSubmenuOpen.value[index][subIndex] = true;
        }
      });
    }
  });
}

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

const checkAuthToken = async () => {
  try {
    const authToken = await cookiesService.getCookie("authToken");
    console.log("Auth token", authToken);
    if (authToken) {
      const response = await userService.getProfile(authToken.token);
      const userProfile = response.data?.data;

      if (userProfile) {
        user.value = userProfile;
        await cookiesService.setCookie("authToken", {
          ...authToken,
          ...userProfile,
        });
      }

      if (user.value.user_group_id === 1) {
        topSectionHeight.value = "40px";
      }

      checkEducatorsGuide();
      // clearInterval(intervalId);
    }
  } catch (error) {
    console.error(error);
  }
};

// checkAuthToken();

// const intervalId = setInterval(checkAuthToken, 500);

function toggleUserMenu() {
  userMenuOpen.value = !userMenuOpen.value;
}

function toggleMobileMenu() {
  mobileMenuOpen.value = !mobileMenuOpen.value;
}

function toggleSubMenu(index) {
  submenuOpen.value[index] = !submenuOpen.value[index];
}

function openMenu(index) {
  if (!isMobile.value) {
    submenuOpen.value[index] = true;
  }
}

function closeMenu(index) {
  if (!isMobile.value) {
    submenuOpen.value[index] = false;
  }
}

function closeSubSubmenu(index, subIndex) {
  if (
    !isMobile.value &&
    subSubmenuOpen.value[index] &&
    subSubmenuOpen.value[index][subIndex]
  ) {
    subSubmenuOpen.value[index][subIndex] = false;
  }
}

function toggleSubSubmenu(index, subIndex) {
  if (!subSubmenuOpen.value[index]) {
    subSubmenuOpen.value[index] = {};
  }

  // Toggle hanya dalam mode desktop
  if (!isMobile.value) {
    subSubmenuOpen.value[index][subIndex] =
      !subSubmenuOpen.value[index][subIndex];
  } else {
    // Pastikan subsubmenu selalu terbuka dalam mode mobile
    subSubmenuOpen.value[index][subIndex] = true;
  }

  // Menjaga agar submenu tetap terbuka
  submenuOpen.value[index] = true;

  // Mendapatkan elemen menu-item yang sesuai dengan index
  const menuItems = document.querySelectorAll(".menu-item");
  if (menuItems[index]) {
    menuItems[index].classList.add("submenu-open");
  }
}

const canAccessYsaDashboard = computed(() => {
  return (
    (user.value?.role === "Teacher" || user.value?.role === "Administrator") &&
    user.value?.ysa &&
    user.value?.school?.ysa
  );
});

function signOut() {
  cookiesService.removeCookie("authToken");
  router.push("/signin");
}

function promptSignIn(event) {
  event.preventDefault();
  router.push("/signin");
}
</script>

<style>
.app-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  box-shadow: none;
  border-bottom: 1px solid #d8dae5;
  background-color: white;
}

.app-bar-section {
  display: flex;
  align-items: center;
}

.logo-section {
  flex-grow: 1;
}

.hamburger-menu {
  display: none;
  /* Hidden on desktop */
  cursor: pointer;
}

.menu-section {
  flex-grow: 8;
  justify-content: center;
  gap: 0.8em;
  position: relative;
}

.user-section {
  flex-grow: 2;
  justify-content: end;
}

.menu-link {
  text-decoration: none;
  color: #003d6a;
  font-weight: 500;
  font-family: "League Spartan";
  font-size: 0.95em;
  cursor: pointer;
  padding: 7px 10px;
  border-bottom: 3px solid transparent;
  transition: color 0.3s, border-color 0.3s;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
}

.menu-link.active {
  color: #e00069;
  border-bottom-color: #e00069;
}

.submenu {
  display: none;
  position: absolute;
  background-color: white;
  padding: 12px 16px;
  border-radius: 25px;
  z-index: 1;
}

.menu-item.submenu-open .submenu {
  display: block;
}

.submenu-link:hover {
  background-color: #e00069; /* Warna pink untuk submenu */
  color: white;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

.sub-submenu-link:hover {
  background-color: #e00069; /* Warna pink untuk subsubmenu */
  color: white;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

.sub-submenu {
  display: block;
  padding-left: 20px;
}

.submenu-link {
  display: flex;
  /* align-items: center;
  justify-content: space-between; */
  gap: 5px;
}

.sub-submenu-link {
  display: flex;
  /* align-items: center;
  justify-content: space-between; */
  gap: 10px;
}

.arrow-icon {
  width: 12px;
  height: 12px;
  margin-left: 8px;
  align-self: center;
}

/* Mobile Styles */
@media (max-width: 991px) {
  .hamburger-menu {
    display: flex;
  }

  .menu-section {
    display: none;
  }

  .menu-section.is-open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 56px;
    left: 0;
    width: 100%;
    padding: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
  }

  .menu-link {
    padding: 10px 15px;
  }

  .submenu,
  .sub-submenu {
    position: static;
    display: block; /* Always show sub-submenu in mobile mode */
  }

  .v-menu {
    left: 60% !important;
    top: 8%;
  }

  .menu-item:hover .submenu {
    display: "";
    position: absolute;
  }
}

.v-menu {
  left: 90%;
  top: 8%;
}

.menu-item:hover .submenu {
  display: block;
}

.menu-item:hover .submenu,
.submenu-item:hover .sub-submenu {
  display: block;
}

.menu-item {
  /* display: flex;
  align-items: center;
  position: relative; */
}

.margin-label {
  margin-top: 0.23em;
}

.greyed-out {
  color: grey;
  pointer-events: none;
  cursor: default;
  position: relative;
}

.small-icon {
  width: 65px;
  height: 10px;
  position: absolute;
  top: 0;
  margin-right: 5px;
  margin-top: 5px;
}

.submenu-link img.colour-image,
.sub-submenu-link img.colour-image {
  filter: invert(16%) sepia(22%) saturate(6604%) hue-rotate(187deg)
    brightness(99%) contrast(101%);
}

.submenu-link:hover img.colour-image,
.sub-submenu-link:hover img.colour-image {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(345deg)
    brightness(104%) contrast(102%);
}
</style>
