<template>
  <div style="display: flex; height: 100vh">
    <img
      src="/images/background-cap.svg"
      alt=""
      style="position: absolute; bottom: 0; left: 0; z-index: -999"
    />
    <div
      style="
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <router-link
        to="/"
        style="
          display: flex;
          gap: 19px;
          margin-top: 40px;
          margin-left: 40px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 50;
        "
      >
        <img src="/images/eddy.svg" alt="" />
      </router-link>

      <!-- Loading state -->
      <template v-if="isLoading">
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
          "
        >
          <div class="verification-loader">
            <div class="spinner"></div>
            <h2 style="color: #003d6a; margin-top: 20px">
              Verifying your email...
            </h2>
            <p
              style="
                color: #003d6a;
                text-align: center;
                width: 80%;
                margin: 10px auto;
              "
            >
              Please wait while we confirm your email address
            </p>
          </div>
        </div>
      </template>

      <template v-else-if="isVerified">
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
          "
        >
          <div>
            <h1
              style="
                font-size: 32px;
                font-weight: 600;
                margin-bottom: 1rem;
                color: #003d6a;
                text-align: center;
              "
            >
              Email Verified!
            </h1>
            <p
              style="
                text-align: center;
                color: #003d6a;
                width: 60%;
                margin: 0 auto;
              "
            >
              Your email has been verified, please login to continue. You will
              be re-directed to the Login page in
              <span style="font-weight: bold">{{ countdown }}</span> seconds
            </p>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
          "
        >
          <h1
            style="
              font-size: 32;
              font-weight: 600;
              margin-bottom: 1rem;
              color: #003d6a;
              text-align: center;
            "
          >
            Failed to verify email
          </h1>
          <p style="text-align: center; color: #003d6a">
            {{ errorMessage }}
          </p>
          <BaseButton
            variant="primary"
            style="
              font-size: 20px;
              font-weight: 600;
              border-radius: 12px;
              margin-top: 32px;
            "
            @click="router.push('/signin')"
          >
            Back to Login
          </BaseButton>
        </div>
      </template>
    </div>

    <!-- right side -->
    <div
      style="
        width: 50%;
        background-color: #003d6a;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <div
        style="
          display: flex;
          justify-content: flex-end;
          width: 100%;
          margin-bottom: 1rem;
          margin-top: 45px;
          padding-right: 40px;
          position: absolute;
          right: 0;
          top: 0;
        "
      >
        <button
          :style="{
            padding: '8px',
            background: selectedLanguage === 'EN' ? '#E00069' : '#F2F3FB',
            color: selectedLanguage === 'EN' ? 'white' : '#E00069',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            width: '60px',
            fontSize: '14px',
          }"
          @click="setLanguage('EN')"
        >
          EN
        </button>
        <button
          :style="{
            padding: '8px',
            background: selectedLanguage === 'ES' ? '#E00069' : '#F2F3FB',
            color: selectedLanguage === 'ES' ? 'white' : '#E00069',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            width: '60px',
            fontSize: '14px',
          }"
          @click="setLanguage('ES')"
        >
          ES
        </button>
      </div>

      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img src="/images/login-illustration.svg" style="width: 50%" alt="" />
        <p
          style="
            color: white;
            font-size: 32px;
            font-weight: 600;
            margin-top: 32px;
          "
        >
          Sign up for Eddy!
        </p>
        <BaseButton
          variant="white"
          style="
            font-size: 20px;
            font-weight: 600;
            width: 480px;
            height: 48px;
            border-radius: 12px;
            margin-top: 32px;
          "
          @click="router.push('/signup')"
        >
          New to Eddy? Sign up here!
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import BaseButton from "@/components/common/BaseButton.vue";
import authService from "@/services/authService";

const selectedLanguage = ref("EN");
const isVerified = ref(false);
const errorMessage = ref("");
const isLoading = ref(true);

const countdown = ref(5);

const router = useRouter();

const route = useRoute();
const token = computed(() => route.query.token);

const setLanguage = (lang) => {
  selectedLanguage.value = lang;
};

const startCountdown = () => {
  countdown.value = 5;
  const countdownInterval = setInterval(() => {
    countdown.value--;
  }, 1000);

  setTimeout(() => {
    clearInterval(countdownInterval);
    router.push("/signin");
  }, 5000);
};

const verifyEmail = async () => {
  isLoading.value = true;

  try {
    // Add a small delay to show the loading animation
    await new Promise((resolve) => setTimeout(resolve, 1500));

    await authService.verifyEmail(token.value);
    isLoading.value = false;
    isVerified.value = true;
    startCountdown();
  } catch (error) {
    isLoading.value = false;
    if (error?.response?.data?.error) {
      errorMessage.value = error.response.data.message;
    } else {
      errorMessage.value = "Invalid or expired token";
    }
  }
};

onMounted(() => {
  if (!token.value) {
    router.push("/signin");
  } else {
    verifyEmail();
  }
});
</script>

<style scoped>
.verification-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.spinner {
  width: 70px;
  height: 70px;
  border: 5px solid rgba(0, 61, 106, 0.2);
  border-radius: 50%;
  border-top-color: #003d6a;
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Add a subtle pulse animation to the text */
h2 {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}
</style>
