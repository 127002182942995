<template>
  <div
    class="validation-container"
    :class="{
      'validation-visible': password.length > 0,
      'validation-hidden': password.length === 0,
    }"
  >
    <div v-if="password.length > 0">
      <ul class="validation-list">
        <li
          v-for="(message, key) in validationMessages"
          :key="key"
          :class="validations[key] ? 'validation-success' : 'validation-error'"
        >
          <span class="validation-icon">{{
            validations[key] ? "✓" : "✗"
          }}</span>
          {{ message }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
defineProps({
  password: {
    type: String,
    required: true,
  },
  validations: {
    type: Object,
    required: true,
    default: () => ({
      lowercase: false,
      uppercase: false,
      specialChar: false,
      length: false,
    }),
  },
});

const validationMessages = {
  lowercase: "Must contain at least one lowercase letter [a-z]",
  uppercase: "Must contain at least one uppercase letter [A-Z]",
  specialChar: "Must contain at least one special character [e.g. $, %, &]",
  length: "Must be at least 10 characters in length",
};
</script>

<style scoped>
.validation-container {
  overflow: hidden;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  width: 100%;
  transition: all 0.5s ease-in-out;
  max-height: 0;
  opacity: 0;
}

.validation-visible {
  opacity: 1;
  max-height: 200px;
}

.validation-hidden {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.validation-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.validation-list li {
  margin-bottom: 0.25rem;
}

.validation-success {
  color: #10b981; /* text-green-500 */
}

.validation-error {
  color: #ef4444; /* text-red-500 */
}

.validation-icon {
  display: inline-block;
  width: 1.25rem;
}
</style>
