<template>
  <div class="masterclass-form">
    <button class="button-secondary" @click="goBackToMasterclass" style="margin-bottom: 20px">
      <v-icon size="16" class="mr-1">mdi-arrow-left</v-icon>
      Back to Masterclass List
    </button>
    <div class="masterclass-form-header">
      <h2 class="color-primary">
        {{ isEdit ? "Edit and Publish Masterclass" : "Create New Masterclass" }}
      </h2>
    </div>
    <div class="masterclass-form-content">
      <MasterclassInput @input-change="handleInputChange" :title="masterclassData.title" :blurb="masterclassData.blurb"
        :disabled="isPublished" />
      <div class="masterclass-form-content-category">
        <p class="color-primary font-weight-bold">Category*</p>
        <div class="masterclass-form-content-category-select">
          <div v-for="(category, index) in categories" :key="index"
            class="masterclass-form-content-category-select-item" :class="{
              'masterclass-form-content-category-select-item-active':
                selectedCategory[category.value],
            }" @click="selectCategory(category.value)">
            <p>{{ category.name }}</p>
          </div>
        </div>
      </div>

      <div class="masterclass-form-content-button">
        <div>
          <div class="upload-section">
            <div class="file-preview" v-if="scormFile || masterclassData.filename">
              <span class="text-center">{{
                scormFile?.name || masterclassData.filename
              }}</span>
              <span v-if="scormFile">({{ formatBytes(scormFile.size) }})</span>
              <v-icon color="primary" size="28">mdi-file-document-outline</v-icon>
            </div>
            <div v-else>
              <p :style="{ fontSize: '14px' }">No SCORM File Uploaded</p>
            </div>
          </div>
          <button class="button-upload" @click="openScormDialog" :disabled="isPublished">
            <img src="/images/document.svg" alt="Upload" />
            {{ scormFile ? "Re-upload" : "Upload" }} SCORM File
          </button>
          <p class="error-message">{{ errors.scormFile }}</p>
        </div>
        <div>
          <div class="upload-section">
            <div class="thumbnail-preview" v-if="thumbnailPreview">
              <img :src="thumbnailPreview" alt="Thumbnail Preview" />
            </div>
            <div v-else>
              <p :style="{ fontSize: '14px' }">No Thumbnail Uploaded</p>
            </div>
          </div>
          <button class="button-upload" @click="openThumbnailDialog" :disabled="isPublished">
            <img src="/images/cloud-upload.svg" alt="Upload" />
            {{ thumbnailPreview ? "Re-upload" : "Upload" }} Thumbnail
          </button>
          <p class="error-message">{{ errors.thumbnail }}</p>
        </div>
      </div>
      <p class="error-message">{{ errorMessage }}</p>
    </div>
    <div class="masterclass-form-content-button-bottom">
      <button class="button-primary" :class="{
        'button-disabled':
          !hasChanges || isLoading || (isEdit && isPublished),
      }" :disabled="!hasChanges || isLoading || (isEdit && isPublished)" @click="saveDraft"
        v-if="!isEdit || !isPublished">
        <v-progress-circular v-if="isDraftLoading" indeterminate size="16" width="2" color="white"
          class="mr-2"></v-progress-circular>
        Save Draft
      </button>
      <button class="button-primary" :class="{
        'button-disabled':
          !isReadyToPublish || isLoading || (isEdit && isPublished),
      }" :disabled="!isReadyToPublish || isLoading || (isEdit && isPublished)" @click="publishMasterclass">
        <v-progress-circular v-if="isLoading" indeterminate size="16" width="2" color="white"
          class="mr-2"></v-progress-circular>
        {{ isEdit && isPublished ? "Published" : "Publish Masterclass" }}
      </button>
      <!-- <button
        v-if="isEdit && isPublished"
        class="button-secondary"
        @click="goBackToMasterclass"
      >
        <v-icon size="16" class="mr-1">mdi-arrow-left</v-icon>
        Back to Masterclass
      </button> -->
    </div>

    <!-- SCORM Upload Dialog -->
    <v-dialog v-model="scormDialog" max-width="500px">
      <v-card>
        <v-card-title class="dialog-title">
          Upload SCORM File
          <v-btn icon @click="scormDialog = false" class="close-btn" variant="text" color="grey" size="small">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider style="margin: 0 20px"></v-divider>
        <v-card-text>
          <div class="upload-area" @click="triggerScormFileInput" @dragover.prevent @drop="onScormFileDrop">
            <v-icon size="36" color="grey lighten-1">mdi-cloud-upload-outline</v-icon>
            <p>Click to Upload SCORM File</p>
            <input type="file" ref="scormFileInput" accept=".zip" style="display: none" @change="onScormFileSelected" />
          </div>

          <!-- Show selected file name -->
          <div v-if="tempScormFile" class="selected-file-info">
            <v-icon color="success" size="20">mdi-check-circle</v-icon>
            <span>{{ tempScormFile.name }}</span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="scormDialog = false">Cancel</v-btn>
          <v-btn color="primary" @click="saveScormFile" :disabled="!tempScormFile">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Thumbnail Upload Dialog -->
    <v-dialog v-model="thumbnailDialog" max-width="500px">
      <v-card>
        <v-card-title class="dialog-title">
          Upload Thumbnail

          <v-btn icon @click="thumbnailDialog = false" size="small" class="close-btn" variant="text" color="grey">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider style="margin: 0 20px"></v-divider>
        <v-card-text>
          <div class="upload-area" @click="triggerThumbnailInput" @dragover.prevent @drop="onThumbnailDrop">
            <img src="/images/upload-placeholder.svg" alt="Upload" />
            <p>Upload Thumbnail</p>
            <p class="upload-hint">(JPG or PNG only)</p>
            <p class="upload-hint">150 to 250 px</p>
            <input type="file" ref="thumbnailInput" accept=".jpg,.jpeg,.png" style="display: none"
              @change="onThumbnailSelected" />
          </div>

          <!-- Show selected thumbnail preview -->
          <div v-if="tempThumbnailFile" class="selected-file-info">
            <v-icon color="success" size="20">mdi-check-circle</v-icon>
            <span style="
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              ">{{ tempThumbnailFile.name }}</span>
            <div v-if="tempThumbnailPreview" class="dialog-thumbnail-preview">
              <img :src="tempThumbnailPreview" alt="Selected Thumbnail" />
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="thumbnailDialog = false">Cancel</v-btn>
          <v-btn color="primary" @click="saveThumbnail" :disabled="!tempThumbnailFile">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Success Snackbar -->
    <v-snackbar v-model="snackbar" :timeout="3000" color="success">
      {{ snackbarMessage }}
      <template v-slot:actions>
        <v-btn variant="text" @click="snackbar = false" size="small">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script setup>
import MasterclassInput from "@/components/admin/ysa/MasterclassInput.vue";
import ysaAdminService from "@/services/ysaAdminService";
import { formatBytes } from "@/utils";
import { computed, onMounted, reactive, ref, defineProps } from "vue";
import { useRoute, useRouter } from "vue-router";

const props = defineProps({
  isEdit: {
    type: Boolean,
    default: false,
  },
  masterclassId: {
    type: String,
    default: null,
  },
});

const route = useRoute();
const router = useRouter();
const id = props.masterclassId || route.params.id;

// Loading and published state
const isLoading = ref(false);
const isDraftLoading = ref(false);
const isPublished = ref(false);
const errorMessage = ref("");

const errors = ref({
  scormFile: null,
  thumbnail: null,
});

// Current masterclass data
const masterclassData = reactive({
  title: "",
  blurb: "",
  filename: "",
});

// Track if data has been saved (to determine if there are new changes)
const savedState = ref(
  JSON.stringify({
    ...masterclassData,
    categories: { is_innovator: false, is_changemaker: false },
    scormFile: null,
    thumbnailPreview: null,
  })
);

// Category selection
const categories = ref([
  {
    name: "Sustainability Youth Changemaker",
    value: "is_innovator",
  },
  {
    name: "Sustainability Youth Innovator",
    value: "is_changemaker",
  },
]);

const selectedCategory = ref({
  is_innovator: false,
  is_changemaker: false,
});

const selectCategory = (value) => {
  if (isPublished.value) return;
  selectedCategory.value[value] = !selectedCategory.value[value];
};

// SCORM file upload
const scormDialog = ref(false);
const scormFileInput = ref(null);
const tempScormFile = ref(null);
const scormFile = ref(null);

const openScormDialog = () => {
  scormDialog.value = true;
  tempScormFile.value = null;
};

const triggerScormFileInput = () => {
  scormFileInput.value.click();
};

const onScormFileSelected = (event) => {
  const file = event.target.files[0];
  if (file && file.name.endsWith(".zip")) {
    tempScormFile.value = file;
  } else {
    alert("Please select a valid SCORM file (.zip)");
  }
};

const onScormFileDrop = (event) => {
  event.preventDefault();
  const file = event.dataTransfer.files[0];
  if (file && file.name.endsWith(".zip")) {
    tempScormFile.value = file;
  } else {
    alert("Please select a valid SCORM file (.zip)");
  }
};

const saveScormFile = () => {
  if (tempScormFile.value) {
    scormFile.value = tempScormFile.value;
    scormDialog.value = false;
  }
};

// Thumbnail upload
const thumbnailDialog = ref(false);
const thumbnailInput = ref(null);
const tempThumbnailFile = ref(null);
const tempThumbnailPreview = ref(null);
const thumbnailPreview = ref(null);

const openThumbnailDialog = () => {
  thumbnailDialog.value = true;
  tempThumbnailFile.value = null;
  tempThumbnailPreview.value = null;
};

const triggerThumbnailInput = () => {
  thumbnailInput.value.click();
};

const onThumbnailSelected = (event) => {
  const file = event.target.files[0];
  if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
    tempThumbnailFile.value = file;
    const reader = new FileReader();
    reader.onload = (e) => {
      tempThumbnailPreview.value = e.target.result;
    };
    reader.readAsDataURL(file);
  } else {
    alert("Please select a valid image file (JPG or PNG)");
  }
};

const onThumbnailDrop = (event) => {
  event.preventDefault();
  const file = event.dataTransfer.files[0];
  if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
    tempThumbnailFile.value = file;
    const reader = new FileReader();
    reader.onload = (e) => {
      tempThumbnailPreview.value = e.target.result;
    };
    reader.readAsDataURL(file);
  } else {
    alert("Please select a valid image file (JPG or PNG)");
  }
};

const saveThumbnail = () => {
  if (tempThumbnailFile.value) {
    thumbnailPreview.value = tempThumbnailPreview.value;
    thumbnailDialog.value = false;
  }
};

// Handle input changes from MasterclassInput component
const handleInputChange = (data) => {
  masterclassData.title = data.title;
  masterclassData.blurb = data.blurb;
};

// Computed property to check if there are any changes
const hasChanges = computed(() => {
  const currentState = JSON.stringify({
    ...masterclassData,
    categories: selectedCategory.value,
    scormFile: scormFile.value ? scormFile.value.name : null,
    thumbnailPreview: thumbnailPreview.value,
  });

  return currentState !== savedState.value;
});

// Computed property to check if all required fields are filled for publishing
const isReadyToPublish = computed(() => {
  const hasCategory =
    selectedCategory.value.is_innovator ||
    selectedCategory.value.is_changemaker;

  // For edit mode, we don't require the SCORM file if it's already uploaded
  const hasRequiredFiles = props.isEdit
    ? thumbnailPreview.value
    : thumbnailPreview.value && scormFile.value;

  return (
    masterclassData.title &&
    masterclassData.blurb &&
    hasCategory &&
    hasRequiredFiles
  );
});

// Snackbar for success messages
const snackbar = ref(false);
const snackbarMessage = ref("");

const validateField = () => {
  if (!scormFile.value && !masterclassData.filename) {
    errors.value.scormFile = "SCORM file is required";
  }
  if (!thumbnailPreview.value) {
    errors.value.thumbnail = "Thumbnail is required";
  }

  return Object.values(errors.value).every((error) => error === null);
};

// Save draft functionality
const saveDraft = async () => {
  errors.value = {
    scormFile: null,
    thumbnail: null,
  };

  if (!validateField()) return;

  isDraftLoading.value = true;

  try {
    // Update saved state
    savedState.value = JSON.stringify({
      ...masterclassData,
      categories: selectedCategory.value,
      scormFile: scormFile.value ? scormFile.value.name : null,
      thumbnailPreview: thumbnailPreview.value,
    });

    await postMasterclass("draft");

    // Show success message
    snackbarMessage.value = "Draft saved successfully!";
    snackbar.value = true;
  } catch (error) {
    console.error(error);
    if (error.response?.data?.message) {
      errorMessage.value = error.response.data.message;
    } else {
      errorMessage.value = "Error saving draft. Please try again.";
    }
  } finally {
    isDraftLoading.value = false;
  }
};

const publishMasterclass = async () => {
  isLoading.value = true;

  try {
    await postMasterclass("published");
  } finally {
    isLoading.value = false;
  }
};

const postMasterclass = async (status) => {
  try {
    errorMessage.value = "";

    const formData = new FormData();
    formData.append("title", masterclassData.title.slice(0, 50));
    formData.append("blurb", masterclassData.blurb.slice(0, 500));
    formData.append("is_innovator", selectedCategory.value.is_innovator);
    formData.append("is_changemaker", selectedCategory.value.is_changemaker);
    formData.append("status", status);

    // Handle thumbnail upload
    if (tempThumbnailFile.value) {
      formData.append("thumbnail", tempThumbnailFile.value);
    }

    // Handle SCORM file upload
    if (scormFile.value) {
      const fileWithCorrectType = new File(
        [scormFile.value],
        scormFile.value.name,
        { type: "application/zip" }
      );
      formData.append("file", fileWithCorrectType);
    }

    if (props.isEdit) {
      await ysaAdminService.updateMasterclass(id, formData);

      // Update state to reflect published status if publishing
      if (status === "published") {
        isPublished.value = true;
      }

      // Show success message
      snackbarMessage.value =
        status === "published"
          ? "Masterclass published successfully!"
          : "Draft saved successfully!";
      snackbar.value = true;

      // Update saved state to prevent further changes
      savedState.value = JSON.stringify({
        ...masterclassData,
        categories: selectedCategory.value,
        scormFile: scormFile.value ? scormFile.value.name : "existing-file",
        thumbnailPreview: thumbnailPreview.value,
      });
    } else {
      await ysaAdminService.createMasterclass(formData);

      // Show success message
      snackbarMessage.value =
        status === "published"
          ? "Masterclass published successfully!"
          : "Draft saved successfully!";
      snackbar.value = true;

      // Navigate back to masterclass list after a short delay ONLY if publishing
      if (status === "published") {
        setTimeout(() => {
          goBackToMasterclass();
        }, 1500);
      } else {
        // For draft, update the saved state to prevent showing changes
        savedState.value = JSON.stringify({
          ...masterclassData,
          categories: selectedCategory.value,
          scormFile: scormFile.value ? scormFile.value.name : null,
          thumbnailPreview: thumbnailPreview.value,
        });
      }
    }
  } catch (error) {
    console.error(error);
    if (error.response?.data?.message) {
      errorMessage.value = error.response.data.message;
    } else {
      errorMessage.value =
        status === "published"
          ? "Error publishing masterclass. Please try again."
          : "Error saving draft. Please try again.";
    }
  }
};

// Navigate back to masterclass list
const goBackToMasterclass = () => {
  router.push("/admin/ysa/masterclass");
};

// Fetch masterclass data if in edit mode
const fetchMasterclass = async () => {
  if (!props.isEdit || !id) return;

  try {
    const response = await ysaAdminService.getMasterclassById(id);
    const data = response.data?.data;

    if (data) {
      masterclassData.title = data.title;
      masterclassData.blurb = data.blurb;
      masterclassData.filename = data?.filename;
      selectedCategory.value.is_innovator = data.is_innovator;
      selectedCategory.value.is_changemaker = data.is_changemaker;
      thumbnailPreview.value = data.thumbnail;

      if (data.scorm) {
        // Create a placeholder for existing SCORM file
        scormFile.value = { name: "Existing SCORM file" };
      }

      // Check if masterclass is already published
      isPublished.value = data.status === "published";

      // Update saved state to reflect the loaded data
      savedState.value = JSON.stringify({
        ...masterclassData,
        categories: { ...selectedCategory.value },
        scormFile: scormFile.value ? scormFile.value.name : null,
        thumbnailPreview: thumbnailPreview.value,
      });
    }
  } catch (error) {
    console.error(error);
    errorMessage.value = "Error loading masterclass data.";
  }
};

onMounted(() => {
  fetchMasterclass();
});
</script>

<style scoped>
.masterclass-form {
  padding: 40px;
}

.masterclass-form-content {
  margin-top: 20px;
}

.masterclass-form-content-button {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.masterclass-form-content-category {
  margin-top: 20px;
}

.masterclass-form-content-category-select {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.masterclass-form-content-category-select-item {
  border: 2px solid #d8dae5;
  border-radius: 16px;
  padding: 10px;
  cursor: pointer;
  color: #555555;
  transition: background-color 0.2s ease, border-color 0.2s ease,
    color 0.2s ease;
}

.masterclass-form-content-category-select-item:hover {
  background-color: #f0f0f0;
}

.masterclass-form-content-category-select-item-active {
  background-color: #eaecff;
  border-color: #4c4ddc;
  color: #555555;
  font-weight: bold;
}

.masterclass-form-content-button-bottom {
  margin-top: 40px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.button-upload {
  background-color: #00925e;
  color: white;
  border: none;
  border-radius: 16px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  cursor: pointer;
  justify-content: center;
}

.button-upload:hover {
  background-color: rgba(0, 146, 94, 0.8);
}

.button-upload:active {
  background-color: rgba(0, 146, 94, 0.6);
}

.button-primary {
  background-color: #00925e;
  color: white;
  border: none;
  border-radius: 16px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.button-primary:hover {
  background-color: rgba(0, 146, 94, 0.8);
  border: none;
  outline: none;
}

.button-disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.button-disabled:hover {
  background-color: #cccccc;
}

.button-secondary {
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 16px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.button-secondary:hover {
  background-color: #e9e9e9;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #00925e;
  font-weight: bold;
}

.close-btn {
  margin-right: -12px;
}

.upload-area {
  border: 2px dashed #d8dae5;
  border-radius: 16px;
  padding: 40px;
  text-align: center;
  cursor: pointer;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.upload-area:hover {
  background-color: #f9f9f9;
}

.upload-hint {
  font-size: 12px;
  color: #888;
  margin: 0;
}

.masterclass-form-content-uploads {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.upload-section {
  margin-bottom: 10px;
  width: 250px;
  height: 150px;
  background-color: #f5f5f5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 14px;
}

.thumbnail-preview {
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
  margin-top: 10px;
  overflow: hidden;
}

.thumbnail-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.remove-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: white !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.selected-file-info {
  margin-top: 15px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.dialog-thumbnail-preview {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.dialog-thumbnail-preview img {
  max-width: 200px;
  max-height: 150px;
  border-radius: 4px;
  border: 1px solid #ddd;
  object-fit: contain;
}

.error-message {
  color: red;
}
</style>
