<template>
  <div style="display: flex; height: 100vh">
    <img
      src="/images/background-cap.svg"
      alt=""
      style="position: absolute; bottom: 0; left: 0; z-index: -999"
    />
    <div
      style="
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <img
        src="/images/white-bird.svg"
        style="position: absolute; z-index: -1; bottom: 0; left: 0; width: 40%"
        alt=""
      />
      <router-link
        to="/"
        style="
          display: flex;
          gap: 19px;
          margin-top: 40px;
          margin-left: 40px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 50;
        "
      >
        <img src="/images/eddy.svg" alt="" />
      </router-link>

      <template v-if="isEmailSent">
        <EmailVerificationMessage
          title="Please check your inbox"
          description="An email has been sent with more details on how to reset your password."
          :initialCountdown="60"
          @resend="send"
        />
      </template>
      <template v-else>
        <div style="display: flex; justify-content: center; width: 100%">
          <div style="width: 430px">
            <h1
              style="
                font-size: 32;
                font-weight: 600;
                margin-bottom: 1rem;
                color: #003d6a;
              "
            >
              Forgot Password
            </h1>
          </div>
        </div>
        <div
          style="
            display: flex;
            gap: 20px;
            width: 100%;
            flex-direction: column;
            align-items: center;
            margin-top: 10px;
          "
        >
          <BaseInput
            type="text"
            label="Email*"
            placeholder="Input Email"
            v-model="email"
            style="width: 430px"
            :error="isEmailError"
            :errorMessage="emailErrorMessage || errorMessage"
            :showInputError="false"
          />
        </div>

        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            margin-top: 20px;
          "
        >
          <div style="display: flex; gap: 10px; flex-direction: column">
            <BaseButton
              :loading="loading"
              :disabled="loading"
              @click="send"
              variant="primary"
              style="width: 430px"
            >
              Send
            </BaseButton>
            <router-link
              :to="{ name: 'LoginPage' }"
              class="hover-underline color-primary"
            >
              <p>Back to Login</p>
            </router-link>
          </div>
        </div>
      </template>
    </div>

    <!-- right side -->
    <div
      style="
        width: 50%;
        background-color: #003d6a;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <div
        style="
          display: flex;
          justify-content: flex-end;
          width: 100%;
          margin-bottom: 1rem;
          margin-top: 45px;
          padding-right: 40px;
          position: absolute;
          right: 0;
          top: 0;
        "
      >
        <button
          :style="{
            padding: '8px',
            background: selectedLanguage === 'EN' ? '#E00069' : '#F2F3FB',
            color: selectedLanguage === 'EN' ? 'white' : '#E00069',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            width: '60px',
            fontSize: '14px',
          }"
          @click="setLanguage('EN')"
        >
          EN
        </button>
        <button
          :style="{
            padding: '8px',
            background: selectedLanguage === 'ES' ? '#E00069' : '#F2F3FB',
            color: selectedLanguage === 'ES' ? 'white' : '#E00069',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            width: '60px',
            fontSize: '14px',
          }"
          @click="setLanguage('ES')"
        >
          ES
        </button>
      </div>

      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img src="/images/login-illustration.svg" style="width: 50%" alt="" />
        <p
          style="
            color: white;
            font-size: 32px;
            font-weight: 600;
            margin-top: 32px;
          "
        >
          Sign up for Eddy!
        </p>
        <button
          style="
            font-size: 20px;
            font-weight: 600;
            color: #003d6a;
            background-color: white;
            width: 480px;
            height: 48px;
            border-radius: 12px;
            margin-top: 32px;
          "
          @click="router.push('/signup')"
        >
          New to Eddy? Sign up here!
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
// import { useStore } from "vuex"
import BaseButton from "@/components/common/BaseButton.vue";
import { isAxiosError } from "axios";
import { validateEmail } from "../utils";
import EmailVerificationMessage from "@/components/EmailVerificationMessage.vue";
import BaseInput from "@/components/BaseInput.vue";
import authService from "@/services/authService";
const router = useRouter();

const email = ref("");
const selectedLanguage = ref("EN");
const loading = ref(false);
const isEmailError = ref(false);
const emailErrorMessage = ref("");
const errorMessage = ref("");
const isEmailSent = ref(false);

function setLanguage(lang) {
  selectedLanguage.value = lang;
}

async function send() {
  const { isValid, message } = validateEmail(email.value);
  if (!isValid) {
    isEmailError.value = true;
    emailErrorMessage.value = message;
    return;
  }

  isEmailError.value = false;
  emailErrorMessage.value = "";
  errorMessage.value = "";

  loading.value = true;

  try {
    await authService.forgotPassword({ email: email.value });
    isEmailSent.value = true;
  } catch (error) {
    isEmailError.value = true;
    if (isAxiosError(error)) {
      if (error?.response?.status >= 400 && error?.response?.status <= 499) {
        errorMessage.value = error?.response?.data?.message;
      } else {
        errorMessage.value = "Sorry, we can't process your request.";
      }
    } else {
      errorMessage.value = "Sorry, we can't process your request.";
    }
  } finally {
    loading.value = false;
  }
}
</script>
