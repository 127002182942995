<template>
  <div class="modal-overlay" v-if="isOpen" @click="close">
    <div class="modal privacy-policy flex flex-column">
      <div class="flex justify-end">
        <button class="btn-close" @click="close">&times;</button>
      </div>
      <div>
        <h2 class="title">Privacy Policy</h2>
        <p>
          This Privacy Policy outlines the types of information that Eddy and edm8ker LLC (“we,” “us,” “our”) may collect from you when you visit our website, how we use that information, and the choices you have regarding our use of your personal data. By using our website, you agree to this Privacy Policy.
        </p>
        <h3>1. Information We Collect</h3>
        <p>
          We may collect various types of personal data from you, including:
          <ul>
            <li>Contact information, such as your name, email address, phone number, and mailing address.</li>
            <li>Demographic information, such as your age, gender, and location.</li>
            <li>Information about your use of our website, such as your IP address, browser type, and operating system.</li>
            <li>Any other information you choose to provide to us.</li>
          </ul>
        </p>
        <h3>2. How We Use Your Information</h3>
        <p>
          We may use your personal data for the following purposes:
          <ul>
            <li>To provide you with the products or services you have requested.</li>
            <li>To personalize your experience on our website.</li>
            <li>To improve our website and the services we offer.</li>
            <li>To improve our website and the services we offer.</li>
            <li>To communicate with you about our products, services, and promotions.</li>
            <li>To conduct research and analytics.</li>
            <li>To comply with legal obligations.</li>
          </ul>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        isOpen: { type: Boolean, default: false },
    },
    methods: {
        close() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>
  .modal-overlay {
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,.5);
  }
  .modal {
    z-index: 999999;
    position: fixed; 
    box-shadow: 0px 2px 5px #cdcdcd;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -60%);
    text-align: left;
    overflow: auto;
    padding-top: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    height: auto !important;
    max-height: 85vh;
    background-color: white;
  }

  .modal .btn-close {
    font-size: 2rem;
    color: #003D6A;
  }

  .modal .title {
    color: #003D6A;
    font-size: 40px;
    margin-bottom: 1.5rem;
  }

  .modal h3 {
    margin: 1rem 0;
    color: #3B4648;
  }

  .modal ul { 
    margin-left: 1rem;
  }

  .modal ul li { 
    margin: 0;
  }
</style>