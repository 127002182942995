<template>
  <div id="dynamicHeightDiv" class="background-style-2"></div>

  <!-- Detailed Profile Section -->
  <div class="container-profile">
    <div v-if="user" class="profile-photo" style="position: relative">
      <img
        style="
          position: absolute;
          top: -90px;
          min-width: 20px;
          min-height: 20px;
          height: 200px;
          width: 200px;
          border-radius: 100px;
          border: 10px solid rgb(0, 61, 106);
        "
        :src="user.image ? user.image : '/images/no-profile.webp'"
        alt="User Image"
      />
    </div>
    <div v-if="user" class="main-content">
      <div class="detailed-profile-container">
        <div class="name-email">
          <div style="color: #003d6a; font-weight: 600; font-size: 2em">
            {{ user.firstname }} {{ user.lastname }}
          </div>
          <div style="color: #003d6a; font-weight: 600; font-size: 1.2em">
            {{ user.email }}
          </div>
        </div>
        <div class="detailed-profile-icons">
          <span>Public</span>
          <img src="/images/eye.svg" alt="" />
        </div>
      </div>
    </div>
  </div>

  <!-- Full Content Section -->
  <div class="container-profile mb-10">
    <div class="sidebar-profile">
      <ul style="margin-right: 1.2em">
        <template v-if="isAdmin">
          <li @click="redirectToAdmin()">Admin</li>
        </template>
        <li
          v-for="(menu, index) in menuItems"
          :key="index"
          :class="{
            active: currentIndex === index,
            disabled: menu.disabled && !menu.redirect,
          }"
          @click="setActive(index, menu.disabled)"
        >
          {{ menu.label }}
        </li>
      </ul>
    </div>
    <div class="main-content">
      <!-- Conditionally Render Child Components -->

      <!-- Uncomment the following components when ready -->
      <AccountContent v-if="currentIndex === 0" />
      <!-- <SecuritySettings v-if="currentIndex === 1" /> -->
      <!-- <ReferralContent v-if="currentIndex === 2" /> -->
      <!-- <BadgesContent v-if="currentIndex === 3" /> -->
      <!-- <PostHistory v-if="currentIndex === 5" /> -->

      <!-- Temporary placeholders -->
      <div v-if="currentIndex === 1">
        <p>Security Settings Content (Disabled)</p>
      </div>
      <div v-if="currentIndex === 2">
        <p>Referral Content (Disabled)</p>
      </div>
      <div v-if="currentIndex === 3">
        <p>Badges Content (Disabled)</p>
      </div>
      <div v-if="currentIndex === 4">
        <ResourcesContent v-if="currentIndex === 4" />
      </div>
      <div v-if="currentIndex === 5">
        <p>Post History Content (Disabled)</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
// Child components (commented for now)

// import SecuritySettings from './SecuritySettings.vue';
// import ReferralContent from './ReferralContent.vue';
// import BadgesContent from './BadgesContent.vue';
import ResourcesContent from "@/components/profile/ResourcesContent.vue";
// import PostHistory from './PostHistory.vue';
import UserService from "@/services/userService";
import cookiesService from "@/services/cookiesService";
import AccountContent from "@/components/profile/AccountContent.vue";

const user = ref(null);
const isAdmin = ref(false);

const currentIndex = ref(4); // Default to Resources tab
const menuItems = ref([
  { label: "Account", disabled: false, redirect: false },
  { label: "Security Settings", disabled: false, redirect: true },
  { label: "Referral", disabled: false, redirect: true },
  { label: "Badges", disabled: false, redirect: true },
  { label: "My Resources", disabled: false },
  { label: "Post History", disabled: false, redirect: true },
]);

// Load user profile data
const getProfile = async () => {
  try {
    const authToken = await cookiesService.getCookie("authToken");
    if (authToken) {
      const response = await UserService.getProfile(authToken.token);
      if (response.status === 200) {
        user.value = response.data.data;
        isAdmin.value = response.data.data.user_group_id === 1;
      }
    }
  } catch (error) {
    console.error("Failed to load profile:", error);
  }
};

const setActive = (index, isDisabled) => {
  if (!isDisabled) {
    currentIndex.value = index;
  }

  switch (index) {
    case 0:
      // window.open(`${process.env.VUE_APP_LOGIN_URL}/account`, "_blank");
      break;
    case 1:
      window.open(
        `${process.env.VUE_APP_LOGIN_URL}/account/change-password`,
        "_blank"
      );
      break;
    case 2:
      window.open(
        `${process.env.VUE_APP_LOGIN_URL}/account/referral`,
        "_blank"
      );
      break;
    case 3:
      window.open(
        `${process.env.VUE_APP_LOGIN_URL}/account/mlu-badges`,
        "_blank"
      );
      break;
    case 5:
      window.open(
        `${process.env.VUE_APP_LOGIN_URL}/account/post-history/${user.value.user_id}`,
        "_blank"
      );
      break;
    default:
  }
};

// Set background image for the dynamic height div
const setBackgroundImage = () => {
  const dynamicHeightDiv = document.getElementById("dynamicHeightDiv");
  if (dynamicHeightDiv) {
    dynamicHeightDiv.style.backgroundImage = "url('./images/background-2.svg')";
    const width = window.innerWidth;
    const height = width * (200 / 1440);
    dynamicHeightDiv.style.height = `${height}px`;
    dynamicHeightDiv.style.backgroundSize = "cover";
    dynamicHeightDiv.style.borderRadius = "0px 0px 25px 25px";
  }
};

onMounted(() => {
  setBackgroundImage();
  window.addEventListener("resize", setBackgroundImage);
  getProfile();
});

onUnmounted(() => {
  window.removeEventListener("resize", setBackgroundImage);
});
</script>

<style>
.container-profile {
  display: flex;
  flex-direction: row;
  margin: 0em 2em;
}

.sidebar-profile {
  margin: 0em 1.5em;
  flex: 0 0 200px;
  height: 100%;
  background-color: #ffffff;
  color: #cccccc;
  border-right: 2px solid #d8dae5;
}

.profile-photo {
  margin: 0em 1.5em;
  flex: 0 0 200px;
  height: 100%;
  background-color: #ffffff;
  color: #cccccc;
}

.sidebar-profile ul {
  list-style-type: none;
  padding: 0;
}

.sidebar-profile li {
  margin-top: 2px;
  padding: 15px 20px;
  cursor: pointer;
  border-left: 5px solid transparent;
}

.sidebar-profile li.active {
  border-radius: 10px;
  background-color: #003d6a;
  color: #ffffff;
  border-bottom: 0px solid #e00069;
}

.main-content {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
}

.sidebar-profile li.disabled {
  cursor: not-allowed;
}

.sidebar-profile li:hover {
  border-radius: 10px;
  background-color: #003d6a;
  color: #ffffff;
  border-bottom: 0px solid #e00069;
}

.detailed-profile-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name-email h2,
.name-email h3 {
  color: #003d6a;
  margin: 0;
}

.detailed-profile-icons {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #00925e;
}

.detailed-profile-icons img {
  height: 24px;
  width: auto;
}

.detailed-profile-icons span {
  font-size: 16px;
}

.card-header-created {
  color: #ccc;
  font-size: 0.8em;
}

.card-header-date {
  color: #3b4648;
  font-size: 0.8em;
}
</style>
