<template>
  <div class="modal-overlay" v-if="isOpen" @click="close">
    <div class="modal notification-setting flex flex-column">
      <div class="flex justify-end">
        <button class="btn-close" @click="close">&times;</button>
      </div>
      <div>
        <h2 class="title">Notification Settings</h2>
        <p>
          By default we subscribe you to the following announcement and activity
          email notifications. You can change your notification settings at any
          time within the email itself - just scroll to the bottom of an email
          notification. Changing email notifications within Eddy is not
          supported at the moment, and will be added as a feature at a later
          date.
          <br />
          <b>
            <ul>
              <li>
                Latest Resources on Eddy: Fresh resources and upcoming events.
              </li>
              <li>
                Feedback: Help us continue improving our product and lesson
                packages!
              </li>
            </ul>
          </b>
          <br /><br />
          No spam ever. Unsubscribe any time.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: { type: Boolean, default: false },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
}
.modal {
  z-index: 999999;
  position: fixed;
  box-shadow: 0px 2px 5px #cdcdcd;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  text-align: left;
  overflow: auto;
  padding-top: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  height: auto !important;
  max-height: 85vh;
  background-color: white;
}

.modal .btn-close {
  font-size: 2rem;
  color: #003d6a;
}

.modal .title {
  color: #003d6a;
  font-size: 40px;
  margin-bottom: 1.5rem;
}

.modal h3 {
  margin: 1rem 0;
  color: #3b4648;
}

.modal ul {
  margin-left: 1rem;
}

.modal ul li {
  margin: 0;
}
</style>
