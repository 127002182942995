import http from "./httpService";

class YsaTeacherService {
  async getStudentList(params) {
    const url = http.getUrlWithParams(
      "/admin/dashboard/teachers/students",
      params
    );
    return await http.get(url);
  }

  async getMasterclassList(params) {
    const url = http.getUrlWithParams("/masterclass/teachers", params);
    return await http.get(url);
  }

  async getMasterclassById(id) {
    return await http.get(`/masterclass/${id}`);
  }

  async viewMasterclass(id) {
    return await http.get(`/masterclass/view/${id}`);
  }

  async getMasterclassTrack(id, params) {
    const url = http.getUrlWithParams(`/masterclass/${id}/students`, params);
    return await http.get(url);
  }

  async downloadMasterclassSurvey(id) {
    return await http.get(`/masterclass/${id}/students/export`, {
      responseType: "blob",
    });
  }
}

export default new YsaTeacherService();
