<template>
  <div class="admin-container">
    <TableData
      :headers="dynamicHeaders"
      :items="studentList"
      :loading="loading"
      :total-pages="totalPages"
      :current-page="currentPage"
      :use-api-sort="true"
      @update:current-page="handlePageChange"
      @sort="handleSort"
    />
  </div>
</template>

<script setup>
import TableData from "@/components/common/TableData.vue";
import ysaTeacherService from "@/services/ysaTeacherService";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const studentList = ref([]);

const sortParams = computed(() => ({
  sort_by: route.query.sort_by || "firstname",
  sort_dir: route.query.sort_dir || "asc",
}));

const filters = ref({
  page: 1,
  limit: 10,
  sort_by: sortParams.value.sort_by,
  sort_order: sortParams.value.sort_dir,
});

const totalPages = ref(0);
const currentPage = ref(1);

const loading = ref(false);

const dynamicHeaders = ref([
  { text: "Student Name", value: "fullname", sortable: true },
  { text: "Email", value: "email", sortable: true },
  {
    text: "Masterclasses not started",
    value: "assigned_masterclass_notstarted",
    sortable: true,
  },
  {
    text: "Masterclasses in progress",
    value: "assigned_masterclass_onprogress",
    sortable: true,
  },
  {
    text: "Masterclasses completed",
    value: "assigned_masterclass_finished",
    sortable: true,
  },
]);

const getStudentList = async () => {
  try {
    loading.value = true;
    const response = await ysaTeacherService.getStudentList(filters.value);

    const data = response?.data?.data;

    studentList.value = data?.rows;
    totalPages.value = data?.total_page;
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};

const handlePageChange = (page) => {
  currentPage.value = page;
  filters.value.page = page;
  getStudentList();
};

const handleSort = (sortData) => {
  filters.value.sort_by =
    sortData.sort_by === "fullname" ? "firstname" : sortData.sort_by;
  filters.value.sort_order = sortData.sort_dir.toLowerCase();
  router.push({
    query: {
      ...router.currentRoute.value.query,
      sort_by: sortData.sort_by,
      sort_dir: sortData.sort_dir,
    },
  });
  getStudentList();
};

onMounted(() => {
  getStudentList();
});
</script>
