<template>
  <div :style="{ paddingTop: topSectionHeight }" class="teacher-layout">
    <div class="top-section">
      <h2>YSA Admin Panel</h2>
      <p style="margin-top: 15px">
        View all YSA details here! View individual student information and track
        student progress with Masterclasses.
      </p>
    </div>
    <TeacherSidebar
      class="sidebar"
      :style="{ marginTop: topSectionHeight }"
      :mobileMenuOpen="mobileMenuOpen"
      @toggle-mobile-menu="toggleMobileMenu"
    />
    <div class="teacher-header">
      <AppHeader />
    </div>

    <div
      class="main-content-teacher"
      :class="{ 'mobile-open': mobileMenuOpen }"
    >
      <main class="content-admin">
        <router-view />
      </main>
      <div
        class="teacher-footer"
        ref="footerRef"
        :class="{ 'fixed-footer': isFooterFixed }"
      >
        <AppFooter />
      </div>
    </div>
    <div class="overlay" v-if="mobileMenuOpen" @click="toggleMobileMenu"></div>
  </div>
</template>

<script setup>
import AppFooter from "@/components/AppFooter.vue";
import AppHeader from "@/components/AppHeader.vue";
import TeacherSidebar from "@/components/teacher/TeacherSidebar.vue";
import cookiesService from "@/services/cookiesService";
import { computed, onMounted, onUnmounted, ref } from "vue";

const mobileMenuOpen = ref(false);
const topSectionHeight = computed(() => {
  return user.value?.role === "Administrator" ? "80px" : "40px";
});
const footerRef = ref(null);
const isFooterFixed = ref(false);

const user = ref(null);

const getUserData = async () => {
  const authToken = await cookiesService.getCookie("authToken");
  user.value = authToken;
};

onMounted(() => {
  getUserData();
});

function toggleMobileMenu() {
  mobileMenuOpen.value = !mobileMenuOpen.value;
}

// Function to check if footer is in viewport
function checkFooterVisibility() {
  if (!footerRef.value) return;

  const windowHeight = window.innerHeight;
  const documentHeight = document.documentElement.scrollHeight;
  const scrollPosition = window.scrollY + windowHeight;

  // Only fix the footer when we're near the bottom of the page
  // This ensures it only becomes fixed when user reaches the end of content
  if (documentHeight - scrollPosition < 200) {
    isFooterFixed.value = true;
  } else {
    isFooterFixed.value = false;
  }
}

onMounted(() => {
  window.addEventListener("scroll", checkFooterVisibility);
  // Initial check
  checkFooterVisibility();
});

onUnmounted(() => {
  window.removeEventListener("scroll", checkFooterVisibility);
});
</script>

<style scoped>
.top-section {
  padding-top: 40px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 999;
}

.teacher-layout {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 100px;
}

.teacher-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
.main-content-teacher {
  margin-left: 250px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  position: relative;
  margin-top: 100px;
  overflow-y: auto;
}

.content-admin {
  flex: 1;
  overflow-y: auto;
  background-color: white;
  padding: 30px 20px;
}

.content-admin::-webkit-scrollbar {
  display: none;
}

.content-admin::-webkit-scrollbar-thumb {
  display: none;
}

.teacher-footer {
  transition: all 0.3s ease;
  width: 100%;
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%; /* Match the width of main content */
  z-index: 990;
  background-color: white; /* Ensure the footer has a background */
}

/* Media Queries for Mobile */
@media (max-width: 991px) {
  .teacher-layout {
    flex-direction: column;
  }

  .sidebar {
    width: 250px;
    height: 100%;
    position: absolute;
    z-index: 1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .sidebar.mobile-open {
    transform: translateX(0);
  }

  .main-content-teacher {
    margin-left: 0;
    transition: transform 0.3s ease-in-out;
  }

  .main-content-teacher.mobile-open {
    transform: translateX(250px);
  }

  .hamburger-menu {
    display: block;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .fixed-footer {
    width: 100%; /* Full width on mobile */
    left: 0;
    transition: transform 0.3s ease-in-out;
  }

  .main-content-teacher.mobile-open .fixed-footer {
    transform: translateX(250px);
  }
}
</style>
