<template>
  <div>
    <TableData :headers="dynamicHeaders" :items="trackItems" :loading="tracksLoading"
      :total-pages="tracksData?.data?.total_page" :current-page="currentPage" @update:current-page="handlePageChange"
      @sort="handleSort" :use-api-sort="true" />
    <div class="teacher-list-tool-container">
      <div class="teacher-list-tool-item">
        <BaseButton variant="primary" :loading="downloading" loading-text="Downloading..." @click="downloadSurveyData">
          Download Survey Data
        </BaseButton>
        <transition name="fade">
          <div v-if="downloadMessage" :class="['download-message', downloadMessage.type]">
            {{ downloadMessage.text }}
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup>
import TableData from "@/components/common/TableData.vue";
import { useDataFetching } from "@/composeables/useDataFetching";
import ysaTeacherService from "@/services/ysaTeacherService";
import { computed, defineEmits, onMounted, ref, watch } from "vue";

import BaseButton from "@/components/common/BaseButton.vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const masterclassId = computed(() => route.params.id);

const sortParams = computed(() => ({
  sort_by: route.query.sort_by || "student_name",
  sort_dir: route.query.sort_dir || "desc",
}));

const emit = defineEmits(["updateSort"]);

const downloading = ref(false);
const downloadMessage = ref(null);

const filters = ref({
  page: 1,
  limit: 10,
  sort_by: sortParams.value.sort_by || "student_name",
  sort_dir: sortParams.value.sort_dir || "desc",
});

const currentPage = ref(1);

const {
  data: tracksData,
  loading: tracksLoading,
  fetchData: fetchTracks,
} = useDataFetching(ysaTeacherService.getMasterclassTrack, {
  initialData: {
    message: "",
    error: null,
    data: {
      page: 0,
      limit: 0,
      total_rows: 0,
      total_page: 0,
      rows: [],
    },
  },
});

const handlePageChange = (page) => {
  filters.value.page = page;
  currentPage.value = page;
  fetchTracks(masterclassId.value, filters.value);
};

// Watch for changes in sort parameters from parent
watch(
  () => sortParams.value,
  (newSortParams) => {
    if (
      newSortParams.sort_by !== filters.value.sort_by ||
      newSortParams.sort_dir !== filters.value.sort_dir
    ) {
      filters.value.sort_by = newSortParams.sort_by;
      filters.value.sort_dir = newSortParams.sort_dir;
      fetchTracks(masterclassId.value, filters.value);
    }
  },
  { deep: true }
);

const trackItems = computed(() => {
  return tracksData.value.data.rows.map((item) => ({
    ...item,
    progress: `${item.progress}%`,
  }));
});

const dynamicHeaders = ref([
  {
    text: "Student Name",
    value: "student_name",
    sortable: true,
  },
  {
    text: "Email",
    value: "student_email",
    sortable: true,
  },
  {
    text: "Completion",
    value: "progress",
    sortable: true,
  },
  {
    text: "Engagement",
    value: "engagement_time",
    sortable: true,
  },
  {
    text: "Survey",
    value: "survey_status",
    sortable: true,
  },
]);

const handleSort = async ({ sort_by, sort_dir }) => {
  filters.value.sort_by = sort_by;
  filters.value.sort_dir = sort_dir;

  router.push({
    query: {
      ...router.currentRoute.value.query,
      sort_by,
      sort_dir,
    },
  });

  // Emit event to update URL in parent component
  emit("updateSort", { sort_by, sort_dir });

  await fetchTracks(masterclassId.value, filters.value);
};

const downloadSurveyData = async () => {
  downloading.value = true;
  downloadMessage.value = null;

  try {
    const response = await ysaTeacherService.downloadMasterclassSurvey(
      masterclassId.value
    );
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "students-survey-data.xlsx";
    a.click();

    // Show success message
    downloadMessage.value = {
      type: "success",
      text: "Download successful!",
    };

    // Auto-hide message after 3 seconds
    setTimeout(() => {
      downloadMessage.value = null;
    }, 3000);
  } catch (error) {
    console.error("Error downloading survey data:", error);

    // Show error message
    downloadMessage.value = {
      type: "error",
      text: "Failed to download. Please try again.",
    };

    // Auto-hide error message after 5 seconds
    setTimeout(() => {
      downloadMessage.value = null;
    }, 5000);
  } finally {
    downloading.value = false;
  }
};

onMounted(async () => {
  await fetchTracks(masterclassId.value, filters.value);
});
</script>

<style scoped>
.teacher-list-tool-container {
  display: flex;
  justify-content: flex-end;
}

.teacher-list-tool-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.download-message {
  margin-top: 8px;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  max-width: 300px;
}

.download-message.success {
  background-color: rgba(76, 175, 80, 0.1);
  color: #2e7d32;
  border-left: 3px solid #2e7d32;
}

.download-message.error {
  background-color: rgba(244, 67, 54, 0.1);
  color: #d32f2f;
  border-left: 3px solid #d32f2f;
}

/* Transition effects */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
