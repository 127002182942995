<template>
  <div style="display: flex; height: 100vh">
    <img
      src="/images/background-cap.svg"
      alt=""
      style="position: absolute; bottom: 0; left: 0; z-index: -999"
    />
    <div
      style="
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <img
        src="/images/white-bird.svg"
        style="position: absolute; z-index: -1; bottom: 0; left: 0; width: 40%"
        alt=""
      />
      <router-link
        to="/"
        style="
          display: flex;
          gap: 19px;
          margin-top: 40px;
          margin-left: 40px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 50;
        "
      >
        <img src="/images/eddy.svg" alt="" />
      </router-link>

      <template v-if="isPasswordReset">
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
          "
        >
          <div>
            <h1
              style="
                font-size: 32px;
                font-weight: 600;
                margin-bottom: 1rem;
                color: #003d6a;
              "
            ></h1>
            <p style="text-align: left; color: #003d6a">
              Your password has been changed. You will be re-directed to the
              Login page <br />
              in <span style="font-weight: bold">{{ countdown }}</span>
              seconds
            </p>
          </div>
        </div>
      </template>
      <template v-else>
        <div style="display: flex; justify-content: center; width: 100%">
          <div style="width: 430px">
            <h1
              style="
                font-size: 32;
                font-weight: 600;
                margin-bottom: 1rem;
                color: #003d6a;
              "
            >
              Reset Password
            </h1>
          </div>
        </div>
        <div
          style="
            display: flex;
            gap: 20px;
            width: 100%;
            flex-direction: column;
            align-items: center;
            margin-top: 10px;
          "
        >
          <div style="display: flex; flex-direction: column">
            <BaseInput
              type="password"
              label="Password*"
              placeholder="Input your password"
              v-model="password"
              style="width: 430px"
              :error="isPasswordError"
              :errorMessage="passwordErrorMessage"
              :showInputError="false"
              @input="validatePasswordInput"
            />

            <PasswordValidationList
              :password="password"
              :validations="validations"
            />
          </div>
          <BaseInput
            type="password"
            label="Retype Password*"
            placeholder="Confirm your password"
            v-model="confirmPassword"
            style="width: 430px"
            :error="isConfirmPasswordError"
            :errorMessage="confirmPasswordErrorMessage"
            :showInputError="false"
          />
          <div
            v-if="errorMessage"
            :style="{ color: errorMessage ? '#e00269' : 'transparent' }"
          >
            {{ errorMessage }}
          </div>
        </div>

        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            margin-top: 20px;
          "
        >
          <div style="display: flex; gap: 10px; flex-direction: column">
            <BaseButton
              :loading="loading"
              :disabled="loading"
              @click="resetPassword"
              variant="primary"
              style="width: 430px"
            >
              Reset Password
            </BaseButton>
          </div>
        </div>
      </template>
    </div>

    <!-- right side -->
    <div
      style="
        width: 50%;
        background-color: #003d6a;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <div
        style="
          display: flex;
          justify-content: flex-end;
          width: 100%;
          margin-bottom: 1rem;
          margin-top: 45px;
          padding-right: 40px;
          position: absolute;
          right: 0;
          top: 0;
        "
      >
        <button
          :style="{
            padding: '8px',
            background: selectedLanguage === 'EN' ? '#E00069' : '#F2F3FB',
            color: selectedLanguage === 'EN' ? 'white' : '#E00069',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            width: '60px',
            fontSize: '14px',
          }"
          @click="setLanguage('EN')"
        >
          EN
        </button>
        <button
          :style="{
            padding: '8px',
            background: selectedLanguage === 'ES' ? '#E00069' : '#F2F3FB',
            color: selectedLanguage === 'ES' ? 'white' : '#E00069',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            width: '60px',
            fontSize: '14px',
          }"
          @click="setLanguage('ES')"
        >
          ES
        </button>
      </div>

      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img src="/images/login-illustration.svg" style="width: 50%" alt="" />
        <p
          style="
            color: white;
            font-size: 32px;
            font-weight: 600;
            margin-top: 32px;
          "
        >
          Sign up for Eddy!
        </p>
        <button
          style="
            font-size: 20px;
            font-weight: 600;
            color: #003d6a;
            background-color: white;
            width: 480px;
            height: 48px;
            border-radius: 12px;
            margin-top: 32px;
          "
          @click="router.push('/signup')"
        >
          New to Eddy? Sign up here!
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { isAxiosError } from "axios";
import { computed, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import BaseButton from "@/components/common/BaseButton.vue";
import PasswordValidationList from "@/components/PasswordValidationList.vue";
import { validatePassword } from "@/utils";
import BaseInput from "@/components/BaseInput.vue";
import authService from "@/services/authService";

const selectedLanguage = ref("EN");
const password = ref("");
const isPasswordError = ref(false);
const passwordErrorMessage = ref("");
const confirmPassword = ref("");
const isConfirmPasswordError = ref(false);
const confirmPasswordErrorMessage = ref("");
const errorMessage = ref("");
const loading = ref(false);
const isPasswordReset = ref(false);

const countdown = ref(10);

const router = useRouter();

const route = useRoute();
const token = computed(() => route.query.token);

const startCountdown = () => {
  countdown.value = 5;
  const countdownInterval = setInterval(() => {
    countdown.value--;
  }, 1000);

  setTimeout(() => {
    clearInterval(countdownInterval);
    router.push("/signin");
  }, 5000);
};

// Add validation state for password requirements display
const validations = ref({
  lowercase: false,
  uppercase: false,
  specialChar: false,
  length: false,
});

function setLanguage(lang) {
  selectedLanguage.value = lang;
}

function validatePasswordInput() {
  const result = validatePassword(password.value);
  validations.value = result.validations;

  // Update error state
  isPasswordError.value = !result.isValid;

  // Clear confirm password if password is cleared
  if (password.value.length === 0) {
    confirmPassword.value = "";
  }
}

async function resetPassword() {
  // Validate password
  const passwordValidationResult = validatePassword(password.value);
  if (!passwordValidationResult.isValid) {
    isPasswordError.value = true;
    passwordErrorMessage.value = passwordValidationResult.message;
    return;
  }

  // Validate confirm password
  if (!confirmPassword.value) {
    isConfirmPasswordError.value = true;
    confirmPasswordErrorMessage.value = "Please confirm your password";
    return;
  }

  if (password.value !== confirmPassword.value) {
    isConfirmPasswordError.value = true;
    confirmPasswordErrorMessage.value = "Passwords do not match";
    return;
  }

  isPasswordError.value = false;
  passwordErrorMessage.value = "";
  isConfirmPasswordError.value = false;
  confirmPasswordErrorMessage.value = "";

  loading.value = true;

  try {
    await authService.resetPassword(token.value, {
      password: password.value,
      confirm_password: confirmPassword.value,
    });
    isPasswordReset.value = true;
    startCountdown();
  } catch (error) {
    if (isAxiosError(error)) {
      if (error?.response?.data?.message) {
        errorMessage.value = error?.response?.data?.message;
      } else {
        errorMessage.value = "Sorry, we can't process your request.";
      }
    } else {
      errorMessage.value = "Sorry, we can't process your request.";
    }
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  if (!token.value) {
    router.push("/signin");
  }
});
</script>
