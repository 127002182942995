<template>
  <aside class="sidebar" :class="{ 'mobile-open': props.mobileMenuOpen }">
    <div class="sidebar-header">
      <a href="/">
        <img height="40" src="/images/eddy.svg" alt="Eddy Logo" />
      </a>
      <div
        class="close-menu"
        @click="$emit('toggle-mobile-menu')"
        v-if="isMobile"
      >
        <img src="/images/close.svg" alt="Close" />
      </div>
    </div>
    <nav class="menu scrollbar" id="style-4">
      <ul>
        <li
          v-for="item in items"
          :key="item.title"
          @click="item.children ? toggleSubmenu(item) : navigate(item)"
          :class="{
            highlight: isActive(item),
            'menu-parent': item.children,
            'submenu-open': hasActiveChild(item),
          }"
        >
          <div class="menu-item">
            <img
              height="20"
              v-if="item.icon"
              :src="`/images/sidebar-menu/${item.icon}.svg`"
              alt="Eddy Logo"
            />
            <span style="margin: auto 0">{{ item.title }}</span>
            <img
              v-if="item.children"
              :src="
                isSubmenuOpen(item)
                  ? '/images/admin/polygon-up.svg'
                  : '/images/admin/polygon-down.svg'
              "
              class="expand-icon"
              @click.stop="toggleSubmenu(item)"
            />
          </div>
          <ul
            v-if="item.children"
            :class="{
              'submenu-open': isSubmenuOpen(item) || hasActiveChild(item),
            }"
          >
            <li
              v-for="child in item.children"
              :key="child.title"
              @click.stop="navigate(child)"
              :class="{ highlight: isActive(child), 'menu-child': true }"
            >
              <span>{{ child.title }}</span>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, defineProps } from "vue";
import { useRouter, useRoute } from "vue-router";

const props = defineProps({
  mobileMenuOpen: Boolean,
});

// const emit = defineEmits(['toggle-mobile-menu']);

const router = useRouter();
const route = useRoute();

const appLoginUrl = computed(() => process.env.VUE_APP_LOGIN_URL);

const items = ref([
  { title: "Dashboard", icon: "dashboard", route: "/admin/dashboard" },
  { title: "Users List", icon: "user-list", route: "/admin/users" },
  {
    title: "Redeemed Referrals",
    icon: "redeemed-referrals",
    route: "/admin/redeem",
  },
  {
    title: "Register New Teacher",
    icon: "register-new-teacher",
    route: "/admin/invite-teacher",
  },
  { title: "Lesson Plan", icon: "lesson-plan", route: "/admin/lesson-plans" },
  {
    title: "Maker Resources",
    icon: "maker-resources",
    url: `${appLoginUrl.value}/admin/maker-resources`,
  },
  {
    title: "Micro Learning",
    icon: "micro-learning",
    url: `${appLoginUrl.value}/admin/instructional-strategies`,
  },
  {
    title: "STEM Orders",
    icon: "cart",
    route: "/admin/maker-cart",
    class: "highlight",
  },
  {
    title: "China-US YMC",
    icon: "china-us-ymc",
    route: "/admin/china-us",
    class: "highlight",
  },
  {
    title: "YSA Dashboard",
    icon: "ysa-dashboard",
    children: [
      { title: "Dashboard", route: "/admin/ysa/dashboard" },
      {
        title: "Self Directed Learning",
        url: `${appLoginUrl.value}/admin/ysa/self-directed-learning`,
      },
      {
        title: "Lesson Packages",
        url: `${appLoginUrl.value}/admin/lesson-package`,
      },
      { title: "Teachers List", route: "/admin/ysa/ysaTeacherListPage" },
      { title: "Student List", route: "/admin/ysa/ysaStudentListPage" },
      { title: "School List", route: "/admin/ysa/ysaSchoolListPage" },
      { title: "Masterclass", route: "/admin/ysa/masterclass" },
    ],
  },
  { title: "Categories", icon: "categories", route: "/admin/categories" },
  { title: "Comments", icon: "comments", route: "/admin/comment" },
  { title: "Coach", icon: "coach", route: "/admin/coach" },
  {
    title: "Logs",
    icon: "users-log",
    children: [
      { title: "Users Log", route: "/admin/userlog" },
      { title: "Content Logs", route: "/admin/contentLog" },
      { title: "Changelog", route: "/admin/change-log" },
    ],
  },
  { title: "Scorm Files", icon: "scorm-files", route: "/admin/scorm" },
  { title: "Enquiries", icon: "enquiries", route: "/admin/formsubmission" },
]);

const openSubmenu = ref(null);

const navigate = (item) => {
  // emit('toggle-mobile-menu');
  if (item.url) {
    window.open(item.url, "_blank");
  } else if (item.route) {
    router.push(item.route);
  }
};

const toggleSubmenu = (item) => {
  openSubmenu.value = openSubmenu.value === item ? null : item;
};

const isSubmenuOpen = (item) => {
  return openSubmenu.value === item;
};

const isMobile = ref(window.innerWidth < 991);

const handleResize = () => {
  isMobile.value = window.innerWidth < 991;
};

const isActive = (item) => {
  if (item.route) {
    return route.path.startsWith(item.route);
  } else if (item.url) {
    return route.fullPath.startsWith(item.url);
  }
  return false;
};

const hasActiveChild = (item) => {
  if (item.children) {
    return item.children.some((child) => isActive(child));
  }
  return false;
};

window.addEventListener("resize", handleResize);

onMounted(() => {
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});
</script>

<style scoped>
.sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  color: #003d6a;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  border-right: 1px solid #d8dae5;
}

.sidebar-header {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.logo {
  max-width: 100px;
}

.menu {
  flex: 1;
  margin: 0px 0px 0px 20px;
}

.menu ul {
  list-style: none;
  padding: 0;
}

.menu li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  margin-bottom: 2px;
}

.menu-item {
  border-radius: 12px;
  padding: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}

.menu li.menu-child {
  border-radius: 12px;
  padding: 10px;
  width: 100%;
}

.menu-item:hover {
  background-color: #e00069;
  color: white;
}

.menu-item:hover img {
  filter: invert(98%) sepia(8%) saturate(59%) hue-rotate(140deg)
    brightness(119%) contrast(100%);
}

.menu li.menu-parent:hover > .menu-item {
  /* background-color: #E00069; */
  /* color: white; */
}

.menu li.menu-parent:hover > .menu-item img {
  /* filter: invert(98%) sepia(8%) saturate(59%) hue-rotate(140deg) brightness(119%) contrast(100%); */
}

.menu li.menu-child:hover {
  background-color: #e00069; /* Different color for child items */
  color: white;
}

.menu li img {
  margin-right: 10px;
  color: #003d6a;
  filter: invert(15%) sepia(77%) saturate(2258%) hue-rotate(186deg)
    brightness(92%) contrast(104%);
}

.menu .highlight img {
  filter: invert(98%) sepia(8%) saturate(59%) hue-rotate(140deg)
    brightness(119%) contrast(100%);
}

.highlight {
  border-radius: 12px;
  background-color: #e00069;
  color: white;
}

.submenu-open {
  display: block;
  width: 100%;
}

.menu li ul {
  display: none;
  list-style-type: none;
  padding-left: 10px;
}

.menu li ul.submenu-open {
  display: block;
}

.expand-icon {
  margin-left: auto;
  cursor: pointer;
}

/* Mobile Styles */
@media (max-width: 991px) {
  .sidebar {
    width: 250px;
    height: 100%;
    position: fixed;
    z-index: 1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .sidebar-header {
    display: flex;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 20px;
  }

  .sidebar.mobile-open {
    transform: translateX(0);
  }

  .close-menu {
    display: block;
    cursor: pointer;
  }

  .close-menu img {
    height: 30px;
  }
}
</style>
