<template>
  <div class="input-wrap">
    <label class="input-main" v-if="label" v-bind="$attrs">{{ label }}</label>
    <input
      class="input-main"
      :class="{ 'input-error': error }"
      v-if="type === 'text'"
      type="text"
      :value="modelValue"
      :placeholder="error && showInputError ? errorMessage : placeholder"
      @input="$emit('update:modelValue', $event.target.value)"
      v-bind="$attrs"
    />
    <div v-if="type === 'password'" style="position: relative">
      <input
        class="input-main"
        :class="{ 'input-error': error }"
        :type="isPasswordVisible ? 'text' : 'password'"
        :value="modelValue"
        :placeholder="error && showInputError ? errorMessage : placeholder"
        @input="$emit('update:modelValue', $event.target.value)"
        v-bind="$attrs"
      />

      <span
        @click="togglePasswordVisibility"
        style="
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          margin-top: 5px;
          margin-right: 3px;
        "
      >
        <img
          v-if="isPasswordVisible"
          src="/images/eye.svg"
          alt="Toggle Password Visibility"
          width="20"
          height="20"
        />

        <img
          v-else
          src="/images/reveal-password.svg"
          alt="Toggle Password Visibility"
          width="20"
          height="20"
        />
      </span>
    </div>

    <p
      v-if="error && errorMessage && !showInputError"
      style="color: #e00069; margin-top: 5px; font-size: 14px"
    >
      {{ errorMessage }}
    </p>

    <textarea
      :class="{ 'input-main': true, 'input-error': error }"
      v-else-if="type === 'multiline'"
      :value="modelValue"
      :placeholder="error ? errorMessage : placeholder"
      @input="$emit('update:modelValue', $event.target.value)"
      v-bind="$attrs"
    ></textarea>
    <div v-if="type === 'select'" class="dropdown" ref="dropdownRef">
      <input
        class="input-main"
        :class="{ 'input-error': error }"
        type="text"
        style="width: 100%"
        :value="selectedOptionText"
        :placeholder="error && showInputError ? errorMessage : placeholder"
        readonly
        v-bind="$attrs"
        @click="toggleDropdown"
      />
      <img
        :src="currentIcon"
        class="dropdown-icon"
        :class="{ 'rotate-icon': showDropdown }"
        @click="iconClickHandler"
      />
      <div
        v-if="showDropdown"
        class="dropdown-panel"
        :style="{ '--dropdown-max-height': dropdownMaxHeight }"
      >
        <input
          class="input-search"
          type="text"
          placeholder="Search ..."
          @input="searchHandler($event.target.value)"
          @keyup="apiSearchEnabled && debounceApiSearch($event.target.value)"
        />
        <div class="options-container">
          <div v-if="isLoading" class="loading-indicator">
            <div class="input-spinner"></div>
          </div>
          <template v-else>
            <div
              v-for="(option, index) in filteredOptions"
              :key="index"
              @click.stop="selectOption(option)"
            >
              {{ option.text }}
            </div>
            <div v-if="filteredOptions.length === 0" class="no-results">
              No results found
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-if="type === 'multipleSelect'" class="dropdown" ref="dropdownRef">
      <input
        class="input-main"
        type="text"
        style="width: 100%"
        :value="selectedItemsCount"
        :placeholder="placeholder"
        readonly
        v-bind="$attrs"
        @click="toggleDropdown"
      />
      <button @click="iconClickHandler">
        <img
          :src="currentIcon"
          class="dropdown-icon"
          :class="{ 'rotate-icon': showDropdown }"
        />
      </button>

      <div
        v-if="showDropdown"
        class="dropdown-panel"
        :style="{ '--dropdown-max-height': dropdownMaxHeight }"
      >
        <input
          class="input-search"
          type="text"
          placeholder="Search ..."
          @input="searchHandler($event.target.value)"
          @keyup="apiSearchEnabled && debounceApiSearch($event.target.value)"
        />

        <div class="options-container">
          <div v-if="isLoading" class="loading-indicator">
            <div class="input-spinner"></div>
          </div>
          <template v-else>
            <div
              v-for="(option, index) in filteredOptions"
              :key="index"
              class="checkbox-option"
            >
              <input
                type="checkbox"
                :id="'checkbox-' + index"
                :value="option.value"
                :checked="isSelected(option.value)"
                @change="toggleSelection(option.value, index)"
                :disabled="shouldDisableCheckbox(option.value)"
                v-model="selectedValues"
              />
              <label :for="'checkbox-' + index">{{ option.text }}</label>
            </div>
            <div v-if="filteredOptions.length === 0" class="no-results">
              No results found
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  onMounted,
  onUnmounted,
  computed,
  nextTick,
} from "vue";
import { watch, watchEffect } from "vue";

const props = defineProps({
  label: {
    type: [String, Boolean],
    default: false,
  },
  modelValue: {
    type: [String, Array, Number],
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  options: {
    type: Array,
    default: () => [],
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: "",
  },
  maxHeight: {
    type: String,
    default: "",
  },
  apiSearchEnabled: {
    type: Boolean,
    default: false,
  },
  apiSearchFunction: {
    type: Function,
    default: null,
  },
  apiSearchDebounce: {
    type: Number,
    default: 300,
  },
  showInputError: {
    type: Boolean,
    default: true,
  },
  maximumMultipleSelect: {
    type: Number,
    default: 2,
  },
});

const isPasswordVisible = ref(false);
const currentIcon = ref("/images/arrow-circle-left.svg");

const dropdownMaxHeight = computed(() => {
  return props.maxHeight ? `${props.maxHeight}px` : "none";
});

const togglePasswordVisibility = () => {
  isPasswordVisible.value = !isPasswordVisible.value;
};

onMounted(() => {
  window.addEventListener("click", closeDropdown);

  if (
    (props.type === "select" && props.modelValue) ||
    (props.type === "multipleSelect" &&
      props.modelValue &&
      props.modelValue.length > 0)
  ) {
    currentIcon.value = "/images/close.svg";
  } else {
    currentIcon.value = "/images/arrow-circle-left.svg";
  }
});

onUnmounted(() => {
  window.removeEventListener("click", closeDropdown);
});

watch(
  () => props.modelValue,
  (newValue) => {
    if (!newValue) {
      currentIcon.value = "/images/arrow-circle-left.svg";
    }
  }
);

watchEffect(() => {
  if (
    (props.type === "select" && props.modelValue) ||
    (props.type === "multipleSelect" &&
      props.modelValue &&
      props.modelValue.length > 0)
  ) {
    currentIcon.value = "/images/close.svg";
  } else {
    currentIcon.value = "/images/arrow-circle-left.svg";
  }
});

const emit = defineEmits(["update:modelValue", "update:options"]);

const searchText = ref("");
const showDropdown = ref(false);
const dropdownRef = ref(null);

const adjustDropdownPosition = () => {
  nextTick(() => {
    if (!dropdownRef.value) return;

    const dropdownPanel = dropdownRef.value.querySelector(".dropdown-panel");
    if (!dropdownPanel) return;

    const rect = dropdownPanel.getBoundingClientRect();

    if (rect.right < 200) {
      const inputSearch = dropdownPanel.querySelector(".input-search");
      inputSearch.style.left = `0px`;
      inputSearch.style.right = "auto";

      const optionsContainer =
        dropdownPanel.querySelector(".options-container");
      optionsContainer.style.left = `0px`;
      optionsContainer.style.right = "auto";
    }
  });
};

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;

  if (showDropdown.value) {
    resetSearch();

    nextTick(() => {
      adjustDropdownPosition();
    });
  } else {
    resetSearch();
  }
};

const iconClickHandler = () => {
  if (
    (selectedOptionText.value || selectedItemsCount) &&
    currentIcon.value.includes("close.svg")
  ) {
    clearSelection();
  } else {
    toggleDropdown();
  }
};

const closeDropdown = (event) => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    showDropdown.value = false;
    searchText.value = "";

    if (props.apiSearchEnabled && searchText.value === "") {
      if (props.type === "multipleSelect" || props.type === "select") {
        // performApiSearch("");
      }
    }
  }
};

const clearSelection = () => {
  selectedOptionText.value = "";
  emit("update:modelValue", "");

  if (props.type === "multipleSelect") {
    selectedValues.value = [];
  }

  currentIcon.value = "/images/arrow-circle-left.svg";
  showDropdown.value = false;
};

const selectOption = (option) => {
  currentIcon.value = "/images/close.svg";
  showDropdown.value = false;
  selectedOptionText.value = option.text;
  emit("update:modelValue", option.value);
  searchText.value = "";
};

const isLoading = ref(false);
let debounceTimeout = null;

const filteredOptions = computed(() => {
  if (props.apiSearchEnabled) {
    return props.options;
  }

  if (!searchText.value) {
    return props.options;
  }

  return props.options.filter((option) =>
    option.text.toLowerCase().includes(searchText.value.toLowerCase())
  );
});

const searchHandler = (value) => {
  searchText.value = value;

  if (!props.apiSearchEnabled) return;
};

const debounceApiSearch = (value) => {
  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
  }

  if (!value || value.length === 0) {
    performApiSearch("");
    return;
  }

  debounceTimeout = setTimeout(() => {
    performApiSearch(value);
  }, props.apiSearchDebounce);
};

const performApiSearch = async (query) => {
  if (!props.apiSearchFunction) return;

  try {
    isLoading.value = true;
    const results = await props.apiSearchFunction(query);
    emit("update:options", results);
  } catch (error) {
    console.error("Error performing API search:", error);
  } finally {
    isLoading.value = false;
  }
};

const resetSearch = () => {
  searchText.value = "";
  if (props.apiSearchEnabled && props.apiSearchFunction) {
    performApiSearch("");
  }
};

const selectedOptionText = computed(() => {
  if (props.type === "select") {
    const option = props.options.find((o) => o.value === props.modelValue);
    return option ? option.text : "";
  }
  return "";
});

const isSelected = computed(() => {
  return (optionValue) => props.modelValue.includes(optionValue);
});

const toggleSelection = (optionValue) => {
  const newValue = [...props.modelValue];
  const index = newValue.indexOf(optionValue);
  if (index === -1) {
    if (
      newValue.length < props.maximumMultipleSelect ||
      props.maximumMultipleSelect < 0
    ) {
      newValue.push(optionValue);
    } else {
      alert("You can only select up to 2 items.");
      return;
    }
  } else {
    newValue.splice(index, 1);
  }
  emit("update:modelValue", newValue);
};

const selectedValues = ref([]);

// Add this watch to sync selectedValues with modelValue
watch(
  () => props.modelValue,
  (newValue) => {
    if (props.type === "multipleSelect" && Array.isArray(newValue)) {
      selectedValues.value = [...newValue];
    }
  },
  { immediate: true }
);

const shouldDisableCheckbox = (checkboxId) => {
  let maxSelectedCondition = false;
  if (props.maximumMultipleSelect >= 0) {
    maxSelectedCondition =
      selectedValues.value.length >= props.maximumMultipleSelect;
  }

  return maxSelectedCondition && !selectedValues.value.includes(checkboxId);
};

const selectedItemsCount = computed(() => {
  const count = props.modelValue.length;
  return count === 0 ? "" : `${count} Selected`;
});
</script>

<style scoped>
.input-wrap {
  display: flex;
  flex-direction: column;

  input {
    padding: 8px 12px;
    font-size: 16px;
  }
}

.dropdown {
  position: relative;
}

.dropdown input {
  cursor: pointer;
}

.btn-main {
  border-radius: 25px;
  text-decoration: none;
  font-family: "League Spartan";
  font-weight: 500;
  background-color: #00925e;
  color: white;
  display: block;
  padding: 0.5em;
}

.btn-main:hover {
  background-color: #00734a;
}

input.input-main,
textarea.input-main,
select.input-main {
  background-color: #f2f3fb;
  border: 0.5px solid #d8dae5;
  border-radius: 10px;
  font-size: 16px;
  font-family: "League Spartan", sans-serif;
}

input.input-main:hover,
textarea.input-main:hover,
select.input-main:hover {
  /* border: 0.5px solid #CCCCCC !important; */
}

input.input-main::placeholder,
textarea.input-main::placeholder,
select.input-main::placeholder {
  color: #999;
}

input.input-main:focus,
textarea.input-main:focus,
select.input-main:focus {
  border: 0.5px solid #003d6a !important;
}

textarea.input-main {
  height: 100%;
  padding: 5px 10px;
}

select.input-main {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
}

label.input-main {
  color: #e00069;
  font-family: "League Spartan";
  font-size: 0.8em;
  font-weight: 500;
}

.dropdown-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s;
}

.rotate-icon {
  transform: translateY(-50%) rotate(180deg);
  transition: transform 0.3s;
}

.input-search {
  position: absolute;
  top: 0px;
  right: 0;
  width: calc(100% - 20px);
  padding: 8px 10px;
  z-index: 1001;
  background-color: #fff;
  border-radius: 10px;
  border: 0.5px solid #d8dae5 !important;
  width: 100%;
  min-width: 200px;
  margin: 5px 0px;
}

.dropdown-panel {
  font-family: "League Spartan", sans-serif;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1001;
}

.options-container {
  -ms-overflow-style: none;
  position: absolute !important;
  top: 50px;
  right: 0px;
  background-color: #f2f3fb;
  border: 0.5px solid #d8dae5 !important;
  border-radius: 10px;
  color: #003d6a;
  font-family: "League Spartan", sans-serif;
  min-width: 200px;
  width: 100%;
  z-index: 999;
  max-height: 200px;
  overflow-y: auto;
  padding: 5px;
}

/* Updated scrollbar styles */
.options-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.options-container::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
  margin: 5px;
}

.options-container::-webkit-scrollbar-thumb {
  background: rgba(0, 61, 106, 0.2);
  border-radius: 10px;
  transition: background 0.2s ease;
}

.options-container::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 61, 106, 0.4);
}

/* For Firefox */
.options-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 61, 106, 0.2) transparent;
}

.options-container div:hover {
  background-color: #f0f0f0;
}

.options-container div {
  color: #003d6a;
  padding: 8px 12px;
  cursor: pointer;
}

.options-container div:not(:first-child) {
  border-top: 0.5px solid #d8dae5;
}

.options-container div:first-child:hover {
  border-radius: 10px 10px 0 0;
}

.options-container div:last-child:hover {
  border-radius: 10px 10px;
}

.checkbox-option {
  display: flex;
  align-items: center;
}

.checkbox-option input[type="checkbox"] {
  margin-right: 8px;
}

.input-error {
  border: 1px solid #e00069 !important;
}

.input-error .custom-input {
  border: none !important;
}

.input-error::placeholder {
  color: #e00069 !important;
}

/* Existing styles for input, textarea, and select elements */
input.input-main,
textarea.input-main,
select.input-main {
  background-color: #f2f3fb;
  border: 0.5px solid #d8dae5;
  border-radius: 10px;
  font-size: 16px;
  font-family: "League Spartan", sans-serif;
}

/* Add hover styles for input, textarea, and select elements */
input.input-main:hover,
textarea.input-main:hover,
select.input-main:hover {
  border-color: #cccccc;
  /* Change border color to red */
  background-color: #eaecff;
  /* Optional: change background color to a lighter red for better visibility */
}

/* Maintain focus style */
input.input-main:focus,
textarea.input-main:focus,
select.input-main:focus {
  border: 0.5px solid #003d6a !important;
}

.input-wrap {
  display: flex;
  flex-direction: column;
}

input.input-main,
textarea.input-main,
select.input-main {
  background-color: #f2f3fb;
  border: 0.5px solid #d8dae5;
  border-radius: 10px;
  font-size: 16px;
  padding: 8px 35px 8px 8px;
  /* Normal padding */
  box-sizing: border-box;
  /* Pastikan perubahan padding tidak mengubah ukuran */
  transition: padding 0.3s ease, border-color 0.3s ease;
  /* Transisi untuk padding dan border color */
  font-family: "League Spartan", sans-serif;
}

/* Efek hover yang diperbaiki */
textarea.input-main:hover {
  padding-top: 10px;
  /* Penyesuaian padding atas */
  padding-bottom: 6px;
  /* Penyesuaian padding bawah untuk menjaga ukuran total */
  border-color: #cccccc;
  /* Opsi: ubah warna border saat hover */
}

/* Fokus tanpa mengubah ukuran */
input.input-main:focus,
textarea.input-main:focus,
select.input-main:focus {
  border: 0.5px solid #003d6a !important;
  outline: none;
  /* Menghapus outline default */
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #003d6a;
  width: 100%;
}

.input-spinner {
  width: 24px;
  height: 24px;
  border: 3px solid #f0f0f0;
  border-top: 3px solid #003d6a;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.no-results {
  padding: 10px;
  text-align: center;
  color: #787878;
  font-style: italic;
}
</style>
