<template>
  <div class="masterclass-view-container">
    <!-- Loading overlay for initial data loading -->
    <div v-if="isLoading" class="loading-overlay">
      <div class="loader-container">
        <div class="loader"></div>
        <p>Loading masterclass...</p>
      </div>
    </div>

    <h3 class="color-primary">{{ masterclass?.title }}</h3>
    <!-- Tabs -->
    <div class="tabs-container">
      <div class="tab-list">
        <div
          class="tab-item"
          :class="{ active: activeTab === 'view' }"
          @click="handleTabClick('view')"
        >
          <h4>View</h4>
        </div>
        <div
          class="tab-item"
          :class="{ active: activeTab === 'track' }"
          @click="handleTabClick('track')"
        >
          <h4>Track</h4>
        </div>
      </div>
    </div>
    <div class="tab-content">
      <div v-if="activeTab === 'view'">
        <p class="color-primary">{{ masterclass?.blurb }}</p>
        <button
          class="button-primary"
          @click="viewMasterclass"
          style="margin-top: 20px"
          :disabled="isLoadingUrl"
        >
          <span v-if="!isLoadingUrl">View Masterclass</span>
          <span v-else class="button-loader"></span>
        </button>
      </div>
      <div v-if="activeTab === 'track'">
        <TrackMasterclass :masterclass-id="masterclass?.id" />
      </div>
    </div>

    <!-- Iframe Modal -->
    <div v-if="showIframe" class="iframe-modal">
      <div class="iframe-container">
        <div class="iframe-header">
          <v-btn @click="closeIframe" icon="mdi-close" size="small" />
        </div>
        <div v-if="isIframeLoading" class="iframe-loading">
          <div class="loader"></div>
          <p>Loading content...</p>
        </div>
        <iframe
          :src="iframeUrl"
          frameborder="0"
          allowfullscreen
          @load="iframeLoaded"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script setup>
import TrackMasterclass from "@/components/teacher/masterclass/TrackMasterclass.vue";
import ysaTeacherService from "@/services/ysaTeacherService";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const masterclass = ref(null);
const showIframe = ref(false);
const iframeUrl = ref("");
const isLoading = ref(true);
const isLoadingUrl = ref(false);
const isIframeLoading = ref(false);

// Get active tab from URL or default to 'view'
const activeTab = computed(() => route.query.tab || "view");

const handleTabClick = (tab) => {
  updateRouteParams({ tab });
};

const updateRouteParams = (params) => {
  // Merge new params with existing ones, preserving other query parameters
  const newQuery = { ...route.query, ...params };

  // Remove undefined or null values
  Object.keys(newQuery).forEach((key) => {
    if (newQuery[key] === undefined || newQuery[key] === null) {
      delete newQuery[key];
    }
  });

  router.replace({
    name: route.name,
    params: route.params,
    query: newQuery,
  });
};

const viewMasterclass = async () => {
  isLoadingUrl.value = true;
  try {
    const response = await ysaTeacherService.viewMasterclass(route.params.id);
    const url = response.data?.data;
    if (url) {
      iframeUrl.value = url;
      showIframe.value = true;
      isIframeLoading.value = true;
    } else if (masterclass.value && masterclass.value.url) {
      iframeUrl.value = masterclass.value.url;
      showIframe.value = true;
      isIframeLoading.value = true;
    }
  } catch (error) {
    console.error("Error loading masterclass URL:", error);
  } finally {
    isLoadingUrl.value = false;
  }
};

const iframeLoaded = () => {
  isIframeLoading.value = false;
};

const closeIframe = () => {
  showIframe.value = false;
  iframeUrl.value = "";
};

onMounted(async () => {
  isLoading.value = true;
  try {
    const response = await ysaTeacherService.getMasterclassById(
      route.params.id
    );
    const data = response.data?.data;
    if (data) {
      masterclass.value = data;
    }
  } catch (error) {
    console.error("Error loading masterclass data:", error);
  } finally {
    isLoading.value = false;
  }
});
</script>

<style scoped>
.masterclass-view-container {
  padding: 20px;
  position: relative;
}

.tabs-container {
  display: flex;
  align-items: center;
}

.tab-list {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.tab-item {
  list-style: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  color: #cccccc;
}

.tab-item.active {
  color: #e00069;
  font-weight: bold;
  border-bottom: 2px solid #e00069;
}

.tab-item:hover {
  color: #e00069;
  font-weight: bold;
}

.tab-content {
  margin-top: 30px;
}

.iframe-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.iframe-container {
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
}

.iframe-header {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 20px;
  padding: 10px;
  z-index: 1000;
  background-color: transparent;
}

.close-button {
  border: none;
  font-size: 24px;
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  color: #e00069;
}

.close-button:hover {
  color: #e00069;
}

iframe {
  flex: 1;
  width: 100%;
  height: 100%;
  border: none;
}

/* Loading styles */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-radius: 8px;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.loader {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #e00069;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.iframe-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.iframe-loading p {
  margin-top: 15px;
  color: #333;
  font-weight: 500;
}

.button-loader {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid #ffffff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
