<template>
  <div style="display: flex; height: 100vh">
    <!-- Left side -->

    <div
      cols="6"
      style="
        width: 50%;
        background-color: #003d6a;
        display: flex;
        align-items: center;
      "
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: auto;
        "
      >
        <router-link
          to="/"
          style="
            display: flex;
            z-index: 50;
            margin-top: 40px;
            margin-left: 40px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 50;
          "
        >
          <img src="/images/eddy.svg" alt="" class="filter-white" />
        </router-link>
        <img style="" src="/images/register-illustration.svg" alt="" />
        <p style="color: white; font-size: 32px; font-weight: 600">
          Turn your classroom into an <br />
          online Makerspace today!
        </p>

        <BaseButton
          variant="white"
          style="
            font-size: 18px;
            font-weight: 600;
            width: 480px;
            height: 48px;
            border-radius: 12px;
            margin-top: 32px;
          "
          @click="router.push('/signin')"
        >
          Already signed up? Sign in here
        </BaseButton>
      </div>
    </div>

    <!-- Right side -->
    <div
      style="
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <div
        style="
          display: flex;
          justify-content: flex-end;
          width: 100%;
          margin-top: 45px;
          margin-right: 40px;
          position: absolute;
          top: 0;
          right: 0;
        "
      >
        <button
          :style="{
            padding: '8px',
            background: selectedLanguage === 'EN' ? '#E00069' : '#F2F3FB',
            color: selectedLanguage === 'EN' ? 'white' : '#E00069',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            width: '60px',
            fontSize: '14px',
          }"
          @click="setLanguage('EN')"
        >
          EN
        </button>
        <button
          :style="{
            padding: '8px',
            background: selectedLanguage === 'ES' ? '#E00069' : '#F2F3FB',
            color: selectedLanguage === 'ES' ? 'white' : '#E00069',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            width: '60px',
            fontSize: '14px',
          }"
          @click="setLanguage('ES')"
        >
          ES
        </button>
      </div>

      <div
        style="display: flex; flex-direction: column; justify-content: center"
      >
        <template v-if="emailUsed">
          <div
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center;
            "
          >
            <div style="display: flex; flex-direction: column; gap: 20px">
              <h2 class="color-primary">Email already in use</h2>
              <p>
                It seems that you’ve already signed up for Eddy using your
                e-mail <br />
                {{ email }}.
              </p>
              <BaseButton variant="primary" @click="router.push('/signin')">
                Sign in with this email
              </BaseButton>
            </div>
          </div>
        </template>
        <template v-else>
          <div style="display: flex; justify-content: center; width: 100%">
            <div style="width: 430px">
              <h1
                style="
                  font-size: 32;
                  font-weight: 600;
                  margin-bottom: 1rem;
                  color: #003d6a;
                "
              >
                Sign Up
              </h1>
            </div>
          </div>

          <!-- Input fields -->
          <div style="display: flex; width: 100%; justify-content: center">
            <div
              style="
                display: flex;
                gap: 10px;
                width: 440px;
                justify-content: center;
              "
            >
              <BaseInput
                type="text"
                label="First Name*"
                placeholder="Input First Name"
                v-model="firstName"
                :error="isFirstNameError"
                :errorMessage="firstNameErrorMessage"
                style="width: 210px"
                :showInputError="false"
              />
              <BaseInput
                type="text"
                label="Last Name*"
                placeholder="Input Last Name"
                v-model="lastName"
                :error="isLastNameError"
                :errorMessage="lastNameErrorMessage"
                style="width: 210px"
                :showInputError="false"
              />
            </div>
          </div>

          <div
            style="
              display: flex;
              gap: 10px;
              width: 100%;
              flex-direction: column;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              "
            >
              <BaseInput
                type="text"
                label="Email*"
                placeholder="Input Email"
                v-model="email"
                style="width: 430px"
                :error="isEmailError"
                :errorMessage="emailErrorMessage"
                :showInputError="false"
              />
            </div>

            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              "
            >
              <BaseInput
                type="password"
                label="Password*"
                placeholder="Input Password"
                v-model="password"
                style="width: 430px"
                @input="validatePasswordInput"
                :error="isPasswordError"
                :errorMessage="passwordErrorMessage"
                :showInputError="false"
              />
              <PasswordValidationList
                :password="password"
                :validations="validations"
              />

              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                "
              >
                <BaseInput
                  type="password"
                  label="Verify Password*"
                  placeholder="Verify Password"
                  v-model="retypePassword"
                  :style="{
                    width: '430px',
                    marginTop: '10px',
                    marginTop: password ? '7px' : '4px',
                  }"
                  :error="isRetypePasswordError"
                  :errorMessage="retypePasswordErrorMessage"
                  :showInputError="false"
                />
              </div>
              <div
                v-if="requestErrorMessage"
                style="
                  text-align: left;
                  color: #e00269;
                  margin-top: 10px;
                  width: 430px;
                  word-wrap: break-word;
                "
              >
                {{ requestErrorMessage }}
              </div>
            </div>
          </div>

          <!-- Sign Up Button with loading state -->
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 10px;
              margin-top: 20px;
            "
          >
            <button
              :disabled="loading"
              style="
                background-color: #003d6a;
                color: white;
                height: 50px;
                border: none;
                width: 430px;
                border-radius: 12px;
                font-size: 14px;
                font-weight: 400;
              "
              @click="signUp"
            >
              <span v-if="loading">Signing Up...</span>
              <span v-else>Sign Up</span>
            </button>

            <div
              style="
                display: flex;
                gap: 10px;
                align-items: center;
                width: 430px;
              "
            >
              <button
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 8px;
                  height: 50px;
                  border: 1px solid #3b4648;
                  background-color: white;
                  border-radius: 12px;
                  font-size: 14px;
                  font-weight: 400;
                  flex: 1;
                "
                @click="signUpWithGoogle"
              >
                <img src="/images/google.svg" alt="Google Icon" />
                Continue with Google
              </button>
              <button
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 8px;
                  height: 50px;
                  border: 1px solid #ccc;
                  color: #ccc;
                  background-color: white;
                  border-radius: 12px;
                  font-size: 14px;
                  font-weight: 400;
                  flex: 1;
                "
                disabled
              >
                <img
                  src="/images/facebook.svg"
                  alt="Facebook Icon"
                  style="filter: grayscale(100%)"
                />
                Continue with Facebook
              </button>
            </div>

            <div style="width: 450px">
              <p
                style="font-size: 0.75rem; text-align: center; margin-top: 1rem"
              >
                By creating an account you agree with our
                <a
                  class="signup-footer-links"
                  @click="isTermsOfServiceModalOpen = true"
                  >Terms of Service</a
                >,
                <a
                  class="signup-footer-links"
                  @click="isPrivacyPolicyModalOpen = true"
                  >Privacy Policy</a
                >, and our default
                <a
                  class="signup-footer-links"
                  @click="isNotificationSettingModalOpen = true"
                  >Notification Settings</a
                >.
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
  <TermsOfServiceModal
    :isOpen="isTermsOfServiceModalOpen"
    @close="isTermsOfServiceModalOpen = false"
  />
  <PrivacyPolicyModal
    :isOpen="isPrivacyPolicyModalOpen"
    @close="isPrivacyPolicyModalOpen = false"
  />
  <NotificationSettingModal
    :isOpen="isNotificationSettingModalOpen"
    @close="isNotificationSettingModalOpen = false"
  />
</template>

<script setup>
import BaseButton from "@/components/common/BaseButton.vue";
import NotificationSettingModal from "@/components/modal/NotificationSettingModal.vue";
import PrivacyPolicyModal from "@/components/modal/PrivacyPolicyModal.vue";
import TermsOfServiceModal from "@/components/modal/TermsOfServiceModal.vue";
import PasswordValidationList from "@/components/PasswordValidationList.vue";
import authService from "@/services/authService";
import { validateEmail, validatePassword } from "@/utils";
import { isAxiosError } from "axios";
import { ref } from "vue";
import { useRouter } from "vue-router";
import BaseInput from "../components/BaseInput.vue";

const router = useRouter();

const emailUsed = ref(false);

const firstName = ref("");
const lastName = ref("");
const email = ref("");
const password = ref("");
const retypePassword = ref("");
const selectedLanguage = ref("EN");
const loading = ref(false);

const isTermsOfServiceModalOpen = ref(false);
const isPrivacyPolicyModalOpen = ref(false);
const isNotificationSettingModalOpen = ref(false);

const isFirstNameError = ref(false);
const firstNameErrorMessage = ref("");

const isLastNameError = ref(false);
const lastNameErrorMessage = ref("");

const isEmailError = ref(false);
const emailErrorMessage = ref("");

const isPasswordError = ref(false);
const passwordErrorMessage = ref("");

const isRetypePasswordError = ref(false);
const retypePasswordErrorMessage = ref("");

const requestErrorMessage = ref("");

function validateForm() {
  // Reset error states
  isFirstNameError.value = !firstName.value;
  firstNameErrorMessage.value = isFirstNameError.value
    ? "First name required"
    : "";

  isLastNameError.value = !lastName.value;
  lastNameErrorMessage.value = isLastNameError.value
    ? "Last name required"
    : "";

  const { isValid, message } = validateEmail(email.value);
  isEmailError.value = !isValid;
  emailErrorMessage.value = isEmailError.value ? message : "";

  const validatePasswordResult = validatePassword(password.value);
  isPasswordError.value = !validatePasswordResult.isValid;
  passwordErrorMessage.value = validatePasswordResult.message;

  if (!retypePassword.value) {
    isRetypePasswordError.value = true;
    retypePasswordErrorMessage.value = "Verify password required";
  } else if (password.value && password.value !== retypePassword.value) {
    isRetypePasswordError.value = true;
    retypePasswordErrorMessage.value = "Passwords do not match";
  }

  return !(
    isFirstNameError.value ||
    isLastNameError.value ||
    isEmailError.value ||
    isPasswordError.value ||
    isRetypePasswordError.value
  );
}

function setLanguage(lang) {
  selectedLanguage.value = lang;
}

const validations = ref({
  lowercase: false,
  uppercase: false,
  specialChar: false,
  length: false,
});

function clearError() {
  isFirstNameError.value = false;
  isLastNameError.value = false;
  isEmailError.value = false;
  isPasswordError.value = false;
  isRetypePasswordError.value = false;
  firstNameErrorMessage.value = "";
  lastNameErrorMessage.value = "";
  emailErrorMessage.value = "";
  passwordErrorMessage.value = "";
  retypePasswordErrorMessage.value = "";
}

function validatePasswordInput() {
  const result = validatePassword(password.value);
  validations.value = result.validations;
}

async function signUp() {
  clearError();
  if (!validateForm()) {
    return;
  }

  loading.value = true;
  try {
    const data = {
      firstname: firstName.value,
      lastname: lastName.value,
      email: email.value,
      password: password.value,
      confirm_password: retypePassword.value,
    };

    sessionStorage.setItem("registeredEmail", email.value);
    await authService.register(data);
    router.push("/email-verification");
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.data?.error) {
        if (error.response.data.error === "Conflict") {
          emailUsed.value = true;
        } else {
          requestErrorMessage.value = error.response.data.message;
        }
      } else {
        requestErrorMessage.value = "Registration failed";
      }
    } else {
      requestErrorMessage.value = "Registration failed";
    }
  } finally {
    loading.value = false;
  }
}

function signUpWithGoogle() {
  const googleOAuthUrl = `${process.env.VUE_APP_API_URL}/api/oauth/connect/google`;
  window.location.href = googleOAuthUrl;
}
</script>

<style scoped>
.signup-footer-links {
  cursor: pointer;
  color: #0056b3;
  text-decoration: none;
}
</style>
