<template>
  <div class="masterclass-container">
    <h4 class="masterclass-title color-primary">Masterclasses</h4>

    <!-- Loading state -->
    <div v-if="isLoading" class="loading-state">
      <div class="loader"></div>
      <p>Loading masterclasses...</p>
    </div>

    <!-- Empty state -->
    <div v-else-if="masterclasses.length === 0" class="empty-state">
      <div class="empty-icon">
        <svg
          width="80"
          height="80"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            stroke="#e00069"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9 15H15"
            stroke="#e00069"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9 9H9.01"
            stroke="#e00069"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15 9H15.01"
            stroke="#e00069"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <h3>No Masterclasses Available</h3>
      <p>There are currently no masterclasses available for your students.</p>
    </div>

    <!-- Masterclass grid -->
    <div v-else class="masterclass-grid">
      <div v-for="(masterclass, index) in masterclasses" :key="index">
        <div @click="viewMasterClass(masterclass.id)">
          <div class="masterclass-card">
            <div class="card-content">
              <div
                class="card-image"
                :style="{ backgroundImage: `url(${masterclass.thumbnail})` }"
              >
                <div class="card-overlay"></div>
              </div>

              <div class="card-info">
                <h2 class="card-title">{{ masterclass.title }}</h2>
                <p class="card-description">
                  {{ truncateText(masterclass.blurb, 80) }}
                </p>
              </div>
            </div>
          </div>
          <div class="card-stats">
            <div class="stats-item">
              <span class="stats-label">Students Assigned</span>
              <span class="stats-value">{{ masterclass.assigned }}</span>
            </div>
            <div class="stats-item">
              <span class="stats-label">Students Completed</span>
              <span class="stats-value">{{ masterclass.completed }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ysaTeacherService from "@/services/ysaTeacherService";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const masterclasses = ref([]);
const isLoading = ref(true);

const getMasterclassList = async () => {
  isLoading.value = true;
  try {
    const response = await ysaTeacherService.getMasterclassList();
    const data = response.data?.data?.rows;
    if (data) {
      masterclasses.value = data;
    }
  } catch (error) {
    console.error("Error fetching masterclasses:", error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  await getMasterclassList();
});

const viewMasterClass = (id) => {
  router.push(`/teacher/ysa/masterclass/${id}`);
};

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + "...";
};
</script>

<style scoped>
.masterclass-container {
  padding: 0.5rem;
  margin: 0 auto;
  min-height: 400px;
}

.masterclass-title {
  font-size: 2rem;
  margin-bottom: 2rem;
}

/* Loading state styles */
.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
  gap: 20px;
}

.loader {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #e00069;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Empty state styles */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
  text-align: center;
  color: #666;
  border-radius: 12px;
  margin: 2rem 0;
}

.empty-icon {
  margin-bottom: 1.5rem;
}

.empty-state h3 {
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 600;
}

.empty-state p {
  max-width: 400px;
  line-height: 1.5;
}

.masterclass-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 2rem;
}

.masterclass-card {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: 300px;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.masterclass-card:hover {
  transform: translateY(-5px);
}

.card-content {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #36d1dc, #5b86e5);
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.6
  ); /* Semi-transparent black overlay */
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
}

.masterclass-card:hover .card-overlay {
  opacity: 1;
}

.card-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem;
  color: white;
  height: 200px;
  z-index: 3;
  transform: translateY(calc(100% - 90px));
  transition: transform 0.4s ease;
  background-color: #f2f3fb;
}

.card-info::after {
  content: "";
  position: absolute;
  top: -25px;
  left: -25px;
  right: -25px;
  height: 20%;
  background-color: #f2f3fb;
  opacity: 0;
  filter: blur(8px);
  z-index: 5;
}

.masterclass-card:hover .card-info {
  transform: translateY(0px);
}

.card-info:hover::after {
  opacity: 1;
}

.card-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  word-break: break-word;
}

.card-description {
  font-size: 0.9rem;
  line-height: 1.4;
  margin-bottom: 1rem;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.4s ease, transform 0.4s ease;
  transition-delay: 0.1s;
  word-break: break-word;
}

.masterclass-card:hover .card-description {
  opacity: 1;
  transform: translateY(0);
}

.card-stats {
  display: flex;
  width: 60%;
  margin: 20px auto;
  justify-content: space-between;
  padding: 0.7rem;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
}

.stats-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stats-label {
  font-size: 0.8rem;
  color: #003d6a;
  margin-bottom: 0.25rem;
  text-align: center;
  font-weight: 600;
}

.stats-value {
  font-size: 1.1rem;
  font-weight: 800;
  color: #003d6a;
}
</style>
