import axios from "axios";
import http from "./httpService";

const auth = axios.create({
  headers: {
    "Content-type": "application/json",
  },
});

class AuthService {
  async validateToken(token) {
    return await auth.get(
      `${process.env.VUE_APP_LOGIN_URL}/validate-token/${token}`
    );
  }

  async login(data) {
    return await http.post("/auth/login", data);
  }

  async register(data) {
    return await http.post("/auth/register", data);
  }

  async forgotPassword(data) {
    return await http.post("/auth/user/reset-password", data);
  }

  async resetPassword(token, data) {
    return await http.put(`/auth/user/reset-password/${token}`, data);
  }

  async resendVerificationEmail(email) {
    return await http.post("/auth/request-verification", { email });
  }

  async verifyEmail(token) {
    return await http.put(`/auth/verify/${token}`);
  }
}

export default new AuthService();
