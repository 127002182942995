<template>
  <div style="display: flex; height: 100vh">
    <!-- Left side -->
    <div
      cols="6"
      style="
        width: 50%;
        background-color: #003d6a;
        display: flex;
        align-items: center;
      "
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: auto;
        "
      >
        <router-link
          to="/"
          style="
            display: flex;
            gap: 19px;
            margin-top: 40px;
            margin-left: 40px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 50;
          "
        >
          <img src="/images/eddy.svg" alt="" class="filter-white" />
        </router-link>
        <img style="" src="/images/register-illustration.svg" alt="" />
        <p style="color: white; font-size: 32px; font-weight: 600">
          Turn your classroom into an <br />
          online Makerspace today!
        </p>

        <BaseButton
          variant="white"
          style="
            font-size: 18px;
            font-weight: 600;
            width: 480px;
            height: 48px;
            border-radius: 12px;
            margin-top: 32px;
          "
          @click="router.push('/signin')"
        >
          Already signed up? Sign in here
        </BaseButton>
      </div>
    </div>

    <!-- Right side -->
    <div
      style="
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      "
    >
      <EmailVerificationMessage
        v-if="email && !errorMessage"
        title="Verification email sent"
        description="Please check your email to finish signing up."
        timer-message="You can re-send the verification email after"
        :is-loading="isLoading"
        @resend="resendVerificationEmail"
      />
      <div
        style="display: flex; flex-direction: column; align-items: center"
        v-else
      >
        <p style="color: red; font-size: 18px; font-weight: 600">
          {{ errorMessage }}
        </p>
        <BaseButton
          variant="primary"
          style="
            width: 200px;
            height: 48px;
            border-radius: 12px;
            margin-top: 16px;
          "
          @click="router.push('/signin')"
          >Back to Sign In</BaseButton
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseButton from "@/components/common/BaseButton.vue";
import EmailVerificationMessage from "@/components/EmailVerificationMessage.vue";
import authService from "@/services/authService";
import { isAxiosError } from "axios";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const email = ref("");

const router = useRouter();
const errorMessage = ref("");

const isLoading = ref(false);

const getEmail = () => {
  const registeredEmail = sessionStorage.getItem("registeredEmail");
  if (registeredEmail) {
    email.value = registeredEmail;
    // Optionally clear after retrieving
    // sessionStorage.removeItem("registeredEmail");
  } else {
    router.push("/signin");
  }
};

async function resendVerificationEmail() {
  errorMessage.value = "";
  isLoading.value = true;
  try {
    await authService.resendVerificationEmail(email.value);
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.data?.error) {
        errorMessage.value = error.response.data.message;
      } else {
        errorMessage.value = "Failed to resend verification email";
      }
    } else {
      errorMessage.value = "Failed to resend verification email";
    }
  } finally {
    isLoading.value = false;
  }
}

onMounted(() => {
  getEmail();
});
</script>

<style scoped>
.signup-footer-links {
  cursor: pointer;
  color: #0056b3;
  text-decoration: none;
}
</style>
