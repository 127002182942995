import TeacherLayout from "@/layouts/TeacherLayout.vue";
import MasterClass from "@/views/Teacher/YSA/MasterClass.vue";
import StudentList from "@/views/Teacher/YSA/StudentListPage.vue";
import ViewMasterClass from "@/views/Teacher/YSA/ViewMasterClass.vue";

const teacherRoute = {
  path: "/teacher",
  component: TeacherLayout,
  meta: { requiresAuth: true },
  children: [
    {
      path: "ysa/students",
      name: "YSATeacherStudentListPage",
      component: StudentList,
      meta: { requiresAuth: true, title: "Student List Page" },
    },
    {
      path: "ysa/masterclass",
      name: "YSAMasterclassPage",
      component: MasterClass,
      meta: { requiresAuth: true, title: "Master Class" },
    },
    {
      path: "ysa/masterclass/:id",
      name: "YSAMasterclassViewPage",
      component: ViewMasterClass,
      meta: { requiresAuth: true, title: "Master Class View" },
    },
  ],
};

export default teacherRoute;
